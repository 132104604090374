export const Engage = () => {
  return (
    <div className="engage-section">
      <div className="left-contianer">
        <p>Want to engage with us?</p>
        <hr />
      </div>
      <div className="right-contianer">
        <button>Engage with us</button>
      </div>
    </div>
  );
};
