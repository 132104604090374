import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink } from 'reactstrap';
 

const Sidebar = ({statee}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className={`sidebar ${statee ? 'open' : ''}`}>
      <Navbar color="light" light expand="md">
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="#" className="nav-link">Link 1</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" className="nav-link">Link 2</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" className="nav-link">Link 3</NavLink>
            </NavItem>
            {/* Add more NavItems as needed */}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Sidebar;
