export const validEventIds = [
  "event-1",
  "event-2",
  "workshop-1",
  "workshop-2",
  "workshop-3",
  "workshop-4",
  "workshop-5",
  "round-table-1",
  "round-table-2",
  "round-table-3",
  "round-table-4",
  "round-table-5",
  "round-table-6",
];

export const eventList = [
  {
    dateStart: "01",
    dateEnd: "03",
    Month: "Oct",
    year: "24",
    title: "Learning Exchange",
    location: "Delhi",
    url: "",
    isUpcoming: true,
  },
  {
    dateStart: "01",
    dateEnd: "03",
    Month: "Nov",
    year: "23",
    title: "International Learning Exchange",
    location: "Delhi",
    url: "/events?type=event&id=event-1",
    isUpcoming: false,
  },
  {
    dateStart: "15",
    dateEnd: "",
    Month: "February",
    year: "24",
    title: "Investment Roundtable",
    location: "Hyd",
    url: "/events?type=round-table&id=round-table-1",
    isUpcoming: false,
  },
  {
    dateStart: "09",
    dateEnd: "",
    Month: "June",
    year: "24",
    title: "Mohali nCount review meeting",
    location: "Hyd",
    url: "/events?type=event&id=event-2",
    isUpcoming: false,
  },
  {
    dateStart: "09",
    dateEnd: "",
    Month: "June",
    year: "24",
    title: "Scientific Forest Management",
    location: "Mohali",
    url: "/events?type=workshop&id=workshop-4",
    isUpcoming: false,
  },
  {
    dateStart: "12",
    dateEnd: "",
    Month: "Dec",
    year: "23",
    title: "Training under Abua Bir Abua Dishom Abhiyan",
    location: "Jharkhand",
    url: "/events?type=workshop&id=workshop-1",
    isUpcoming: false,
  },
  {
    dateStart: "06",
    dateEnd: "",
    Month: "June",
    year: "23",
    title: "Empowering Voices of Young Tribal Girls",
    location: "Odisha",
    url: "/our-work?state=odisha&workId=odi-2",
    isUpcoming: false,
  },
  {
    dateStart: "23",
    dateEnd: "",
    Month: "May",
    year: "23",
    title: "ISB-TCS Carbon Measurement Workshop",
    location: "Jharkhand",
    url: "/events?type=workshop&id=workshop-5",
    isUpcoming: false,
  },
  {
    dateStart: "10",
    dateEnd: "12",
    Month: "May",
    year: "23",
    title: "Sal seeds collection training sessions",
    location: "Jharkhand",
    url: "/events?type=workshop&id=workshop-3",
    isUpcoming: false,
  },
];
