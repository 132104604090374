import React from "react";
import Header from "../../components/Header2";
import mapImage from "../../assests/images/map.png";
import { Link } from "react-router-dom";
import locationIcon from "../../assests/icons/Location.png";
import DropDown from "../../utils/CustomDropdown";
import OurWorkCardsSection from "./WorkCards";
import { useWindowSize } from "../../utils/getWindowSize";
import { StateWork } from "./StateWork";
import { useScrollToTop } from "../../utils/scrollToTop";

function Home({ stateParam, paramSize, isValidState, isValidWorkId }) {
  useScrollToTop();
  const { width } = useWindowSize();

  return (
    <div className="work-landing-page">
      {isValidState && paramSize === 1 ? (
        <>
          <Header activeLink="1" background={false} modified={true} />
          <StateWork name={stateParam} />
        </>
      ) : (
        (paramSize === 0 || !isValidState || !isValidWorkId) && (
          <>
            <Header activeLink="1" background={false} modified={true} />
            <div className="work-top-container">
              <div className="map-container-main">
                <img src={mapImage} className="map-img-main" alt="map-img" />
                <Link to="/our-work?state=odisha">
                  <img
                    src={locationIcon}
                    className="map-pin-odisha"
                    alt="loc-img"
                  />
                </Link>
                <Link to="/our-work?state=andhra-pradesh">
                  <img
                    src={locationIcon}
                    className="map-pin-andhra"
                    alt="loc-img"
                  />
                </Link>
                <Link to="/our-work?state=jharkhand">
                  <img
                    src={locationIcon}
                    className="map-pin-jharkhand"
                    alt="loc-img"
                  />
                </Link>
                <Link to="/our-work?state=himachal-pradesh">
                  <img
                    src={locationIcon}
                    className="map-pin-hp"
                    alt="loc-img"
                  />
                </Link>
                <Link to="/our-work?state=goa">
                  <img
                    src={locationIcon}
                    className="map-pin-goa"
                    alt="loc-img"
                  />
                </Link>
                <Link to="/our-work?state=maharashtra">
                  <img
                    src={locationIcon}
                    className="map-pin-mh"
                    alt="loc-img"
                  />
                </Link>
              </div>
              <div className="work-landing-top">
                <div className="top-details">
                  <div className="top-details-info">
                    <p>Not to the scale. Just for the illustration purpose*</p>
                  </div>
                  <div className="top-details-dropdown">
                    <h2>Pick up your area of interest</h2>
                    <p>
                      Living laboratories as part of ITFF serve as regionally
                      embedded expansive demonstration sites designed to
                      illustrate the transformative potential of forests in
                      driving positive socio-economic change. These laboratories
                      operate as pilot projects, integrating sustainability
                      principles, scalable practices, and advanced technologies
                      to foster holistic natural resource governance.
                    </p>
                    <DropDown />
                  </div>
                </div>
              </div>
              {width >= 885 && <div className="work-landing-bottom"></div>}
            </div>
            <OurWorkCardsSection />
          </>
        )
      )}
    </div>
  );
}

export default Home;
