import React from "react";
import Home from "./Home";
import { useSearchParams } from "react-router-dom";
import { validLibIds } from "../../utils/library-data/publicationList";
import { LibraryDetail } from "./LibraryDetail";
import { useScrollToTop } from "../../utils/scrollToTop";

function Library() {
  useScrollToTop()
  const [searchParams] = useSearchParams();
  const libIdParam = searchParams.get("libId");

  const isValidLibId = validLibIds.includes(libIdParam);

  return (
    <>
      {(searchParams.size === 0 || !isValidLibId) && <Home />}

      {isValidLibId && searchParams.size === 1 && (
        <LibraryDetail libId={libIdParam} />
      )}
    </>
  );
}

export default Library;
