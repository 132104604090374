import { Link } from "react-router-dom";

export const Bunch = () => {
  return (
    <div className="bunch-section">
      <div className="bunch-info-container">
        <h3>We are a bunch of change makers!</h3>
        <p>
          IOFE leverages sustainability, scalability, and technology to create a
          paradigm shift by empowering communities with the tools to manage
          their forest resources sustainably.
        </p>
        <Link to="/about-us/team">
          <button className="meet-btn">Meet Our Team</button>
        </Link>
      </div>
    </div>
  );
};
