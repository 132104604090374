import React from "react";
import Home from "./Home";
import { OurTeam } from "./OurTeam";
import { TeamContactForm } from "./TeamContactForm";
import { useScrollToTop } from "../../../utils/scrollToTop";

function Main() {
  useScrollToTop();
  return (
    <>
      <Home />
      <OurTeam />
      <TeamContactForm />
    </>
  );
}

export default Main;
