import React, { useLayoutEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import demoUser from "../../assests/images/demo-user.png";
import demoPubs from "../../assests/images/demo-pubs.png";
import leftArrow from "../../assests/images/leftArrow.png";
import rightArrow from "../../assests/images/rightArrow.png";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", marginLeft: "-25px" }}
      onClick={onClick}
    >
      <img src={leftArrow} alt="prev-arrow" style={{ width: 25 }} />
    </div>
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", marginRight: "-15px" }}
      onClick={onClick}
    >
      <img src={rightArrow} alt="next-arrow" style={{ width: 25 }} />
    </div>
  );
}

const MyCarousel = ({ bgColor }) => {
  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="custom-slider">
      {
        
      }
      <Link
        to="/library/bipp-smi"
        style={{ textDecoration: "none !important" }}
      >
        <div className="stake-carousel">
          <div className="home-pubs-division-texts">
            <p className="home-pubs-division-title">
              BIPP-ISB Formalises the Relationship with Srishti Manipal
              Institute of Art, Design and Technology
            </p>
            <p className="home-pubs-division-desc" style={{ marginTop: 5 }}>
              The major objective of the partnership is to facilitate the
              exchange of information on research and educational programs,
              teaching materials, and literature relevant to their educational
              and research activities. It also involves jointly organizing
              short-term education programs, seminars, conferences, and
              workshops on mutual interests, with invitations extended to each
              other’s faculty for participation.....
            </p>
          </div>
          <div className="home-pubs-division-texts-border">
            <p className="project-card-footer-text">#knowledgepartnership</p>
          </div>
        </div>
      </Link>
      <Link
        to="/library/himachal-pradesh-collaboration"
        style={{ textDecoration: "none" }}
      >
        <div className="stake-carousel">
          <div className="home-pubs-division-texts">
            <p className="home-pubs-division-title">
              Collaboration for Himachal Pradesh Scientific Forest Inventory
            </p>
            <p className="home-pubs-division-desc">
              At the heart of this collaboration lies a vision of sustainability
              and prosperity. The primary objective is to conduct a
              comprehensive inventory of selected forest products, assessing
              their distribution and abundance with precision. This endeavor is
              not just about mapping resources; it’s a strategic step toward
              realizing the potential revenue from the sustainable management
              and regulation of these natural treasure. 
            </p>
          </div>
          <div className="home-pubs-division-texts-border">
            <p className="project-card-footer-text">
              #Forestdepartment &nbsp; #Forestinventorymapping
            </p>
          </div>
        </div>
      </Link>
      <Link to="/library/biofuel-circle" style={{ textDecoration: "none" }}>
        <div className="stake-carousel">
          <div className="home-pubs-division-texts">
            <p className="home-pubs-division-title">
              BiofuelCircle and BIPP-ISB Collaborate to Strengthen Forest-Based
              Bioenergy Value Chains 
            </p>
            <p className="home-pubs-division-desc">
              This partnership aims to utilize forests as sustainable sources of
              raw materials for the bioenergy sector and empower forest-based
              community-owned enterprises with secure tenure.  
            </p>
          </div>
          <div className="home-pubs-division-texts-border">
            <p className="project-card-footer-text">#Bioenergymission</p>
          </div>
        </div>
      </Link>
    </Slider>
  );
};

export default MyCarousel;
