// Define valid states
export const validStates = [
  ["jharkhand", "Jharkhand"],
  ["odisha", "Odisha"],
  ["andhra-pradesh", "Andhra Pradesh"],
  ["himachal-pradesh", "Himachal Pradesh"],
  ["goa", "Goa"],
  ["maharashtra", "Maharashtra"],
];

export const workStateData = {
  jharkhand: {
    title: "Jharkhand",
    mapImage:
      "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/map-jhk.png",
    description:
      "The ITFF-BIPP and the Government of Jharkhand signed an MOU on December 30, 2021, formalising its collaborative partnership with the Department of Scheduled Tribes, Scheduled Castes, Minorities, and Backward Class Welfare, as well as the Department of Forest, Environment & Climate Change to create a sustainable forest economy. ITFF has been instrumental in developing the technology backbone called the JharFRA Platform, which enables easy claim filings across districts. ISB is one of the key members in the FRA cell overseeing the implementation of the Abua Bir Abua Dishom Campaign. The objective of the partnership is upscaling of community forest resource rights and recognition and further establish market linkages for various Seasonal Forest Produces.",
    stateDescription:
      "ITFF has facilitated the on-ground institutional structure and monitoring mechanism to track FRA claim processing progress, implemented capacity building, and reconstituted FRCs. Additionally, ITFF is integrating pastoral claims and grazing tenure rights into its efforts. Jharkhand operations have also been the site for our market linkage initiatives with FPCs as well as SFP processing mechanisation efforts. ",
    distData: [
      {
        distTitle: "Simdega",
        distDescription:
          "Simdega District is ITFF’s pilot location where the model for the saturation of Community Forest Rights claims with a streamlined claim-filing process and mobilisation was developed, which will be scaled across the state.",
      },
      {
        distTitle: "Gumla",
        distDescription:
          "ITFF's operations in the Raidih and Palkot blocks have focused on raising awareness, building capacity, and mobilizing Forest Rights Committees. The project also successfully established a market linkage for Sal seeds between women-led farmer producer companies and the Sweden-based company AAK, with ISB, PRADAN, and JSLPS as facilitating partners. ",
      },
      {
        distTitle: "East Sighbhum",
        distDescription:
          "In the Potka and Dumaria Blocks, ITFF’s efforts have focused on rigorous series of training of Forest Rights Committees, Bir Bandhus and community members on recognizing resource rights and CFR claim generation. ITFF is also hand-holding grassroots civil-society organisations in FRA-based capacity building and processes.",
      },
      {
        distTitle: "West Singhbum",
        distDescription:
          "ITFF’s efforts are currently in the scoping stage, focusing on mapping SFP clusters and initiating the mobilization of community Gram Sabhas and FRCs. ",
      },
    ],
  },
  odisha: {
    title: "Odisha",
    mapImage:
      "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/map-odisha.png",
    description:
      "Although, forests had historically been the primary source of livelihood of 62 scheduled tribes and other traditional forest dwellers in Odisha, the legal recognition of their individual and community rights over forests paved the way decades later in 2006, through the Forest Rights Act. ITFF is collaborating with Mission Shakti, Government of Odisha, to empower women and promote rural prosperity through a sustainable forest economy. This partnership aims to establish women-led community enterprises, beginning in the districts of Malkangiri, Koraput, Nabarangpur, Rayagada, Mayurbhanj, and Kendujhar. The ITFF model seeks to federate Women’s Self-Help Groups (WSHGs), facilitate securing CFR titles at the village level, provide technological support to leverage economies of scale through aggregation and mechanization, and establish direct market connections with major buyers of forest-based industrial raw materials. Our project aims to assist Mission Shakti in capacity building, financial inclusion, and skill development for WSHGs. The collaboration is projected to benefit nearly 20 lakh women across the six districts.",
    stateDescription:
      "Our initiative aims to federate Women-led community enterprise), anchored in secure CFRR titles at the village level. We provide technological support for aggregation and mechanization and establish direct market connections with major buyers of forest-based raw materials.",
    distData: [
      {
        distTitle: "Malkangiri",
        distDescription:
          "In the Kalimela and Khairput blocks of Malkangiri district, our field team has facilitated Community Forest Resource Rights claim-filing, forest boundary mapping, Community Forest Resource Management Committee formation, and bamboo management plans.",
      },
      {
        distTitle: "Bonda Ghati",
        distDescription:
          "Our work with the PVTG (Particularly Vulnerable Tribal Groups) community in Bonda Ghati is serving as a blueprint for habitat rights across Odisha, with the Bonda Development Agency assisting ISB with human resources and groundwork.",
      },
      {
        distTitle: "Mayurbhanj",
        distDescription:
          "We have made facilitation interventions in 11 districts including Mayurbhanj with Vasundhara under the MJJY (Mo Jungle Mo Jami) campaign.",
      },
    ],
  },
  "himachal-pradesh": {
    title: "Himachal Pradesh",
    mapImage:
      "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/map-hp.png",
    description:
      "ITFF has been actively engaged in the regions of Chhota Banghal in Kangra district, Pangi and Bhattiyat tehsil in Chamba district, as well as Lahaul and Spiti District. Our activities include district and state-level government engagement, cluster mapping along with the capacity building of administrative personnel, FRCs, FMCs, and SHG members.  Operations have also spanned working with the communities to support CFR claim-filing and rectifications, management plan formation, and Gram Sabha mobilization.",
    stateDescription:
      "In January 2024, ITFF partnered with the Himachal Pradesh Forest Department to enhance the state's seasonal forest products through a detailed inventory project. This initiative catalogues their occurrence, distribution, and revenue potential using advanced data collection methods, including satellite and drone sensors, and machine learning for precise predictions.",
    distData: [
      {
        distTitle: "Pangi",
        distDescription:
          "In the Pangi Valley of Chamba district, we facilitated CFR claim filing, forest boundary mapping, committee formation, and management plans. Through ITFF initiatives, patwaris, women, and SDLC members received training in participatory forest inventory mapping.",
      },
      {
        distTitle: "Lahual and Spiti ",
        distDescription:
          "BIPP, ISB has also signed MoUs with the Lahaul and Spiti district administration. Our project will facilitate capacity building for FRCs, CFRR claim filing, and forest boundary and inventory mapping harnessing nCount in the region. ",
      },
      {
        distTitle: "Chota Banghal ",
        distDescription:
          "In 2020, 28 Gram Sabhas in Chota Banghal received community forest resource titles and began developing a sustainable harvesting plan for seasonal forest products. They formed the Chota Banghal Maha Gram Sabha, supported by Kishan Sabha and ITFF, to create CFRMCs and CFRMPs. Our project also is currently assisting the CBMGS with market linkages for SFPs. ",
      },
    ],
  },
  "andhra-pradesh": {
    title: "Andhra Pradesh",
    mapImage:
      "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/map-ap.png",
    description:
      "The ITFF and the Andhra Forest Department have partnered to develop a collaborative initiative focused on creating a sustainable forest economy by securing tenure in bamboo forest landscapes. The goal of this partnership is to establish a bamboo economy through a multi-stakeholder approach.",
    stateDescription:
      "ITFF will facilitate the acquisition of tenure rights for local communities, integrate technology for economies of scale in aggregation, and establish marketing linkages for the bamboo economy. We have completed an initial bamboo inventory across Andhra Pradesh and will soon launch a pilot program in a selected district.",
    distData: [
      {
        distTitle: "Alluri Sitarama Raju (ASR)",
        distDescription:
          "In collaboration with the Andhra Pradesh Forest Department, we conducted a field survey to inventory bamboo resources, gathering gold-standard ground-truthing data for a state-wide inventory. The ASR district was chosen as the pilot area to demonstrate a sustainable bamboo economy based on community tenure which will be applied to other regions and SFPs within the state.",
      },
    ],
  },
  maharashtra: {
    title: "Maharashtra",
    mapImage:
      "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/map-mh.png",
    description:
      "An MoU has been signed between ITFF and the District Collector and Chief Executive Officer (CEO), Zilla Parishad, Gadchiroli for a comprehensive Resource Inventory using advanced digital and geospatial technologies, followed by creating an economic model around identified Seasonal Forest Products (SFPs) through security of tenure, community enterprises and market linkages. Previously, in 2018-19, ITFF conducted participatory inventory mapping, identifying twenty-three SFPs with high economic potential. Collaborating with Aamhi Amchya Arogya Sathi, Korchi Mahagram Sabha, and the FPO in Kurkheda, ITFF focused on establishing market linkages and building a sustainable business model based on secure community forest tenure rights. Additionally, ITFF collaborated with MSRLM and Van Dhan Yojana to enhance transparency in institutions, promote financial literacy, include local communities, and utilize targeted technology.",
    stateDescription:
      "In 2018-19, our technology team conducted participatory inventory mapping, identifying 23 economically promising SFPs. Collaborating with Aamhi Amchya Arogya Sathi, Korchi Mahagram Sabha, and the FPO in Kurkheda, ITFF worked on market linkages and sustainable business models based on secure community forest tenure rights. Additionally, ITFF partnered with MSRLM and Van Dhan Yojana to enhance institutional transparency, promote financial literacy, engage local communities, and utilize targeted technology. ",
    distData: [
      {
        distTitle: "Gadchiroli",
        distDescription:
          "ITFF, in collaboration with the District Administration of Gadchiroli, CEO District Council, Forest Department, and Revenue Department, will undertake Boundary and Inventory Mapping of the Community Forest Right Area and Carbon Sequestration after signing a tripartite MoU. The mapping process will begin in Dhanora Block under the leadership of the District Magistrate. This will be followed by comprehensive training covering theoretical and practical aspects of nCount technologies and processes.",
      },
    ],
  },
  goa: {
    title: "Goa",
    mapImage:
      "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/map-goa.png",
    description:
      "ITFF has partnered with the Government of Goa through a MoU to transform the state's forests into essential bioenergy hubs, with the aim of enhancing energy security, fostering rural development, and mitigating climate change. At the core of this collaboration is the development of a bamboo economy, with a particular focus on empowering women to lead community enterprises.",
    stateDescription:
      "ITFFs initiatives will leverage digital and mechanical innovations to enhance efficiency and scalability, while also establishing vital market connections with large industrial buyers. Furthermore, this initiative seeks to establish comprehensive end-to-end business value chains, placing communities with secure tenure rights at the forefront of the model, thereby making them key stakeholders in a sustainable economic framework. ",
    distData: [],
  },
};
