import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from "reactstrap";
import Header from "../../components/Header2";
import mapIcon from "../../assests/logo/mapIcon.png";
import arrowIcon from "../../assests/logo/arrowIcon.png";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { eventDetailData } from "../../utils/event-data/eventDetailData";
import { eventList } from "../../utils/event-data/eventsList";
import { useScrollToTop } from "../../utils/scrollToTop";

const settings = {
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 950,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
  ],
};
const colors = [
  "#EEC3B1",
  "rgba(123, 60, 46, 0.4)",
  "#D9AC98",
  "#E68662",
  "rgba(123, 60, 46, 0.4)",
  "rgba(218, 134, 97, 0.5)",
  "#D9AC98",
];

export const EventDetail = ({ eventType, eventId }) => {
  useScrollToTop();
  const navigate = useNavigate();

  const detailedData = eventDetailData[eventType][eventId];
  const onCardClick = (path) => {
    navigate(path);
  };
  return (
    <>
      <div className="state-work-detail-top">
        <>
          <img
            src={`/event-image/${detailedData.topImage}`}
            className="state-work-detail-top-image"
            alt="work-img"
          />

          <img
            src="bg-layer.png"
            className="state-work-detail-top-image"
            alt="over-lay"
          />
        </>

        <Header activeLink={"4"} modified={false} />
        <div className="state-work-detail-top-detail">
          <h2>{detailedData.topTitle}</h2>
          {detailedData.topHeading && <h1>{detailedData.topHeading}</h1>}
          {detailedData.topDesc && <p>{detailedData.topDesc}</p>}
        </div>
      </div>
      <UncontrolledAccordion defaultOpen={1}>
        {detailedData.contentDescription.map((item, index) => {
          return (
            <AccordionItem key={index}>
              <AccordionHeader
                targetId={index + 1}
                style={{ "--header-bg-color": colors[index % colors.length] }}
              >
                {item.heading}
              </AccordionHeader>
              <AccordionBody accordionId={index + 1}>
                {item.body.map((data, index) => {
                  switch (data.type) {
                    case "paragraph-heading":
                      return (
                        <h6 key={index} className="paragraph-heading">
                          {data.text}
                        </h6>
                      );

                    case "paragraph":
                      return (
                        <p key={index} className="paragraph">
                          {data.text}
                        </p>
                      );

                    case "numBulletPoints":
                      return (
                        <ol key={index} className="bullet-points">
                          {data.text.map((item, idx) => (
                            <li key={idx}>{item}</li>
                          ))}
                        </ol>
                      );

                    case "bulletPoints":
                      return (
                        <ul key={index} className="bullet-points">
                          {data.text.map((item, idx) => (
                            <li key={idx}>{item}</li>
                          ))}
                        </ul>
                      );

                    case "event-dt-image":
                      return (
                        <img
                          src={`/event-image/${data.text}`}
                          className="event-dt-image"
                          alt="work-img"
                        />
                      );

                    default:
                      return <p>{data}</p>;
                  }
                })}
              </AccordionBody>
            </AccordionItem>
          );
        })}
      </UncontrolledAccordion>
      <div className="home-events-section home-detail-events-section">
        <div className="events-container">
          <div className="events-slider events-detail-slider">
            <Slider {...settings} className="custom-slider-events">
              {eventList.map((event, index) => {
                return (
                  <div
                    className="event-slider-card"
                    key={index}
                    onClick={() => onCardClick(event.url)}
                  >
                    <div className="card-info">
                      <span>{event.isUpcoming ? "Upcoming" : <>&nbsp;</>}</span>
                      <h1>
                        {event?.dateStart}
                        {event?.dateEnd !== "" && `-${event?.dateEnd}`}
                      </h1>
                      <h6>
                        {event?.Month}’{event?.year}
                      </h6>
                      <p>{event?.title}</p>
                    </div>
                    <div className="card-location-info">
                      <div className="card-location-icon">
                        <img src={mapIcon} className="" alt="map-icon" />
                        <p className="event-loc">Mohali</p>
                      </div>
                      <img src={arrowIcon} className="" alt="arrow-icon" />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
        {/* <img src={bg} className="background-video" alt="wave" /> */}
      </div>
    </>
  );
};
