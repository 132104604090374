import React from "react";
import MyCarousel from "./Carousel";
import { Link } from "react-router-dom";
import LibraryCaraosel from "../library/Carousel";

function Publications() {
  return (
    <div className="home-pubs-section">
      <center>
        <p className="home-publication-title">
          Building Multi-stakeholder Partnership
        </p>
        <p className="home-publication-desc">
          We are forging partnerships with government agencies, civil society
          organizations, research institutions, and private companies to
          transform forest landscapes into hubs of opportunity for wealth
          creation. 
        </p>
      </center>
      <center className="stake-carousel-track">
        <div className="crous-container">
          {/* <MyCarousel /> */}
          <LibraryCaraosel detail={true}/>
        </div>
      </center>
      <center>
        <Link to="/library">
          <button className="home-page-button mb-0">
            Check out our Library
          </button>
        </Link>
      </center>
    </div>
  );
}

export default Publications;
