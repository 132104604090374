import React from "react";
import Home from "./Home";
import { useSearchParams } from "react-router-dom";
import { StateWorkDetail } from "./StateWorkDetail";
import { validWorkIds } from "../../utils/our-work-data/workData";
import { validStates } from "../../utils/our-work-data/workStateData";
import { useScrollToTop } from "../../utils/scrollToTop";

function Main() {
  useScrollToTop();
  const [searchParams] = useSearchParams();

  const stateParam = searchParams.get("state");
  const workIdParam = searchParams.get("workId");

  const isValidState = validStates.find(
    (state, index) => state[0] === stateParam
  );
  const isValidWorkId = validWorkIds.includes(workIdParam);

  return (
    <>
      <Home
        stateParam={stateParam}
        paramSize={searchParams.size}
        isValidState={isValidState}
        isValidWorkId={isValidWorkId}
      />

      {isValidState && isValidWorkId && searchParams.size === 2 && (
        <StateWorkDetail stateName={stateParam} workId={workIdParam} />
      )}
    </>
  );
}

export default Main;
