export const detailedWorkData = {
  jharkhand: {
    "jhar-1": {
      topTitle:
        "JharFRA: Transforming Forest Governance in Jharkhand through Technology",
      topDesc: `Chief Minister Hemant Soren during the launch of "Abua Bir Abua Dishom Abhiyan" (My Jungle, My Country) in Ranchi, Jharkhand.`,
      topImage: "jhar-1-img.png",
      contentHeading: "JharFRA",
      contentDescription: [
        {
          type: "paragraph",
          text: "On 6 November 2023, Jharkhand Chief Minister Hemant Soren launched the ‘Abua Bir Abua Dishom Abhiyan’ in Ranchi, a campaign focused on granting land ownership (pattas) to forest dwellers under the Scheduled Tribes and Other Traditional Forest Dwellers (Recognition of Forest Rights) Act 2006. This initiative targets the quick processing of both pending applications and new claims in the coming months. The campaign’s goal is to ensure all eligible tribals and forest dwellers receive their land ownership rights. It is expected to benefit 1 lakh individuals and 10,000 villages access to individual and community forest resource rights respectively in Jharkhand.",
        },
        {
          type: "paragraph",
          text: "The key developments in this collaboration include the launch of our app and website dedicated to the campaign, along with a Standard Operating Procedure (SOP) booklet, a user manual for the JharFRA app, and various other resource materials. These tools were developed to streamline and standardize the process of filing claims under the Forest Rights Act of 2006.",
        },
        {
          type: "paragraph",
          text: "The government’s knowledge and technology partners include the Foundation of Ecological Security (FES), with its partner organization Phia Foundation in Jharkhand, and the Initiative on Transformative Forest Futures (ITFF) team at the Indian School of Business (ISB).",
        },
        {
          type: "paragraph",
          text: "Our team visited Ranchi to assist with integrating technology into the Forest Rights Act (FRA) campaign. We met with key stakeholders from the revenue, forest, and IT departments to align the technological aspects of the campaign.",
        },
        {
          type: "paragraph",
          text: "Significant discussions took place with the Forest Department, particularly with CCF Research Siddharth Tripathi, DFO Social Forestry Vikas Ujjwal, and the Forest Department’s GIS team. The focus was on exploring collaborative opportunities with ISB. The Forest Department expressed interest in refining a tool they developed for identifying eligible areas for Individual Forest Rights (IFR) claims through satellite imagery. This tool might be integrated into the JharFRA app dashboard if deemed beneficial. Additionally, the department plans to test the app’s functionality on the ground",
        },
        {
          type: "paragraph",
          text: "A comprehensive training plan is being developed by the FRA cell. This plan involves a cascading model of training from the state to district to panchayat level, focusing on creating master trainers who will train government officials and Beer Bandhus. A special training for 30 tech master trainers on the JharFRA app is also planned, who will then be responsible for training Beer Bandhus over three months.",
        },
        {
          type: "paragraph",
          text: "Jharkhand’s ‘Abua Bir Abua Dishom Abhiyan’ emerges as a beacon of transformative forest governance. Fueled by technology and strategic collaborations, the campaign not only streamlines land ownership rights but also promises a positive impact on the lives of tribal and forest communities. The integration of the JharFRA app and comprehensive training programs reflects a forward-thinking approach, marking a significant step towards inclusive and effective forest governance in the region.",
        },
      ],
      contentTags: ["Forest Governance", "Jharkhand", "Technology"],
    },
    "jhar-2": {
      topTitle: "",
      topDesc: "",
      topImage: "jhar-2-img.png",
      contentHeading:
        "ISB-TCS partnership to build a sustainable forest economy in India",
      contentDescription: [
        {
          type: "paragraph",
          text: "The Initiative on Transformative Forest Futures (ITFF) is focused on making a business case for Community Forest Resource rights recognition in India by collaborating with various stakeholders as a Technical, Implementation, and Knowledge partner. ISB signed a three-year Memorandum of Understanding with TCS (Tata Consultancy Services LTD) in December 2022 to support the ITFF team’s leading efforts in developing a profitable business model for forest communities that includes sustainability. It is a three-year agreement that runs from October 2022 to October 2025. ",
        },
        {
          type: "highlightedParagraph",
          text: "The partnership aims to advance innovation in using technology to monitor forests and their products for societal development. The first objective is monitoring and estimating carbon stocks of the sub-tropical forests in the Simdega district of Jharkhand. Sky-Earth Convergence Technology approach developed by TCS will be extensively used to achieve this objective.",
        },
        {
          type: "paragraph",
          text: "Jaldega, Bano, and Kolebira blocks of Simdega district are the chosen pilot districts of the project. ",
        },
        {
          type: "heading",
          text: "Research Methodology of the Project: ",
        },
        {
          type: "paragraph",
          text: "Forest-related emission reductions and enhanced removals (carbon sequestration) are estimated by measurement, reporting, and verification (MRV) systems, usually based on a combination of remote sensing data, field or in situ measurements, and modeling approaches. Operationalizing the MRV process is lengthy, however, often taking years even in countries with high capacities for such a task, and once it is operational, it relies on a complex, non-standardized, uncertain, and lengthy process of integrating remote sensing and in situ measurements. This negatively affects the ability to address the drivers of these emissions and, at the same time, apply for and access climate finance in a timely manner. Hence, the mission of the project is to measure forest carbon stock accurately with the help of communities and technology and gain actionable insights. ",
        },
        {
          type: "paragraph",
          text: "The TCS Digital Platform for Next Gen Agriculture (TCS DNA) offers a unique Sky and Earth Convergence approach using large-scale data such as imagery by satellites and drones and earth data sets such as IoT (the Internet of Things), images, etc., for accuracy in carbon framing. It will monitor farm-level regenerative practices and measures carbon stocks every season at an accuracy level of 90% while reducing costs by up to 80%. The operationalization aspect of the pilot can be grouped into the following four areas:",
        },
        {
          type: "bulletPoints",
          text: [
            "Data availability and access;",
            "Processing and computational performance;",
            "Uncertainty management; and",
            "Standardization and protocols.",
          ],
        },
        {
          type: "paragraph",
          text: "The collaboration will focus on assessing the readiness of various innovative technologies such as remote sensing (RS), geostatistics (GS), artificial intelligence (AI), the Internet of Things (IoT), and cloud computing (CC). This will help in identifying the combination of these tools that is ideal to foster a next-generation MRV. These tools will then accelerate our mission to unlock the potential of forests and enable governments and stakeholders, especially women, to monitor the implementation of environmental policies and assess the status of the forests. ",
        },
        {
          type: "paragraph",
          text: "Understanding the appropriate technology requirements to contribute to enhancing productivity and resource conservation is crucial for innovating solutions. The Initiative on Forest Economy will be able to empower the collectors of seasonal forest products, especially women, by developing such technologies. ",
        },
      ],
      contentTags: ["ISB - TCS", "Technology", "Collaboration"],
    },
    "jhar-3": {
      topTitle:
        "Women-led FPCs carry out successful Sal Seed Sale to AAK in Gumla and Ranchi District, Jharkhand",
      topDesc: "",
      topImage: "jhar-3-img.png",
      contentHeading: "About the project",
      contentDescription: [
        {
          type: "aboutParagraph",
          text: "The successful large-scale sale of Sal seeds was made possible through the collaborative efforts of the ITFF team, JSLPS, JJBA, Pradan and AAK, utilizing the aggregation capabilities of farmer Producer Companies (FPC). This collaboration aimed to generate wealth at the local level by leveraging the combined expertise and resources of the partner organizations.",
        },
        {
          type: "paragraph",
          text: "On 24th June, Monday afternoon, a truck of 10,900 kgs of Sal seeds was unloaded at the AAK factory gate in Bilaspur with a payment of Rs. 2,57,529 to the Ghaghara Woman Producer Farmer Company Limited, a women-led collective in Gumla. The aggregation process was undertaken by three partners – JJBA (Jharkhand Jungle Bachao Andolan), Pradan’s FPC (Women farmers of GMKST (Gumla Mahila Kisan Swawlamban Trust) and JSLPS (Jharkhand State Livelihood Promotion Society), however the sale was processed through the Pradan’s Nodal FPC (Ghaghara Women Farmer Producer Company Limited) to simplify the administrative and cost process. ",
        },
        {
          type: "paragraph",
          text: "The Sal seeds sale symbolised a successful multi-stakeholder collaboration with a potential to create local wealth through women-led enterprise model. The sale happened at a competitive but profitable price for both the communities and the AAK industry. The collaboration brought together various partners, each with their unique approach. The JJBA, traditionally known for its activism-based model, Pradan’s FPC traditionally known for its NGO model, and JSLPS, the state supported model, partnered with us to undertake Sal seed collection, aggregation, and sale through a market-based approach.",
        },
        {
          type: "paragraph",
          text: "The initiative on transformative forest futures (ITFF) is an initiative by Bharti Institute of Public Policy (ISB) which seeks to create a sustainable business model for local wealth creation at village levels by creating direct market linkages between seasonal forest produce collectors and industries that make use of these produce as raw materials. In 2021, ISB signed an MOU with the Government of Jharkhand to facilitate the creation of a sustainable and resilient forest economy in the state anchored by security of tenure. The idea behind this was that tenure security incentivises communities to preserve and protect forests to avoid over-exploitation forest resources to ensure long-term livelihoods through forest economy.",
        },
        {
          type: "paragraph",
          text: "The Sal seed collection and aggregation process took place in villages with titles of community forest resource rights reflecting the potential of tenure security for the forest dependent communities. Further, the sale reflects the potential for building a sustainable and profitable model of forest-based economy that centres around forest communities and their market relationships with industries.",
        },
        {
          type: "paragraph",
          text: "We present you the successful story of Sal Seed Sale from Ranchi and Gumla district of Jharkhand, including our learning and challenges encountered throughout the journey.",
        },
        {
          type: "paragraph",
          text: "Heavy rainfall and weather disruptions posed a significant challenge to Sal seed collection ans aggregation. As the Sal seed season arrived, heavy rainfall and wind during the crucial Nautapa period – a continuous nine-day heat wave – and onwards significantly disrupted the Sal yield production leading to little production this year. Low yield production demotivated Seasonal Forest produce collectors from Sal seed collection and thus some of the districts had to be abandoned. Therefore, final Sal seed aggregation and procurement process took place in three locations – Burmu block (Ranchi), Raidih (Gumla) and Palkot (Gumla).",
        },
        {
          type: "paragraph",
          text: "The aggregated produce came up to 10.9 Metric Tonnes from all the three locations. Out of which, aggregation in Burmu was done on credit model despite high preference for cash in the block. While there was a significant leakage in the aggregation process as households in need of immediate cash sold parts of their produce to local vendors, they however ensured to keep some sacks of the produce for JJBA because they had committed to selling it to them. This reflected the communities trust in JJBA and their commitment towards them which made it possible for us to test the credit model. On the other hand, in the case of Pradan and JSLPS’s FPCs, their reliance on credit model turned out to be unsuccessful as most of the households sold their produce directly in the local markets for instant cash with very little procurement possible by FPCs.",
        },
        {
          type: "paragraph",
          text: "The sale of 10,900 kgs of Sal seeds by women—led FPC to AAK might seem little, however, the collaborative efforts demonstrate the potential and effectiveness of multi-stakeholder partnerships in facilitating and generating wealth at local levels.",
        },
      ],
      contentTags: ["Forest Governance", "Jharkhand", "Women-led", "Ranchi"],
    },
    "jhar-4": {
      topTitle: "JharFRA Training in Potka",
      topDesc: "",
      topImage: "jhar-4-img.png",
      contentHeading: "About the project",
      contentDescription: [
        {
          type: "aboutParagraph",
          text: "Women make up much of the seasonal collectors of forest produce and sell in the local market to supplement their incomes. The Initiative on Transformative Forest Futures (ITFF) by BIPP-ISB envisions to empower women by creation of women-led forest enterprises. The first step in fulfilling this vision is the recognition of community forest rights (CFRR).",
        },
        {
          type: "paragraph",
          text: "The ITFF has developed JharFRA, a digital application in consultation with the state designed to streamline the process of filing community forest resource rights claims. This innovation aims to enhance efficiency and scalability in managing these claims. The government of Jharkhand has adopted the application under the campaign, Abua Bir Abua Dishom Abhiyaan to upscale the process of community forest resource rights in the state. The rationale is to make use of the digital application to saturate the CFRR claims at scale.",
        },
        {
          type: "paragraph",
          text: "ITFF's operational plan focused on expanding efforts in the East Singhbhum district to enhance community forest resource rights through JharFRA. To achieve this, awareness and understanding of both JharFRA and the Forest Rights Act (FRA) is essential and thus required comprehensive training programs and community engagement to ensure effective adoption and utilization of the digital platform, ultimately promoting more efficient and widespread implementation of forest resource rights.",
        },
        {
          type: "paragraph",
          text: "The team met with the District Welfare Officer (DWO) of East Singhbhum in Jharkhand to discuss the district’s progress under ABAD Abhiyan. It was recognized that there was a need to accelerate the efforts and we advocated for a continuous review of Bir bandhus. To streamline the claim generation process, we proposed a phase-wise review cum training approach spanning three months, beginning with registration on the JharFRA Application.",
        },
        {
          type: "heading",
          text: "Timeline of events",
        },
        {
          type: "paragraph",
          text: "On 22nd April 2024, the ITFF team conducted a JharFRA training workshop in the Potka block of the district. The training discussed the process of CFRR from claim filing to title entitlement and the role of JharFRA in the CFRR claim process. We invited one representative from each Forest Rights Committees (either president or secretary). Over 100 people attended the training program with over 94 FRC members attending the training workshop. Some of them even brought their CFRR documents with required Gram Sabha signatures. While discussing the process of claim filing on the app, we tried to provide visual context by showing them how to register themselves on JharFRA. The village communities displayed great enthusiasm during the training workshop and were highly receptive to using JharFRA.",
        },
        {
          type: "paragraph",
          text: "We asked the attendees to download the application on their mobiles and walked them through the process of claim generation. However, it was also discovered village level data and CFR maps required in the application to progress to the next stage were missing. We intimated the information to our tech team and our technology team immediately started on creating resolving the issues. Similar workshops are also planned for other blocks to ensure comprehensive coverage.",
        },
        {
          type: "paragraph",
          text: "On 9th May 2024, as a part of our focused intervention in the district, a block-level review cum workshop on CFRR claim generation was organized at Dumaria block of East Singhbhum district. The workshop was attended by Bir Bandhus and FRC members. There are 10 BB and 68 FRCs in the block. The meeting saw participation from all 10 BB and 55 FRCs. During the workshop, these FRCs were registered on JharFRA and the attendees were trained on the CFR claim process up to phase 1.",
        },
        {
          type: "paragraph",
          text: "The Bir Bandhus demonstrated active engagement during the workshop and expressed interest in supporting FRCs in the CFR claim generation process. However, they indicated the need for financial support to cover at least their travel expenses. Providing financial incentives to cover these small expenses could greatly enhance their contribution to the process.",
        },
      ],
      contentTags: ["Forest Governance", "Jharkhand", "JharFRA", "Potka"],
    },
    "jhar-5": {
      topTitle:
        "Women-led FPC Sold 72,000 Kg Sal Seeds to AAK in Simdega, Jharkhand: Multistakeholder Partnership to Create Robust Forest Economy",
      topDesc: "",
      topImage: "jhar-5-img.png",
      contentHeading: "About the project",
      contentDescription: [
        {
          type: "aboutParagraph",
          text: "The successful large-scale sale of Sal seeds was made possible through the collaborative efforts of the ITFF team, JSLPS, and AAK, utilizing the aggregation capabilities of farmer Producer Companies (FPC). This collaboration aimed to generate wealth at the local level by leveraging the combined expertise and resources of the partner organizations",
        },
        {
          type: "paragraph",
          text: "On Sunday morning of June 11, 2023, trucks brimming with 72,000 kg of Sal seeds were unloaded at the AAK factory in Bilaspur, with a payment of Rs 10,87,650 to the FPC Kolibera, a formal collective of more than 3000 women operating in Kolebira block of Simdega district in Jharkhand. This remarkable sale symbolized the success of a multi-way collaboration, exemplifying the potential for the creation of wealth at the local level through women-led enterprises. This first-of-its-kind sale was made at a price competitive to both the communities & AAKIPL, thereby, holding immense promise for a long-term sustainable collaboration.",
        },
        {
          type: "paragraph",
          text: "This sale establishes the potential for building a sustainable, thriving, and profitable forest-based economy – one that benefits the forest communities, the industry, and the economy. ISB, AAKIPL, JSLPS, and the Govt. of Jharkhand recognize that this potential can be harnessed through the security of tenure of the forest-dependent communities.",
        },
        {
          type: "paragraph",
          text: "The Initiative on Transformative Forest Futures – an initiative by the Bharti Institute of Public Policy, Indian School of Business (ISB), Hyderabad business model is designed to generate wealth at the local level by establishing direct market linkages between seasonal forest product (SFP) collectors and companies utilizing these valuable resources as raw materials. ISB entered into a collaboration with the Government of Jharkhand in December 2021 to facilitate a robust & sustainable forest economy in the State, anchored in secure tenure. When secure tenure is in place, the communities have the incentive to protect and care for the forests, to avoid over-exploitation of forest resources, and, the right to transform their livelihoods through the sale of forest-based produce. With a special focus on empowering women-led enterprises through financial inclusion, our model has proven successful in implementation, yielding tangible results that speak to its effectiveness. We present you the successful story of Sal Seed Sale from Simdega, district of Jharkhand, including our learning and challenges encountered throughout the journey.",
        },
        {
          type: "heading",
          text: "The Story",
        },
        {
          type: "paragraph",
          text: "On 12 October 2022, AAK signed a MoU with ISB for working together in creating value and endorsing security of tenure towards building sustainable forest-based economies, anchored around Community Forest Resource Rights (CFR). The value will be created collaboratively by (a) Mechanization for reducing drudgery in collection/harvesting and preventing destruction in value, (b)Capacity Building for Aggregation, and (c) Financial inclusion & literacy.",
        },
        {
          type: "paragraph",
          text: "Following inventory mapping and an in-depth study on the utilization of Sal seeds in the confectionery and oil industries, we strategically selected Sal as the pilot Seasonal Forest Product (SFP) for the Simdega region. Extensive preparations were undertaken, including a collaborative partnership with JSLPS for capacity building and financial inclusion. However, due to unforeseen rainfall patterns, the collection of Sal seeds was impeded, resulting in the unfortunate absence of sales in the previous year.",
        },
        {
          type: "paragraph",
          text: "With extensive groundwork and over a year of dedicated efforts in Simdega, the ITFF team was well-prepared for this year’s sale. Recognizing the limited 15-day window (15-30 May) for Sal seed collection, meticulous preparations were undertaken. Shri Arif Akhtar, State Program Manager at JSLPS, Rural Development Department Government of Jharkhand was highly instrumental in devising a comprehensive plan for implementing a community-managed Sal seed-based livelihood initiative. His efforts led to the successful launch and execution of the collectivization drive. Under his guidance, the entire community achieved success in the Saal seed collectivization business, despite facing numerous challenges. They effectively handled the procurement, ensuring daily cash payments to the community, and managed the processing and initial operations of the value chain. Additionally, with the support of the block team, he played a crucial role in motivating the communities and dealing with middleman dynamics. Despite the absence of sufficient finances, he skillfully managed the large-scale initiative with the limited resources available. To quote Shri Akhtar, “It has been a phenomenal journey to see the women-led community-owned FPC’s leadership transact with a large buyer for a direct sale of big scale. I am looking forward to this model to grow many times over.”",
        },
        {
          type: "paragraph",
          text: "After establishing a comprehensive value chain for Sal Seed through numerous discussions and refinements, the execution phase commenced in April, marked by an introductory meeting with stakeholders and relevant higher officials to ensure seamless coordination and cooperation. These meetings included engaging with the District Program Manager of JSLPS to seek their valuable assistance in capacity building, conferring with the Divisional Forest Officer and District Commissioner of Simdega to address potential roadblocks, consulting with the HDFC cluster Head and General Head of NABARD to ensure financial inclusion and availability of funds for SHGs/FPCs if required. Furthermore, meetings were conducted with the FPC and SHG members, enabling us to inform them about the upcoming sale and empowering them to disseminate this information among the collectors. Additionally, the significance of aggregation economics and the economics of scale were articulated to emphasize the importance of participating in the sale.",
        },
        {
          type: "tagsParagraphSection",
          text: ["Focus geography:", "Three blocks of Simdega-"],
          tags: ["Jaldega", "Bano", "Kolebira"],
        },
        {
          type: "heading",
          text: "Stakeholders",
        },
        {
          type: "paragraph",
          text: "JSLPS (Jharkhand State Livelihood Promotion Society) as a mobilizer, AAK as the buyer, FPC Kolebira as a seller, ITFF team as a facilitator of the sale.",
        },
        {
          type: "paragraph",
          text: "Commencing on the 2nd of May, the ITFF team proactively initiated a series of meetings with key stakeholders, ensuring preparedness for the upcoming sale scheduled for the first week of June. Here is the list of events that happened to make the sale possible.",
        },
        {
          type: "bulletPoints",
          text: [
            "In a concerted effort to ensure long-term sustainability, AAK-ISB-JSLPS organized a comprehensive three-day workshop across all three blocks, focused on educating collectors about sustainable and hygienic collection practices. This workshop aimed to empower the collectors with the knowledge and skills necessary for fostering a sustainable and environmentally conscious approach to their operations.",
            "Mr. Abhijeet Parmar and Mr. Sandip Chowdhury from the ITFF team met the Chief Welfare Secretary to update him about the Sal seed sale and to ask his assistance for expedited coordination in Simdega.",
            "Sushma (Head-Strategic initiative), Sukanya (Enterprise Lead), Sandip (ITFF’s National Coordinator) from ISB, and Anubhav Shrivastava (Procurement Head from AAK) came to Ranchi and had multiple meetings with JSLPS, Mr. Diprava Lakra, Welfare Secretary to lay out the plan and divide the responsibilities. The major discussion revolved around the final price of Sal seeds to FPC, advance money to FPC as a token of trust, quality of Sal seeds, one point of aggregation vs multiple, etc.",
          ],
        },
        {
          type: "heading",
          text: "Major Challenges",
        },
        {
          type: "bulletPoints",
          text: [
            "Insufficient Fund; to address the issue of insufficient funds ahead of time, meetings were coordinated with Banks, NABARD, and JSLPL, resulting in the assurance that the FPC had ample financial resources for the sale season. However, complications arose when it was discovered that the available funds were designated for CIF rather than for FPCs. Lastly, Affan, the Block Coordinator from JSLPS and acting CEO of the FPC Kolebira provided invaluable support by allocating sufficient funds to the rural service centers, enabling upfront payments to the collectors. This timely intervention alleviated the financial constraints and ensured a smooth execution of the sales. ",
            "Price Selection; the selection of an appropriate price posed a challenge, with the FPC emphasizing the importance of early price disclosure to mobilize collectors and facilitate aggregation. However, due to uncertainties surrounding transportation costs and the quality and quantity of Sal seeds, an early disclosure was not feasible. The price was eventually revealed once the local markets opened. Nevertheless, a challenge arose as local traders increased the prices to Rs. 18 per kg upon witnessing the mobilization drive. In response, we engaged in conversations with several traders, cautioning them against artificially inflating prices if they intended to sustain their market presence in the long term. Leveraging the longstanding connection of JSLPS with communities and ensuring clear communication about the necessity of aggregation, we successfully mobilized and collected a substantial amount of 72,000 kilograms of Sal seeds.",
            "Quality of Sal Seeds, throughout the initial discussions, AAK expressed concerns regarding the quality of the sal seeds. To address these concerns, samples were collected from Jaldega and Kolebira and sent to AAK for evaluation. As the mobilization efforts commenced, there was uncertainty surrounding whether the collected sal seeds would meet the required standards. On 1st June, we received an update from Suresh at AAK, indicating that the sample from Kolebira met their requirements, while the sample from Jaldega was rejected due to insufficient stearin quality. Affan provided invaluable support by allocating additional manpower at the rural service centers to ensure thorough quality checks using moisture meters. Consequently, we successfully collected 30 tonnes in Kolebira and 40 tonnes in Bano, meeting the required standards for AAK.",
            "Arrangement of Logistics; in the process of organizing logistics, a hurdle emerged when no trucks were available on 5 June. In a collaborative effort, JSLPS and ITFF joined forces to arrange trucks. Finally, Mr. Sandip successfully arranged three trucks, with one 30 metric-ton truck destined for Kolebira and two 25 metric-ton trucks for Bano. To ensure a seamless execution, two transport permits were prepared, one for Jharkhand and another for Chhattisgarh, as the drop location was Bilaspur. Mr. Sandip accompanied the trucks to facilitate a smooth transportation process. The appointed transporter was registered as an AAK vendor and received direct payment from AAK.",
          ],
        },
        {
          type: "paragraph",
          text: "The successful sale of 72,000 kg of Sal seeds by the women-led FPC to AAK in Simdega, Jharkhand, highlights the effectiveness of the multistakeholder partnership in creating a robust forest economy. Despite challenges, the collaborative efforts ensured a seamless execution, demonstrating the potential for generating wealth at the local level. Establishing a multistakeholder business model is crucial for fostering secure and sustainable futures for communities and forests. This collaborative approach ensures the harmonious coexistence of economic development and environmental preservation, laying the foundation for a prosperous and sustainable future.",
        },
      ],
      contentTags: [
        "Forest Governance",
        "Jharkhand",
        "Women-led",
        "Saal Seeds",
        "Partnership",
      ],
    },
    "jhar-6": {
      topTitle:
        "A successful government-supported pilot implementation of CFRR in Simdega, Jharkhand",
      topDesc: "",
      topImage: "jhar-6-img.png",
      contentHeading: "About the project",
      contentDescription: [
        {
          type: "aboutParagraph",
          text: "The Initiative on Transformative Forest Futures by BIPP-ISB has a vision to create women-led forest enterprises in Jharkhand. The first step to fulfilling this vision is the mandatory recognition of community forest rights (CFR). The ITFF team developed a participatory model to upscale the process of filling claims and securing CFR, incorporating input and guidelines from the state government. This model will be replicated across the state after it is successfully implemented in pilot clusters (at the block level) of villages. We were able to fill and deliver six CFR claims to the Bano Block office for verification, owing to the tremendous efforts of our team’s National Coordinator Mr. Sandip Chowdhury.",
        },
        {
          type: "tagsParagraphSection",
          text: [
            "",
            "Timeline of events for successful filling and delivery of the CFR claims in Bano, Jharkhand",
          ],
        },
        {
          type: "paragraph",
          text: "Our team began working with multiple stakeholders in Simdega in September 2022 to initiate the scaling up of the Community Forest Resource (CFR) rights claim filing process across clusters of 175 villages in the blocks of Jaldega, Kolebira, and Bano chosen as pilots for our initiative. We conducted three multistakeholder block-level meetings at each of the three pilot blocks. The operational plan included each stakeholder’s detailed role and their responsibilities in this process to upscale CFR and acted as a baseline for monitoring and learning indicators across the project’s implementation timeline.",
        },
        {
          type: "paragraph",
          text: "On October 26, 2022, ITFF’s National Coordinator, Mr. Sandip Chowdhury met with Mr. K Srinivasan, the State Welfare Secretary of Jharkhand, to discuss the Initiative on Transformative Forest Futures, the adoption of the mobile app RatiFi, and the status of the claim filing process for Community Forest Resource Rights within the state.",
        },
        {
          type: "paragraph",
          text: "Mr. K Srinivasan was enthusiastic to include RatiFi, the Android-built mobile application, in the Forest Economy business model. RatiFi will facilitate uniformity, easy and efficient access to evidentiary documents, and rapid scalability. The Android version of the app is currently accessible on the Google Play Store. He proposed multiple applications of RatiFi in the Forestry Service.",
        },
        {
          type: "paragraph",
          text: "We discussed the progress and potential of implementing ITFF’s vision with Mr. Deepak Lakra and the State Welfare Secretary in Ranchi. The geographic focus of this meeting was primarily Simdega, with later stage inclusion of all potential CFR areas in Jharkhand. This covered, inter alia, the SHG architecture supported by the Jharkhand State Livelihood Promotion Society (JSLPS), the credit system, the June sale of sal seeds, and mechanisation possibilities. The generation of CFR claims in Simdega was a major point of discussion. Mr. Lakra and the Welfare Secretary advised us to complete the CFR claim process for 10–20 of our pilot villages in Simdega in a focused manner and then think of scaling our efforts. We have, therefore, selected 15 villages in the Bano block to fast-track the claim generation process in three panchayats. We also agreed on holding regular online meetings to discuss and update respective officials on the progress in Simdega.",
        },
        {
          type: "paragraph",
          text: "Mr. Sandip Chowdhury, ITFF National Coordinator, regrouped with the team of interns to prioritise work according to the new plan of action. We started form-filling for the selected villages and analysed the uploading process of filled-up forms on RatiFi. The ITFF team began with the form-filling in these villages in Simdega. Form-filling for six villages in Bano under the Bintuka panchayat commenced on Friday, January 20th. The form-filling process was then expedited in 15 villages in three panchayats: Semhatu, Bera Erge, and Bintuka. The digital versions of the Khatian (Record of Rights) for a few villages in Semhatu and Bera Erge were missing. We tried to get them from the Bano Block office. CFR maps of a few villages were missing, so our technology team immediately started on creating these maps. The progress in six villages of Bintuka was better as compared to others, and our team submitted the documents for physical verification to the Circle Inspector (CI) at the Revenue Department of the Bano block.",
        },
        {
          type: "paragraph",
          text: "In mid-February of 2023, the feedback on the efficacy and experience of the app was shared with Prof. Ashwini Chhatre, Executive Director of BIPP-ISB, for his remarks. We tested the theory with the JSLPS ground mobilisation cadre after incorporating his suggestions. Meanwhile, field-level processes such as FRC formation, map creation, evidence document collection, and work allocation to new FRC members are continued. The interns have been trained to use the updated forms, and a strategy has been devised to speed up all of the processes.",
        },
        {
          type: "paragraph",
          text: "Finally, on February 27, 2023, we successfully submitted CFR claims for six out of the 15 selected villages to Cl, Bano for verification. His office has asked us to make some changes and resubmit. They proactively shared copies of “Gairmajurwa Jungle Jhadi Vivran Prapatr III,” which can support the claim filling process for nearly every village in the Bano block. This will help us prepare better claims for the entire block, including the nine claims that are in progress.",
        },
      ],
      contentTags: [
        "Forest Governance",
        "Jharkhand",
        "CFRR",
        "Simdega",
        "Chief Minister",
      ],
    },
    "jhar-7": {
      topTitle:
        "Jharkhand Bioeconomy Mission: Tapping the potential of community-owned forests and technology",
      topDesc: "",
      topImage: "jhar-7-img.png",
      contentHeading: "About the project",
      contentDescription: [
        {
          type: "aboutParagraph",
          text: "At the Jharkhand Budget Conclave on 3rd February 2023, which was presided over by the Chief Minister Shri Hemant Soren, Professor Ashwini Chhatre discussed the immense potential for bioenergy production in Jharkhand’s forested areas and the importance of secure tenure to unlock this opportunity. Prof. Ashwini Chhatre proposed the Jharkhand Bioeconomy Mission that aims to promote the growth of the bioenergy sector through collaboration across agencies and departments. The event was attended by many prominent figures, including the Jharkhand Chief Minister Hemant Soren and Chief Secretary Sukhdev Singh. Professor Ashwini, in the presence of a 200+ audience that comprised leaders from both the private and public sectors, outlined the massive potential from constitution of this mission. The Jharkhand Bioeconomy Mission is set to play a crucial role in the development and growth of the bioenergy industry in the region.",
        },
        {
          type: "paragraph",
          text: "Prof. Ashwini Chhatre began his speech at the Conclave by setting the economic premise of partnerships that can be based on the principle of crowding in private investments, which states that public finance can be used to attract private investment. He pointed out the power of “crowding in” private investments, where every rupee of public finance invested can bring in more private capital. He emphasized that state interventions are most effective when they provide a guarantee of profitability to private investors. He stressed that the goal of a state budget should be to encourage private capital to flow in and support the development of the region. He stated that” state interventions are most successful – when it enables private capital to flow in with an assurance that it will make money for the investors.”",
        },
        {
          type: "paragraph",
          text: "Prof. Ashwini Chhatre highlighting the potential of Jharkhand’s forest landscapes and the subject garnered unwavering attention of the audience. He emphasized the significance of these discounted forest land resources and their critical role in driving economic growth and development in the state. “I want to point to the large presence of forest land that does not have forests – in the best estimates it is about 1.5 million hectares, i.e., 15 lakh ha – that is forest land but does not have trees – either open forest or no forest. This is right now producing nothing and caught in a quagmire of regulatory tripwires.,” he shared, and this fundamental idea set the stage for the Jharkhand Bioeconomy Mission.",
        },
        {
          type: "paragraph",
          text: "These forested lands with high restoration potential present a significant opportunity for forest regeneration with native species such as bamboo. Bamboo, in its native landscapes, not only grows fast but also thrives from regular harvesting and will sequester large amounts of carbon both above and below ground. This bamboo will serve as valuable raw material for industry and nation’s pressing need for ethanol and fossil-fuel alternatives.",
        },
        {
          type: "paragraph",
          text: "Prof. Ashwini Chhatre also highlighted the 1.2 million hectares of standing Sal forests in Jharkhand as a significant opportunity for economic growth and development. He estimated that even if only 10% of the seeds that fall are collected and processed into biodiesel at the current rates that Indian oil companies are willing to pay (Rs. 70/litre), the production of biodiesel would be worth Rs. 6 Lakh crores ($8 billion).",
        },
        {
          type: "paragraph",
          text: "He provided further context by explaining that “A bioeconomy mission for the state of Jharkhand fortunately does not require additional resources. It does not require the budget to create new line items. What it requires is a coordinated mechanism – because the Sal seeds already exist, the land already exists – there is a pool of central funding such as CAMPA, NREGA and other centrally sponsored schemes that can be harnessed and targeted at specific landscapes where these resources already exist in extremely large numbers, using technology that is well used and commercially available for large scale application and, building on the current entrepreneurship that already exists.”",
        },
        {
          type: "paragraph",
          text: "This mission can fructify through a concerted effort to convert this standing wealth into a source of energy that will reduce emissions and put Jharkhand on the map as a leader in India’s future energy sector. Prof. Ashwini Chhatre proposed a “triple win” solution to address issues such as unemployment, forest degradation, and elevated emissions leading to climate change. This mission can fructify through a concerted effort to convert this standing wealth into a source of energy that will reduce emissions and put Jharkhand on the map as a leader in India’s future energy sector. India can become a beacon of hope for the rest of the world and Jharkhand can lead that way in emission reduction.",
        },
      ],
      contentTags: [
        "Forest Governance",
        "Jharkhand",
        "Bioeconomic",
        "Forest",
        "Technology",
      ],
    },
    "jhar-8": {
      topTitle:
        "14 CFRR Claims Submitted to SDLC, Simdega: CFRR Titles Empower Communities towards Building Robust Forest Economy",
      topDesc: "",
      topImage: "jhar-8-img.png",
      contentHeading: "",
      contentDescription: [
        {
          type: "paragraph",
          text: "Marking the culmination of a long and challenging journey, Sandip Chowdhury, ITFF’s National Coordinator, successfully submitted 14 Community Forest Resource Rights (CFRR) claims on behalf of the communities to the Sub-District Level Committee (SDLC) in Simdega, Jharkhand, on 14th June 2023. This significant milestone was made possible due to the unwavering dedication of our six ITFF field interns- Chandrakanta, Sunita, Asraf, Shiv, Vinod, and Sudama – alongside the resilient communities who persevered through scorching summer conditions. Encouragingly, two more claims are currently in progress. Furthermore, the CFRR claim process is underway for over 50 additional villages in Simdega, including 21 claims currently undergoing joint verification in the Jaldega block.",
        },
        {
          type: "paragraph",
          text: "In theory, the process of receiving a CFRR title simply involves filing a claim at three levels: block, sub-divisional, and district. However, the practical execution of this process has been hindered by a lack of uniform comprehension of the Forest Rights Act and limited support from the forest department. Consequently, the process has taken longer than anticipated, surpassing the expected timeframe.",
        },
        {
          type: "paragraph",
          text: "Our journey for CFR filling in Simdega began in January with the collection of necessary documents and the formation of the Forest Right Committee. Owing to a dedicated team on the field, by March 2023, we had successfully submitted 14 claims at the block level. However, due to the confusion surrounding certain terms used in the Forest Rights Act, the forest department rejected these claims with written remarks outlining their objections.",
        },
        {
          type: "paragraph",
          text: "Determined to overcome these hurdles, we sought guidance from experts well-versed in the Forest Rights Act and engaged in consultations with higher officials. With their support, we successfully refuted the objections raised by the forest department Simdega during the joint verification stage. In accordance with the provisions of the Forest Rights Act, the Gram Sabhas played a crucial role in this process. They passed appropriate resolutions in their second meeting held in June, effectively nullifying the objections. Additionally, we established seven Community Forest Management committees to further strengthen our efforts.",
        },
        {
          type: "paragraph",
          text: "As a result of our collective endeavours, we were able to resubmit the 14 claims to the Sub-District Level Committee (SDLC). This journey has been an invaluable learning experience, and we have now established well-defined systems and processes. With this solid foundation in place, the CFRR filing process will continue to make significant progress and achieve even greater success moving forward to pave the way for value creation at the local level, not hundreds of miles away from the forests.",
        },
      ],
      contentTags: [
        "Forest Governance",
        "Jharkhand",
        "CFRR",
        "Robust",
        "Forest Economy",
      ],
    },
  },
  odisha: {
    "odi-1": {
      topTitle: "",
      topDesc:
        "IoFE team guiding communities in inventory mapping with nCount.",
      topImage: "odi-1-img.png",
      contentHeading:
        "Building Biodiversity Champions: The nCount Training Program for Advanced Ecological Monitoring",
      contentDescription: [
        {
          type: "paragraph",
          text: "On the 15th of February, 2024, a specialized Physical Training Program focusing on Inventory Mapping was hosted in Odisha( Jashipur, Mayurbhanj), leveraging the nCount platform. This program was directed towards imparting a detailed training regime on the intricacies of inventory mapping, employing an integrated approach of nCount with ODK Collect to both the personnel of Vasundhara and community volunteers. Convening within the confines of Vasundhara’s office, the session illuminated the critical importance of inventory mapping and its prospective applications, setting the stage for future engagements in ecological conservation and management.",
        },
        {
          type: "paragraph",
          text: "The program’s inclusive nature was highlighted by the participation of five Program Assistants hailing from a variety of districts, including Mayurbhanj, Deoghar, Kandhamal, Nayaghar, and Sambalpur, showcasing a commitment to diversity and collaborative learning. Following the necessary registration process, which required internet access, the assembled participants proceeded to a nearby forest. This field exercise demonstrated the practicality and efficiency of the nCount-facilitated data collection process, underscoring the platform’s user-friendliness and effectiveness.",
        },
        {
          type: "paragraph",
          text: "The subsequent engagement at Suanpal village within the Jashipur block further accentuated the benefits of inventory mapping. Dialogues with Vasundhara staff here were particularly poignant, focusing on the strategic use of nCount technology for bolstering future conservation and management projects. This was especially relevant considering the village’s acquisition of the Community Forest Resource (CFR) Title in 2014 and the ongoing efforts to establish a Community Forest Resource Management Committee (CFRMC), underlining a forward-looking approach to employing nCount technology for tangible community and ecological benefits.",
        },
        {
          type: "heading",
          text: "About nCount",
        },
        {
          type: "paragraph",
          text: "nCount stands as a beacon of innovation in the realm of ecological knowledge enhancement and the stewardship of natural resources. It melds the realms of gamification, remote sensing, and computer vision to cultivate an engaging, interactive platform that rejuvenates the processes involved in biodiversity data collection. The application of remote sensing technology within nCount provides for the real-time observation and analysis of ecosystems, offering indispensable insights for conservation activities. Moreover, the precision afforded by computer vision substantially improves the accuracy of species identification and population monitoring, thereby deepening the collective understanding of biodiversity dynamics. In essence, nCount not only accelerates biodiversity research but also strengthens governance models dedicated to the sustainable oversight of natural resources.",
        },
        {
          type: "paragraph",
          text: "Extending its utility beyond the conventional scope of forest ecosystems, nCount integrates visual object recognition modules with an Environmental DNA (eDNA) metabarcoding analysis component. This innovative combination is designed to streamline the assessment, evaluation, and surveillance of natural ecosystems, enhancing the platform’s capability to support comprehensive ecological studies and conservation strategies effectively.",
        },
      ],
      contentTags: [
        "nCount Workshop",
        "Technology",
        "Fieldwork",
        "Himachal Pradesh",
      ],
    },
    "odi-2": {
      topTitle: "",
      topDesc:
        "IoFE team guiding communities in inventory mapping with nCount.",
      topImage: "odi-2-img.png",
      contentHeading:
        "Jamia Conducted a one-Day Mobile Based Filming Workshop in Malkangiri, Odisha: Empowering Voices of Young Tribal Girls",
      contentDescription: [
        {
          type: "aboutParagraph",
          text: "A volunteer training program was conducted by the faculties of Jamia Millia Islamia University, focusing on mobile-based short filmmaking on 6 June 2003 in Telarai Panchyat, Kalimela. This seven-hour-long training aimed to equip volunteers with the skills and knowledge necessary to create impactful films that explore the multifaceted relationship between forest resources and tribal communities in Malkangiri. As a result of this training, the volunteers from forest communities, especially from Bonda Ghati, have taken the initiative to assert their individual voices, establish their own presence on social media platforms, and even organize a unique film festival. This festival will delve into the diverse dimensions of this interaction, shedding light on the myriad colors and complexities that exist in their daily lives.",
        },
        {
          type: "heading",
          text: "Impact of the Workshop among Young Tribals and Immediate Action Plan",
        },
        {
          type: "paragraph",
          text: "All 30 volunteers, after the training, embarked on their journey to produce captivating films, and we have made noteworthy progress in streamlining the process.",
        },
        {
          type: "paragraph-heading",
          text: "Structuring the Storyline:",
        },
        {
          type: "paragraph",
          text: "One of the challenges faced by our volunteers pertained to formulating an engaging storyline. To address this, a dedicated team organized field training sessions aimed at assisting volunteers in structuring their stories, video shoots, and editing processes effectively.",
        },
        {
          type: "paragraph-heading",
          text: "Hand-Holding Training in Bonda Ghati:",
        },
        {
          type: "paragraph",
          text: "To provide comprehensive support, we devised a three-step hand-holding training program that focused on story development, shooting techniques, and editing skills. This training took place during a one-day camp in a village, where volunteers were encouraged to conduct interviews as a starting point for their films. Furthermore, they spent time in the village to capture natural shots and enhance the quality of their footage. Almost all the female volunteers have successfully completed this training. Subsequently, a full-day video editing training session is scheduled, after which they will publish their films on their own social platforms.",
        },
        {
          type: "paragraph-heading",
          text: "Learning through Interviews and Voice-Overs:",
        },
        {
          type: "paragraph",
          text: "Interviewing subjects and utilizing voice-overs to convey the story have emerged as the primary methods through which our volunteers are gaining expertise. Once they publish their first film, they gain the confidence to produce more captivating content from their respective locations.",
        },
        {
          type: "paragraph-heading",
          text: "Depository and Platforms:",
        },
        {
          type: "paragraph",
          text: "We have designed a protocol for the deposition of raw and edited footage. ITFF field support Bhavani has been appointed to lead this initiative full-time, ensuring its expansion to more villages with young tribal girls as “Jungle Rani Reporters.” Their primary focus is to highlight issues related to forests and natural resources. These films will be published on two platforms: the Facebook page dedicated to the “Malkangiri forest economy” and the “Bado didi’s YouTube channel”. We are actively exploring additional platforms to amplify the impact of our initiative.",
        },
        {
          type: "paragraph-heading",
          text: "Own Film Festival:",
        },
        {
          type: "paragraph",
          text: "Recognizing the critical importance of providing forest communities with a platform to tell their narratives, the “Own Film Festival” is scheduled to take place on 21-22 July 2023. The festival will showcase the ten best films produced by our Jungle Rani Reporters, offering a platform to celebrate their talent and raise awareness about crucial environmental and tribal concerns.",
        },
        {
          type: "paragraph",
          text: "Moving forward, the LoFE team is dedicated to focusing on three key aspects:",
        },
        {
          type: "bulletPoints",
          text: [
            "Refresh Short Course/Training; We will be conducting a short refresher course to update our volunteers with the latest techniques and approaches in reporting and filming.",
            "Digital Depository; We aim to establish a digital depository where all raw and edited footage can be safely stored and accessed for future reference and collaboration.",
            "Branding the “Jungle Rani Reporters”, We seek to position the Jungle Rani Reporters as an initiative led by young tribal girls dedicated to documenting and addressing issues related to forests and tribal communities. Through branding efforts, we aim to raise awareness and foster support for their valuable work.",
          ],
        },
        {
          type: "paragraph",
          text: "“Jungle Rani reporters” is an initiative of young tribal girls who will record and raise issues around the forests and tribals.",
        },
      ],
      contentTags: ["Women Empowerment", "Jungle Rani", "Workshop", "Odisha"],
    },
    "odi-3": {
      topTitle: "",
      topDesc: "",
      topImage: "odi-3-img.png",
      contentHeading:
        "Mayurbhanj Jungle Monch (MJM) Joins the Initiative on Transformative Forest Futures (in-progress)",
      contentDescription: [
        {
          type: "paragraph",
          text: "What started as a small movement in the forest areas of Mayurbhanj district, Odisha, over four decades ago, has now catapulted into an organisation of like-minded community members who have dedicated their lives to saving forest reserves. Meet the 40-year-old Mayurbhanj Jungle Monch (MJM), a public trust organisation, working in the Mayurbhanj district, Odisha, and a large chunk of Simlipal National Park. Mayurbhanj Jungle Monch is the latest community-led organisation to join our initiative.",
        },
        {
          type: "paragraph",
          text: 'ITFF’s small yet dedicated team based in Baripada, Mayurbhanj, is facilitating the implementation of a district-specific program in collaboration with the Department of Mission Shakti, Government of Odisha. The collaboration between ITFF and MJM is to further explore the opportunities to have a better understanding of CFRR claims through "title" holding and marketing opportunities for the Seasonal Forest Products (SFPs).',
        },
        {
          type: "paragraph",
          text: "The team in Baripada led an interactive session, ‘Bichar Charcha,’ with MJM for a community-level intervention aimed at enhancing tenure security rights in Mayurbhanj. They also sought community-level volunteer support for ITFF’s technology platform, ‘nCount,’ and other district-specific initiatives under ITFF.",
        },
      ],
      contentTags: ["Mayurbhanj", "Jungle Monch", "ITFF", "Odisha"],
    },
    "odi-4": {
      topTitle: "",
      topDesc: "",
      topImage: "odi-4-img.png",
      contentHeading:
        "Tribal Women Come Together to Share Dreams, Aspirations, and Life’s Lessons at Jungle Rani’s Bichar Charcha Forum",
      contentDescription: [
        {
          type: "paragraph",
          text: 'I was young and couldn’t read a word since I have never been to school. How could I go even when there was no school? My parents got me married at the age of 15, and I came to live in a new place. My husband used to travel to far-off places, and I had to manage the house on my own with no idea about anything. How to manage money, how to buy things from the market, and how to take money from the vendor who was taking Mohua seed or rice from my farm. It was quite a struggle for me. It was then that I decided that if I have a daughter, I’ll send her to school for a good education so that they should not become me and go through the same struggle I had to go through."',
        },
        {
          type: "paragraph",
          text: "These words shared by Sambari Sisa, a Bonda tribal women leader, resonated with hundreds of women who had gathered at the ‘Bichar Kendra’, organised by Maha Gram Sabha (Kalimela), a community-based organisation comprising local forest communities in Odisha's Malkangiri District. Volunteers from Jungle Rani and Bado Didi Union, along with the members of the ITFF team, organised the ‘Bichar Kendra'—a forum for women from the forest communities to gather and share their life experiences and aspirations.",
        },
        {
          type: "paragraph",
          text: "Taking the opportunity, members of the ITFF team and Gram Sabha Jakalkundi, Kalimela, highlighted the importance of the industrial marketing of forest products through the establishment of a Jungle Producer Company (PC) and securing tenure. They also shared examples of PC and its functioning in promoting women's livelihood.",
        },
        {
          type: "paragraph",
          text: "‘Bichar Kendra’ became a reality after volunteers conducted dozens of meetings, meticulously planning for weeks through discussions with women members with the common goal of providing a platform for women to come together and share experiences.",
        },
        {
          type: "paragraph",
          text: "For the first time, 200 women from 20 different villages belonging to various Adivasi groups like Bonda, Koya, and Desia, who face barriers in accessing even primary institutional facilities such as healthcare, education, etc., came together to discuss their rights to the forest, livelihood, their aspirations for their children's education, and actions they could take to battle institutional or domestic violence.",
        },
      ],
      contentTags: ["Tribal Women", "Jungle Rani", "Bichar Charcha", "Odisha"],
    },
    "odi-5": {
      topTitle: "",
      topDesc: "",
      topImage: "odi-5-img.png",
      contentHeading:
        "Setting up Ground for Bamboo Sale in Malkangiri, Odisha: Joining Forces with BGPPL to Foster Forest Economy",
      contentDescription: [
        {
          type: "paragraph",
          text: "On June 25, 2023, a business meeting took place at Telrai GP, focusing on the upcoming Bamboo Sale in July between Gram Sabha and Bilt Graphic Paper and Pulp Industries (BGPPL). The meeting was held in the Conference Hall and was organized by the Maha Gram Sabha, Kalimela. The event witnessed the presence of all 24 GS leaders, who were actively engaged in direct interactions with Mr. Akhilesh Bharti, the Manager of BGPPL in Chandrapur, Maharashtra. The primary objective of the meeting was to determine the precise rate of Bamboo based on its weight in tons. After the meeting, Mr. Bharti promptly visited the Bamboo depot situated at Sudhakonda to understand the operations.",
        },
        {
          type: "paragraph",
          text: "ITFF aims to establish a direct connection between forest communities and the first-mile industries using seasonal forest products (SFPs) as raw materials. This initiative seeks to eliminate intermediaries in the trade process of SFPs. The forthcoming Bamboo sale in Malkangiri is a step towards fortifying the ITFF vision and generating wealth at the local level.",
        },
        {
          type: "paragraph",
          text: "We will delve into the intricacies of our journey so far, shedding light on the extensive efforts and diverse strategies employed to make the upcoming sale possible. We shall dissect the work into five distinct verticals, each with a specific focus. The ensuing list outlines these essential aspects.",
        },
        {
          type: "paragraph",
          text: "Community Forest Resource Rights (CFRR) titles:  In March 2022, we initiated our operations by engaging with government officials to seek assistance in CFRR (Community Forest Resource Rights). Our efforts encompassed raising awareness about the Forest Rights Act (FRA) and specifically highlighting the importance of Community Forest Rights (CFR).",
        },
        {
          type: "paragraph",
          text: "ISB-BIPP, in collaboration with the Integrated Tribal Development Agency (ITDA), dedicated the first workshop to developing a plan of action for drafting bamboo management plans and understanding existing capacity gaps. The tehsildar, two range officers, Forest Department (FD) officials, the Sub-Collector Forest Rights Committee (FRC), the Sub-Collector Sub-Divisional Level Committee (SDLC), BILT Graphic Paper Products Ltd., and the leaders of twenty-four villages from Malkangiri participated in this workshop to provide support and inputs.  We actively filed claims and established Maha Gram Sabhas to develop sustainable bamboo management plans. As a result, these Gram Sabhas are now directly involved in the sale process alongside BGPPL, fostering a collaborative and mutually beneficial partnership.",
        },
        {
          type: "paragraph",
          text: "Technical Intervention: To expedite the initiative, we leverage technology in two ways (i) Digital Intervention, to file the CFRR titles at scale with efficiency, we use RatiFy, a mobile application and handy GPS to mark the boundaries accurately. To bring the market visibility of the seasonal forest products, the ITFF team engages in inventory mapping. These estimations have proved invaluable in facilitating larger trade opportunities with communities, benefiting both BGPPL and mobilizing the local communities effectively. (ii) Mechanical Intervention, to alleviate the drudgery involved in collecting SFPs and enhance the efficiency and profitability of trade, we collaborated with IIT Delhi to develop mechanization tools such as bamboo splitter, moisture meter, etc. As part of our preparations for the upcoming Bamboo sale, we have implemented the use of a Bamboo Splitter, a tool designed to divide bamboo into parts. By doing so, we aim to reduce transportation costs by increasing the loading capacity of bamboo per ton for trucks. The investment in the Bamboo Splitter not only benefits the bamboo trade but also extends its advantages to other SFPs, benefiting various communities.",
        },
        {
          type: "paragraph",
          text: "During the deployment of the Splitter machine to Malkangiri, we encountered a significant obstacle related to the requirement for 3-phase electricity. Complications arose when the designated area to house the machine in Malkangiri turned out to be smaller than anticipated, and there was a misunderstanding regarding the provision of 3-phase electricity. Consequently, the process was delayed. However, we have taken steps to rectify the situation by ensuring that all eight villages have submitted applications for the installation of three-phase electricity. Furthermore, we have undertaken the task of training women to operate the bamboo splitter. By empowering women with the necessary skills, we aim to create opportunities for them to actively participate in the mechanization process and contribute to the success of the initiative.",
        },
        {
          type: "paragraph",
          text: "Corporate Engagement: As BGPPL is a major buyer in the sector and has been operating in Maharashtra for several decades, we engaged with them for bamboo sale, for the procurement of bamboo from community-owned forests directly to industry. The discussions with BGPPL revolved around the large-scale supply, aggregation, and collective sale, pricing of the products, payment timelines, payment mode, and planning.",
        },
        {
          type: "paragraph",
          text: "Financial Inclusion: With a focus on women-led enterprises, ITFF is actively involved in assisting women in establishing producer groups that will eventually form a unified Producer Company (PC). After the consultation with the department of Mission Shakti and introductory meetings held in Gram Sabhas (GS), we ensure the transfer of knowledge regarding the functionality and registration process of the PC. Our objective is to complete the registration of both Producer Groups (PGs) and PCs prior to the upcoming sale. This will enable the PC to purchase bamboo from the GS and subsequently sell it to BGPPL after implementing the mechanization process using the bamboo splitter. By empowering women and fostering women-led enterprises, we strive to create a sustainable and inclusive business environment.",
        },
        {
          type: "paragraph",
          text: "Mission Shakti Collaboration: ISB and the Department of Mission Shakti have forged a significant partnership through a three-year Memorandum of Understanding (MoU) aimed at promoting women’s empowerment and fostering rural prosperity through the establishment of a sustainable forest economy. The collaboration with Mission Shakti has been instrumental in successfully establishing a Producer Company (PC) and effectively implementing initiatives on the ground. This collaboration not only strengthens our mission but also brings us closer to achieving our shared goals of empowering women and driving sustainable economic growth in rural areas.",
        },
        {
          type: "paragraph",
          text: "The upcoming Bamboo sale displays the power of multi-partnership. With the active involvement of Gram Sabha, BGPPL, and Mission Shakti, the July Bamboo Sale in Malkangiri represents a collaborative effort towards a sustainable forest economy. Through extensive efforts in CFRR, technical intervention, corporate engagement, and financial inclusion, these partnerships empower forest communities, promote women’s empowerment, and eliminate intermediaries in the trade process. This multi-partnership approach brings us closer to achieving our shared goals of inclusive growth and prosperity in the forest economy.",
        },
      ],
      contentTags: ["Malkangiri", "BGPPL", "Odisha", "Bamboo Sale"],
    },
    "odi-6": {
      topTitle: "",
      topDesc: "",
      topImage: "odi-6-img.png",
      contentHeading:
        "Maha Gram Sabha Formation of 24 GS in Malkangiri, Odisha: Self-Governance for Sustainable Growth of Forests and Livelihoods",
      contentDescription: [
        {
          type: "paragraph",
          text: "On April 18, 2023, a significant event unfolded as the Jakhalkundi Gram Sabha orchestrated the highly anticipated Kalimela Maha Gram Sabha. This gathering proved to be a momentous occasion, with the active participation of elected members from 24 Gram Sabhas. Over 200 Gram Sabha members graced the event, showcasing their commitment to the governance of the communities and forest resources.",
        },
        {
          type: "paragraph",
          text: "The atmosphere was filled with enthusiasm as the Sarpanch, Samiti Sabhya, and Ward members, along with the esteemed Peda and Challans of Manyamkonda and Telrai GPs, joined the ranks of the Kalimela Maha Gram Sabha. Their presence symbolized the unity and collaborative spirit shared among the Gram Sabhas, fostering a sense of solidarity in their collective pursuit of governance.",
        },
        {
          type: "paragraph",
          text: "During this historic gathering, the formation of a working body emerged as a pivotal step toward effective governance and decision-making. 15 individuals were chosen to serve as members of this dedicated group. These committed individuals would play a vital role in ensuring the smooth functioning and seamless operations of the Kalimela Maha gram Sabha.",
        },
        {
          type: "paragraph",
          text: "With the establishment of the working body and the appointment of advisors, the Kalimela MGS took a giant leap forward. By bringing together representatives from all 24 revenue villages, this Sabha aimed to channel the aspirations and concerns of the local community into concrete actions and positive change. Embodying the spirit of participatory democracy, the Maha Gram Sabha stands as a beacon of hope, enabling better bargaining for seasonal forest resources and fostering collective participation in large-scale sales to uplift livelihoods.",
        },
      ],
      contentTags: ["Malkangiri", "BGPPL", "Sustainable Growth", "Odisha"],
    },
    "odi-7": {
      topTitle: "",
      topDesc: "",
      topImage: "odi-7-img.png",
      contentHeading:
        "Bonda Tribes in Malkangiri, Odisha Initiate CFRR Recognition Process: First Step towards legally securing their right on the Forest Landscapes",
      contentDescription: [
        {
          type: "paragraph",
          text: "BIPP-ISB’s flagship initiative, the “Initiative on Transformative Forest Futures (ITFF),” has a vision of building long-term sustainable forest enterprises by connecting forest-dwelling communities to industries for large-scale sales of regionally abundant seasonal forest products. Community Forest Resource Rights (CFRR) recognition has become a revolutionary tool for forest communities to become owners of the forests and legally participate in the trade of SFPs for their livelihoods. Recently, the operations of the ITFF team were initiated in Bonda Ghati, situated in the Malkangiri district of Odisha, where the Bonda tribe, one of India’s Particularly Vulnerable Tribal Groups (PVTG), resides.",
        },
        {
          type: "paragraph",
          text: "The ITFF team initiated the operation for filling claims for the recognition of CFRR in February 2023, with the lead from our Cluster Coordinator, Jayanti Buruda, to set up a pilot for forest economy with PVTG as an entire chain. The first operation was set up essentially to work on three dimensions:",
        },
        {
          type: "paragraph",
          text: "(a) Obtain data on the number of villages, households, seasonal forest products (SFP), households involved in SFP collection, sales of SFP, previous year collected volume and price, and a list of potential young PVTG girls for the formation of a Forest Producer Company (FPC) and Self-Help Groups (SHGs).",
        },
        {
          type: "paragraph",
          text: "(b) Conduct multiple meetings with the FRC (Forest Rights Committee) cell and Project Administrator of the Integrated Tribal Development Authority to understand the gaps and processes necessary to convert filled CFRR claims to titles.",
        },
        {
          type: "paragraph",
          text: "(c) Review the previous year’s Habitat rights claim filed by the Gram Sabhas (Bonda and Dadai), submit it to the Sub-district Level Committee (SDLC) as per FRA, 2006, and fill the gaps in the claim filing process in collaboration with the FRC.",
        },
        {
          type: "paragraph",
          text: "The team has drafted specific guidelines for habitat rights and sent them to the SC&ST department in Odisha to issue them separately or include them in the Standard Procedure. These guidelines will significantly help the department work with any PVTG in Odisha. The ITFF team has prepared the list for 32 villages out of 36, and once the CFRR filling process is complete, the management plan and collective sale under Rules 2(1)(d) and 4(1)(e) will start.",
        },
        {
          type: "paragraph",
          text: "The CFRR claim filing faces two major challenges: lack of awareness about rights among the Bonda tribes and unavailability of necessary documents, which slows down the process. To address these issues, the ITFF team has prepared an immediate action plan with inputs from the Gram Sabha and the government. The plan includes the following steps:",
        },
        {
          type: "bulletPoints",
          text: [
            "Determining the nature of mapping the forest for the CFRR recognition process through a meeting with the Gram Sabha and the government.",
            "Selecting five volunteers from each village to expedite the CFRR claim filing process.",
            "Organizing workshops to spread awareness about CFRR, explain the legal procedures, and highlight the benefits of CFRR titles to communities.",
          ],
        },
      ],
      contentTags: ["Malkangiri", "Forest Landscape", "Odisha"],
    },
    "odi-8": {
      topTitle: "",
      topDesc: "",
      topImage: "odi-8-img.png",
      contentHeading:
        "Gram Sabha Takes Action Against Bamboo Cutting by a Contractor in Malkangiri, Odisha: Post CFR filling Changes in the Management of the forests",
      contentDescription: [
        {
          type: "paragraph",
          text: "Forest communities have been the custodians of forests for centuries, indicating their capacity to manage forests. The Forest Rights Act (FRA), 2006 also legally acknowledges this fact by providing them the right to manage forest products and make sustainable forest management plans. One of the primary reasons for the low rate of filed CFRR (Community Forest Resource Right) titles among forest communities is the lack of awareness about the Act and its capability to transform their lives. Realising the impact of FRA and the emergence of potential conflicts on the ground level, we present a story from our pilot region Malkangiri, Odisha, regarding the change in operations after CFRR titles filing.",
        },
        {
          type: "paragraph",
          text: "Initially, the forest communities of Malkangiri needed to be made aware of their rights over forest products and forest lands, which will fetch better livelihood by engaging in the sale of Seasonal Forest Products (SFPs) directly at scale, especially bamboo in Malkangiri. Before CFR filing, most communities were working as wage labourers to facilitate the sale of bamboo for licensed regional contractors.",
        },
        {
          type: "paragraph",
          text: "In pursuit of enhancing the lives and livelihood of forest communities, the Initiative on Transformative Forest Futures (ITFF) team started working towards filing CFR titles in March 2022 by conducting a series of meetings with government officials, gram sabhas, and workshops for CFRR awareness, inventory mapping, etc. Within the next six months, communities managed to file the CFRR titles, which enabled them to form a forest management plan. Collectively, Forest Department, Integrated Tribal Development Authority (ITDA), and gram sabhas(GS) decided to create a bamboo management plan instead of a forest management plan. Hence, Gram sabhas were anticipated to participate in the bamboo sale in the coming season.",
        },
        {
          type: "paragraph",
          text: "In February 2023, a contractor started cutting bamboo in the claim filed CFR area of Sudhakonda gram sabha, not being aware of the change in the right of bamboo harvesting post-CFR title filing. This area was leased out by Forest Department (FD) under a coop system last year. Legally, it should have been cancelled by FD after the submission of the CFRR claim and a resolution passed by the 4(1)(e) committee a few months back. Due to unawareness of the change in management rules as per filed CFR titles, the Forest department had not cancelled the contractor’s pass to cut the bamboo. This brought a conflict between the contractor and gram sabhas.",
        },
        {
          type: "paragraph",
          text: "Around 7,000 bamboo were cut in the forest of Sudhakonda. Gram Sabha raised the complaint to concerned authorities, including DFO, District Collector, Forest Range Officer, and Tehsildar for cutting the Bamboo the contractor without consulting Gram Sabha against FRA, 2006 section 4(1)(e) and amendment act 2012 section (5). After a call by the Gram Sabha to the Forest Range officer, Kalimela, FD stopped the cutting of Bamboo, and the contractor left from Sudhakonda.",
        },
        {
          type: "paragraph",
          text: "Gram sabha leaders arranged a meeting with DFO and District Collector the next day. Both officials agreed that GS has the right to sell bamboo. Gram Sabha Secretary, President, and other community members loaded all the cut bamboo from the Sudhakonda forest in tractors and trucks and kept them in their own custody. As per statements of Gram Sabha, all bamboo will be sold as per the market price of Bamboo to the company/ traders or anyone from which Gram Sabha will be benefited through their saving bank Accounts.",
        },
        {
          type: "paragraph",
          text: "The ITFF team has taken the below-mentioned strategic approach to mitigate stress in communities and move towards strengthening the local governance of communities: (a) Operational legal training of elected leaders (Two male and two female – Proposed for Maha Gram Sabha formation (MGS) federation) on the provision of rights and responsibility of GS under FRA after the submission of Claim before 20th Feb 2023 and primary mobilisation for Maha Gram Sabha formation. (b) MGS federation formation on 22nd Feb to lead the governance aspect of forest management, protection, and regeneration of resources, (c) Start enumeration for other Seasonal Forest Products (SFPs) to reduce the dependency on Bamboo. Interns will start the Mahua inventory by collecting the details of the average volume fall from one tree, the number of Mahua trees in the region, etc, (d) Initiate listing of 200 villages for scaling the work, and the team will share the list with the GIS team for inventory mapping.",
        },
        {
          type: "paragraph",
          text: "Gram Sabha met and discussed changes in the forest management system as per FRA, 2006, with all the concerned stakeholders, i.e., the Forest department, ITDA, the District collector, the contractor, and communities. Now, everyone has the clarity that only GS has the right to harvest bamboo, and contractors can directly buy from GS at mutually decided prices.",
        },
        {
          type: "paragraph",
          text: "The incidence brought out the learning about the possibility of conflict of interest among stakeholders (contractors) prior to CFR titles and CFR-claimed communities in forest regions of the country. As FRA and its implementation is still a new process for most forest communities and concerned authorities, the role of all stakeholders, including government officials, NGOs, academia, and active participation of communities, is inevitable to clear the ambiguity that revolves around CFR titles. Community forest rights recognised under the FRA are important for securing the livelihoods of the forest communities and for strengthening local self-governance of forests and natural resources.",
        },
      ],
      contentTags: ["Malkangiri", "Forest Landscape", "Odisha"],
    },
  },
  "himachal-pradesh": {
    "hp-1": {
      topTitle: "",
      topDesc: "",
      topImage: "hp-1-img.png",
      contentHeading:
        "Formation of Forest Resource Management Committees in Shoar and Shoon, Pangi",
      contentDescription: [
        {
          type: "paragraph",
          text: "The Forest Right Act, 2006 emphasizes the importance of forming democratically elected committees, like the 4(1)(e) Committees, which play a crucial role in decision-making and implementation of sustainable practices.",
        },
        {
          type: "paragraph",
          text: "The recent developments in forest management in the Shoar and Shoon villages of Pangi Valley mark a significant stride towards sustainable environmental stewardship. From the 16th to the 22nd of October, notable progress was achieved through community-driven efforts. Central to our field visit was the formation of 4(1)(e) Community Forest Resource Rights Management Committees (CFRMC) where the active participation of women and youth groups played a pivotal role. The process was characterized by inclusivity and democratic principles, as evident in the unanimous selection of committee members by the Gram Sabha in both villages.",
        },
        {
          type: "paragraph",
          text: "The Community Forest Resource Rights Management Plans (CFRMP) developed are comprehensive, covering aspects of conservation, sustainable use, and equitable benefit sharing. They are tailored to the unique ecological, social, and economic contexts of the villages, reflecting a deep understanding of the local biodiversity and traditional knowledge systems. This approach not only supports the ecological integrity of the forests but also strengthens community rights and livelihoods, paving the way for a sustainable and harmonious coexistence with our natural environment.",
        },
        {
          type: "paragraph",
          text: "The heart of these efforts lay in the drafting and the modification of management plans. These drafts, prepared with considerable forethought, underwent extensive discussion and refinement during the meetings, ensuring comprehensiveness and representation of the community’s needs. The leadership of our team members Sant Ram and Hans Raj will be instrumental in this phase, demonstrating a commitment to not only solidify these plans in Shoar and Shoon but also to replicate this success in neighbouring villages.",
        },
        {
          type: "paragraph",
          text: "The involvement of government authorities added a crucial dimension to sustainable forest governance. The meeting with the Resident Commissioner (RC), Pangi was particularly noteworthy, as it underscored the government’s recognition and support for the advancements in the Pangi Valley. The discussion extended beyond mere accolades, focusing on tangible actions such as organizing meetings for the rectification of the Community Forest Rights (CFR) claims. The proactive stance of RC Pangi highlighted the ongoing efforts to expedite the formation of the Sub-Divisional Level Committee (SDLC). The anticipation of new committee member appointments and the subsequent SDLC meeting to address the remaining claims signified a collaborative approach between local communities and governmental bodies.",
        },
        {
          type: "paragraph",
          text: "The formation of forest management committees and plans in Shoar and Shoon are a testament to the power of community engagement and effective governance in environmental conservation. The synergy between the local communities’ efforts and government support paves the way for sustainable development in the Pangi Valley. With the formation of the SDLC and the continued involvement of various stakeholders, there is a hopeful outlook for the future of forest management in the region.",
        },
      ],
      contentTags: [
        "Forest Resources",
        "Shoar",
        "Shoon",
        "Pangi",
        "Himachal Pradesh",
      ],
    },
    "hp-2": {
      topTitle: "",
      topDesc: "",
      topImage: "hp-2-img.png",
      imageExp: true,
      contentHeading:
        "Inventory Maps: Key building blocks of Bioenergy Economy in Himachal Pradesh",
      contentDescription: [
        {
          type: "paragraph",
          text: "Himachal Pradesh is a high-potential landscape for building a bioenergy economy. This opportunity is due to the abundance of pine needles in areas like Bhattiyat tehsiltaluka in the Chamba district. Pine needles are highly flammable in nature and are often the cause of forest fires, which take a toll on the forest biodiversity and the livelihoods of forest communities. The operationalization of ITFF in Bhattiyat will lead to positive ecological and economic outcomes by creating opportunities to realise the potential of the bioenergy economy in the landscape. Pine needles processed as briquettes are an attractive alternative to coal and other fossil-fuel-based raw materials for hard-to-abate industries that forest communities can provide. One of the major challenges faced by industries in procuring seasonal forest products as raw materials is a lack of information on inventory and, therefore, the possibility of an inconsistent supply of seasonal forest products to the industry.",
        },
        {
          type: "paragraph",
          text: "The ITFF team is addressing this issue with the assistance of technology. We have begun by assessing high-resolution geospatial data and incorporating data points generated through participation with local communities. Forest inventory mapping for pine needles in Bhattiyat has been initiated to create data points and polygon maps. The village head, along with two villagers who are well-versed in the area, provides inputs on community forest boundaries to create their respective forest boundary maps. The ISB-BIPP team is training local volunteers in collecting ground truth data points and forest inventory that will help generate distribution maps and quantity estimations of pine needles in the landscape.",
        },
        {
          type: "paragraph",
          text: "The immediate action plan of the ITFF team is (a) to get an accurate inventory of pine needles through the extensive mapping exercise, (b) to fill claims for the recognition of Community Forest Resource rights of Other Traditional Forest Dwellers and Gaddi, the tribal community of Chamba, and (c) to form a women-led and community-owned enterprise that builds on the SHG architecture to facilitate and expedite the community-industry linkage for steady supply of pine needles to industries. This plan should lead to increased income for local communities through mechanical interventions for value addition at the local level.",
        },
      ],
      contentTags: ["Bioenergy", "Inventory Maps", "Himachal Pradesh"],
    },
    "hp-3": {
      topTitle: "",
      topDesc: "",
      topImage: "hp-3-img.png",
      contentHeading:
        "Himachal Pradesh Bioenergy Mission: Initial consultation with the Chief Minister",
      contentDescription: [
        {
          type: "paragraph",
          text: "The Himachal Pradesh Chief minister, Mr. Sukhvinder Singh Sukhu, in his meeting with the ISB team on March 3, 2023, said, “The Himachal Government is all set to partner with the Indian School of Business (ISB) to build a sustainable bioenergy sector based on the forest resources of the state.” “The government will make direct investments in the emerging bioenergy sector on the basis of policy inputs and research support from ISB.”",
        },
        {
          type: "paragraph",
          text: "In this meeting with the Himachal Pradesh CM, the ISB team discussed the Himanchal Pradesh Bioenergy Mission, including the potential of Briquettes made of dried Pine Needles, Ethanol, Compressed Biogas, and Biofertilizer production from Bamboo as lucrative alternatives to coal. The CM elaborated upon the government’s mandate for ethanol blending in petrol that must increase from 10 percent to 20 percent with an ambition to achieve the target of ‘Green Energy State’ by 2025. The team provided evidence of the state’s enormous potential to evolve as a pivotal hub of bioenergy production from forest-based resources. Himachal Pradesh can gain enormously from its forest lands by channelling investments towards sustainable resource management of existing conifer forests and restoration of degraded bamboo forest areas. The government is keen on doing so by coordinating activities across departments and agencies. These focused investments will unlock opportunities in the emerging bioenergy sector for the state’s welfare, socioeconomic development, and ecological development.",
        },
        {
          type: "paragraph",
          text: "The CM insisted on potentially setting up a pilot with local communities to make briquettes and sell them via a suitable business model designed by ISB. He was aware of the state of SHG institutions in the state and how the need for fair pricing is key to successfully rope them in the business model. It was established that forest-dwelling communities will be the fundamental stakeholders in this mission. Recognition of their legal community rights over forest resources is key to ensuring a sustainable supply of raw material for bioenergy production while also ensuring the protection of forests and their ecosystem services. Community ownership of forests and multisectoral job creation as steady income sources are incentives to build stewardship and diligence within communities. The communities will then be able to not only contribute to the state’s economy but also become leaders in sustainability.",
        },
        {
          type: "paragraph",
          text: "ISB will provide market linkages, a suitable business model, and apt technology. The state government’s support and participation will play a deciding role in making this mission a success. The government officials will meet the ISB team after April 15, 2023, for a detailed discussion on the mission and to prepare an action plan to follow.",
        },
      ],
      contentTags: ["Bioenergy", "Chief Minister", "Himachal Pradesh"],
    },
    "hp-4": {
      topTitle: "",
      topDesc: "",
      topImage: "hp-4-img.png",
      contentHeading:
        "Building women-led supply chain of Indian Hazelnut from the forests of Himachal Pradesh",
      contentDescription: [
        {
          type: "paragraph",
          text: "The forests of Himachal Pradesh are rich in seasonal products, with Hazelnuts and Walnuts being the prominent edible seasonal forest products that have significant market potential both within and outside India. The collection and sale of these wild foods can provide a reliable source of income for forest-dependent communities and have the potential to significantly boost their economy.",
        },
        {
          type: "paragraph",
          text: "ISB-BIPP has successfully connected the Pangi and Dhanala areas with the Dry Fruit Basket, a premium store in Hyderabad, to sell the seasonal forest products. The store has a daily average sale of 100 kilograms of the four varieties of Hazelnuts. The demand for these products was leveraged by using their uniqueness and origin as the selling proposition for the Hazelnuts and Walnuts from Himachal Pradesh. Retailers often prefer to test the market by trying small quantities, and the success of the Dry Fruit Basket outlet has led to a large follow-up order. The store has also expressed interest in conducting future sample sales of other seasonal forest products, such as Kala Jeera, herbs, and pine nuts, to gauge market prospects.",
        },
        {
          type: "heading",
          text: "The First Sale – Jarhun Nag Self-Help Group, Dry Fruit Basket, and AAK",
        },
        {
          type: "paragraph",
          text: "ISB-BIPP team attempted to connect the forest-dependent communities holding the Community Forest Rights titles with the markets for a collective sale of the Seasonal Forest Products through the Gram Sabhas in Himachal Pradesh. In our first attempt to make a collective sale of nuts to the Dry Fruit Basket store, we brought the Self-Help Groups (SHGs) and multiple villages to work together.",
        },
        {
          type: "paragraph",
          text: "In Dhanala, our team gathered Ratna (President), Leela (secretary), and members of the SHG, Santaram Shastri, on 3rd September at the secretary’s house. We designed a process plan to meet the consignment demanded by the buyer. The first shipment from Dhanala comprising of 22 kilograms of hazelnuts and 2 kilograms of walnut oil were respectively sent to the Dry Fruit Basket company in Hyderabad and AAK in Mohali. We discussed feedback on the challenges faced in the past two months while preparing for the sale and addressed the queries of the SHG members. The women of Dhanala, along with the president and the secretary, to constitute two additional SHGs and the existing SHGs, were convinced to include all the women.",
        },
        {
          type: "paragraph",
          text: "A second meeting was also conducted at the secretary’s house. The Women and Child Development (WDC) Cluster Coordinator of Saichu accompanied Ms. Yamini and Ms. Apurva, along with the president, secretary, members, and Santaram.",
        },
        {
          type: "paragraph",
          text: "We elaborated upon the first mile journey and innovative aspects of the process followed to successfully conduct this supply. Ratna, Leela, Santaram, and three other SHG members were working on breaking the hazelnuts, picking and cleaning the kernels, and packing the nuts. We used pine needles and cones for the packaging instead of plastic material, paper, bubble wrap, and cardboard sheets. Santaram suggested the cones be polished to shine in the following order to make them aesthetically pleasing.",
        },
        {
          type: "paragraph",
          text: "In contrast, Ratna suggested they could collect the cones and needles as they fall and dry them separately to save time. After three hours, the hazelnuts were packed, and Ratna stitched a cloth cover for the cardboard boxes, which added authenticity and the feel of a nativity. The hazelnuts were nicely packed in two-sized plastic containers, weighed, labelled, and replaced with the new name ‘Jarhun Nag SHG Dhanala Pangi – Himalayan Hazelnuts’ and couriered to the Dry Fruit Basket store.",
        },
        {
          type: "paragraph",
          text: "The new harvest of hazelnuts was sent to the Dry Fruit Basket for market evaluation, and the lighter colour and improved taste were noted. During a discussion about challenges faced by ASHA workers in participating in SHG activities, it was brought to light that missing a day of work would result in loss of income. The workers were assured that they don’t have to attend if they cannot, but it’s important to inform the SHG president, other members, and the ISB-BIPP team. Leela, a SHG member, raised the issue of needing the presence of the president and secretary for bank transactions, resulting in additional expenses such as a taxi ride back to Dhanala after forgetting a stamp. The Cluster Coordinator suggested that such expenses should be shared among all members as accidents do happen. A member asked about the benefits of being part of an SHG, and Ratna explained the advantages, stressing that all women would eventually join an SHG.",
        },
        {
          type: "heading",
          text: "Sale at Dry Fruit Basket",
        },
        {
          type: "paragraph",
          text: "We created advertising materials, such as leaflets, tabletop displays, shelf stickers, slides, and videos to promote the sale of freshly harvested hazelnuts at the Dry Fruit Basket store. After the successful sale of the first batch of hazelnuts and walnuts, the store owner was pleased with the products and our advertising efforts. He expressed interest in selling more Seasonal Forest Products and ordered larger quantities of shelled and unshelled hazelnuts and walnuts. DFB has also agreed to sell broken kernels due to the customers’ interest in native products.  Additionally, he agreed to sell broken kernels as there was customer interest in native products. The order for shelled hazelnuts was increased to 40 kgs and 120 kgs, and he ordered 40 kgs of shelled walnuts at a rate of Rs. 800/kg and shelled pine nuts to be delivered by the end of September.",
        },
        {
          type: "paragraph",
          text: "To meet the increased demand, two villages combined their harvest. The high interest in these products by consumers demonstrated the market potential for Seasonal Forest Products and the success of our efforts towards linking markets/industries with forest communities. On October 10th, 2022, the hazelnut consignment arrived at the Dry Fruit Basket store, and the owner expressed satisfaction with the nuts’ quality and packaging (using pinecones). He also expressed interest in procuring pinenuts in the future.",
        },
        {
          type: "paragraph",
          text: "We are proud of the results that our collaborative efforts have produced. Our satisfaction stems from the realization that our hard work has culminated in a successful and impactful enterprise. The formalization of the Indian Hazelnut supply chain was a success, with the consignment reaching the store to the owner’s delight and praise for the quality and eco-friendly packaging. Our team continues to strive for further success by seeking out additional stores to sell Seasonal Forest Products.",
        },
      ],
      contentTags: ["Bioenergy", "Inventory Maps", "Himachal Pradesh"],
    },
  },
  goa: {
    "goa-1": {
      topTitle: "",
      topDesc: "",
      topImage: "goa-1-img.png",
      contentHeading:
        "Bamboo Economy Rising: Building Bioenergy Hubs and Women-led Enterprises through MoU signed with the Government of Goa",
      contentDescription: [
        {
          type: "paragraph",
          text: "Goa has taken a significant step forward in its sustainable development journey. The state has become the fourth partner of the Initiative on Transformative Forest Futures, joining Odisha, Himachal Pradesh, and Jharkhand. On 8th August 2023, a Knowledge Partnership Memorandum of Understanding (MoU) was inked between the Bharti Institute of Public Policy, ISB, and the Government of Goa.",
        },
        {
          type: "paragraph",
          text: "One of the pivotal objectives outlined within the MoU is to tap the full potential of the State's forests as bioenergy hubs, promoting energy security, rural development, and climate change mitigation.",
        },
        {
          type: "paragraph",
          text: "With a strategic focus on harnessing the capabilities of bioenergy, the ITFF team will actively investigate the potential of establishing a Bamboo economy in Goa. This endeavour will involve the establishment of women-led community enterprises, incorporating digital and mechanical interventions to enhance operational efficiency and scalability, and establishing market linkage with large-scale industrial buyers. The ITFF team will facilitate building the end-to-end business value chains, where communities with secure tenure constitute the anchor and prime stakeholder of the model. This stands as a cornerstone among the five key objectives of the MoU.",
        },
        {
          type: "paragraph",
          text: "The MoU was signed in the presence of the Hon’ble Chief Minister of Goa, Dr. Pramod Sawant, by Shri Vijay B Saxena, Director, Directorate of Planning, Statistics & Evaluation, Government of Goa, and Prof. Ashwini Chhatre, Executive Director, Bharti Institute of Public Policy, ISB in Panaji, Goa. The MoU signing was also attended by Shri Ajit Roy, Secretary to the Chief Minister, with power, New & Renewable Energy, Mines & Geology portfolios, Dr. Aarushi Jain, Policy Director, Bharti Institute of Public Policy, ISB, and Apurva Duddu, Research Associate, Bharti Institute of Public Policy, ISB. From a broader perspective, this collaboration is poised to be a beacon for sustainable energy solutions. It's a model that fits perfectly with India's emission reduction targets and sustainable development goals.",
        },
      ],
      contentTags: ["ISB - TCS", "Technology", "Collaboration"],
    },
  },
};
