import Header from "../../components/Header2";
import { detailedWorkData } from "../../utils/our-work-data/detailedWorkData";
import { Link } from "react-router-dom";
import { useScrollToTop } from "../../utils/scrollToTop";

export const StateWorkDetail = ({ stateName, workId }) => {
  useScrollToTop()
  const detailedData = detailedWorkData[stateName][workId];
  return (
    <>
      <div className="state-work-detail-top">
        {detailedData.imageExp ? (
          <img
            src={`work-image/${detailedData.topImage}`}
            className="state-work-detail-top-image-exp"
            alt="work-img"
          />
        ) : (
          <>
            <img
              src={`work-image/${detailedData.topImage}`}
              className="state-work-detail-top-image"
              alt="work-img"
            />
            <img
              src="bg-layer.png"
              className="state-work-detail-top-image"
              alt="over-lay"
            />
          </>
        )}
        <Header activeLink={"1"} modified={false} />
        <div className="state-work-detail-top-detail">
          <h2>{detailedData.topTitle}</h2>
          {detailedData?.topDesc && <p>{detailedData?.topDesc}</p>}
        </div>
      </div>
      <div className="state-work-detail-bottom">
        <div className="state-work-detail-bottom-container">
          <div className="detail-bottom-container-left">
            <h4>{detailedData.contentHeading}</h4>
            {detailedData.contentDescription.map((block, index) => {
              switch (block.type) {
                case "heading":
                  return (
                    <h5 key={index} className="heading">
                      {block.text}
                    </h5>
                  );

                case "aboutParagraph":
                  return (
                    <div className="about-paragraph-container">
                      <p key={index} className="about-paragraph">
                        {block.text}
                      </p>
                    </div>
                  );

                case "paragraph-heading":
                  return (
                    <h6 key={index} className="paragraph-heading">
                      {block.text}
                    </h6>
                  );

                case "paragraph":
                  return (
                    <p key={index} className="paragraph">
                      {block.text}
                    </p>
                  );

                case "highlightedParagraph":
                  return (
                    <p key={index} className="highlighted-paragraph">
                      {block.text}
                    </p>
                  );

                case "tagsParagraphSection":
                  return (
                    <div className="tags-paragraph-section">
                      <p key={index} className="tags-paragraph">
                        <strong>{block.text[0]}</strong>&nbsp;
                        {block.text[1]}
                      </p>
                      {block.tags && (
                        <div className="tags-para-tag-container">
                          {block.tags.map((tagName, index) => (
                            <button className="tags-para-btn-tag" key={index}>
                              {tagName}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  );

                case "bulletPoints":
                  return (
                    <ul key={index} className="bullet-points">
                      {block.text.map((item, idx) => (
                        <li key={idx}>{item}</li>
                      ))}
                    </ul>
                  );

                default:
                  return null;
              }
            })}
            <Link to="/our-work">
              <button className="back-work-btn">Back to all works</button>
            </Link>
          </div>
          <div className="detail-bottom-container-right">
            <div className="work-detail-tag-container">
              {detailedData.contentTags.map((tagName, index) => (
                <button className="state-work-btn-tag" key={index}>
                  {tagName}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
