export const publicationDetailData = {
  "lib-1": {
    topImage: "",
    imageExp: true,
    topHeading:
      "BIPP-ISB Formalises the Relationship with Srishti Manipal Institute of Art, Design and Technology",
    headingDesc:
      "The three-year MoU will focus on fostering academic and knowledge collaboration between SMI and BIPP-ISB for the effective implementation of a large-scale landscape-level Initiative on Transformative Forest Futures(ITFF).",
    aboutDescription: {
      text: [
        "On 22 September 2023, Bharti Institute of Public Policy, ISB and Srishti Manipal Institute of Art, Design and Technology signed a Memorandum of Understanding (MoU) to build together a scalable and sustainable forest economy and livelihood anchored around Community Forest Resource Rights (CFR) and Women-led Enterprises.",
        "The major objective of the partnership is to facilitate the exchange of information on research and educational programs, teaching materials, and literature relevant to their educational and research activities. It also involves jointly organizing short-term education programs, seminars, conferences, and workshops on mutual interests, with invitations extended to each other’s faculty for participation.",
      ],
      aboutTags: [],
    },
    contentDescription: [
      {
        type: "paragraph-heading",
        text: "Additionally, the MoU will facilitate-",
      },
      {
        type: "bulletPoints",
        text: [
          "Joint Summer Internship for the students of B. Des., M. Des, BFA, and MFA programs at SMI.",
          "Participation in multi-institutional collaboration to design and develop a multi-media case study platform. The proposed platform acts as a new pedagogical tool for imparting education in humanities, social sciences, development studies, management education, and related fields.",
          "Final semester project dissertation joint fellowship for M. Des. and MFA students.",
          "Conceptualization and co-production of documentaries focused on forest governance, economy, livelihood, agriculture-nutrition convergence, etc.",
        ],
      },
      {
        type: "paragraph",
        text: "The MoU highlights the vital role of youth participation in research, as it paves the way for innovative approaches to a sustainable forest economy, reinforcing the importance of fresh perspectives and dynamic solutions in shaping a brighter future for our ecosystems and communities.",
      },
    ],
  },
  "lib-2": {
    topImage: "lib-2-img.png",
    topHeading:
      "Collaboration for Himachal Pradesh Scientific Forest Inventory",
    headingDesc: "",
    aboutDescription: {
      text: [
        "In the serene and verdant landscapes of Himachal Pradesh, a groundbreaking scientific initiative is taking shape, spearheaded by the esteemed Bharti Institute of Public Policy at the Indian School of Business in collaboration with the Himachal Pradesh Forest Department (HPFD). The project, titled “Himachal Pradesh Seasonal Forest Products Inventory,” embarks on a mission to catalog and understand the diverse array of seasonal forest products in this ecologically rich region using nCount technology.",
      ],
      aboutTags: [
        "Objective and Vision",
        "Innovative Methodology",
        "Phased Approach",
        "Anticipated Outcomes",
      ],
    },
    contentDescription: [
      {
        type: "dark",
        textLeft: "Objective and Vision",
        textRight: [
          "At the heart of this collaboration lies a vision of sustainability and prosperity. The primary objective is to conduct a comprehensive inventory of selected forest products, assessing their distribution and abundance with precision. This endeavor is not just about mapping resources; it’s a strategic step toward realizing the potential revenue from the sustainable management and regulation of these natural treasures. In doing so, the project aims to contribute significantly to wealth generation, job creation, and the fortification of rural livelihoods in Himachal Pradesh.",
        ],
      },
      {
        type: "light",
        textLeft: "Innovative Methodology",
        textRight: [
          "The methodology adopted for this inventory is a blend of traditional ecological knowledge and cutting-edge technology. It involves an extensive collection of high-quality data, harnessing the capabilities of sensors mounted on satellites and drones. This advanced approach is complemented by the practice of ground-truthing, ensuring the accuracy and reliability of the data collected. An integral part of the methodology is the compilation of both scientific and traditional insights into sustainable extraction practices, marrying age-old wisdom with modern science.",
        ],
      },
      {
        type: "dark",
        textLeft: "Phased Approach",
        textRight: [
          "The project is meticulously structured into two distinct phases, each with its unique focus and set of activities. Phase 1, spanning February to December 2024, is dedicated to identifying and modeling the distribution of seasonal forest products. This phase is bifurcated into two tracks: the first deals with species that are gregarious and proximate to human habitation, and the second focuses on more elusive high-altitude species, necessitating extensive fieldwork for data collection. Phase 2, scheduled from January to March 2025, is reserved for synthesizing the collected data and knowledge into a comprehensive report. This report will not only highlight the economic potential of these forest products but also lay out a roadmap for their sustainable management.",
        ],
      },
      {
        type: "light",
        textLeft: "Anticipated Outcomes",
        textRight: [
          "The outputs of this project will include co-produced reports on the inventory of selected seasonal forest products in Himachal Pradesh, complete with high-resolution maps for each species. These maps will be released continuously throughout the year. Additionally, efforts will be made to publicize the findings among relevant stakeholders, and BIPP researchers will prepare papers for publication in high-ranking journals to highlight the collaborative knowledge produced.",
          "Phase 2, scheduled for January to March 2025, will build on the comprehensive knowledge gained in Phase 1. A detailed report will outline the potential of seasonal forest products in generating wealth, creating jobs, and securing rural livelihoods through scientific resource management. This report will identify high-potential clusters for priority action, suggest institutional mechanisms for tapping business opportunities through community-based enterprises, and recommend ways to integrate monitoring mechanisms into formal forest management systems. A symposium will be organized to gather feedback from experts, and the final report will be refined based on their input.",
          "This collaborative initiative between HPFD and BIPP represents a significant step forward in leveraging technology and traditional knowledge for sustainable forest management in Himachal Pradesh.",
        ],
      },
    ],
  },
  "lib-3": {
    topImage: "lib-3-img.png",
    topHeading:
      "BiofuelCircle and BIPP-ISB Collaborate to Strengthen Forest-Based Bioenergy Value Chains",
    headingDesc: "",
    aboutDescription: {
      text: [
        "Bharti Institute of Public Policy, ISB, and BiofuelCircle Private Limited (BFC) have signed a three-year Memorandum of Understanding (MoU) on June 9, 2023, to foster collaboration in strengthening the forest economy by creating sustainable and efficient bioenergy value chains. This partnership aims to utilize forests as sustainable sources of raw materials for the bioenergy sector and empower forest-based community-owned enterprises with secure tenure.",
        "BiofuelCircle Private Limited is a pioneering company in the bioenergy sector, committed to promoting sustainable and environmentally friendly sources of energy. BFC focuses on creating value chains that empower communities and protect the environment.",
      ],
      aboutTags: [
        "Objective and Collaboration",
        "Scope of work",
        "Roles and Responsibilities",
      ],
    },
    contentDescription: [
      {
        type: "dark",
        textLeft: "Objective and Collaboration",
        textRight: [
          "The primary objective of this collaboration is to enable the creation of large-scale, efficient, and sustainable value chains in the bioenergy sector, with forests serving as a secure and sustainable source of raw materials. Forest-based community-owned enterprises, supported by secure tenure, will form the cornerstone of these bioenergy value chains.",
        ],
      },
      {
        type: "light",
        textLeft: "Scope of Work",
        textRight: [
          "BIPP-ISB and BFC will collaborate on the following key areas: (a) developing end-to-end bioenergy value chains with a focus on forest-based communities with secure tenure as the primary suppliers of raw materials, (b) establishing transparent, sustainable, and scalable forest-based value chains for bioenergy.",
          "The initial focus of the collaboration will be on building bioenergy value chains using pine needles sourced from Himachal Pradesh, specifically the Hamirpur district and Bhattiyat cluster of Chamba district, in the first year. Both parties will explore opportunities to expand the scope and geography of their collaboration in the future.",
        ],
      },
      {
        type: "dark",
        textLeft: "Roles and Responsibilities",
        textRight: [
          "The roles and responsibilities of both parties are clearly defined to ensure the success of this partnership. The initiative on the Forest Economy team will work closely with governments and communities to operationalize secure tenure, identify raw material sources, and mobilize forest-based communities. BFC will focus on mapping value chains, facilitating purchase transactions, and creating a competitive marketplace for bioenergy sources from community-owned enterprises.",
          "BiofuelCircle, utilizing its digital platform, will facilitate transparently mapping the value chains, streamlining the logistics of sourcing, and enabling large-scale transactions between community-owned enterprises and buyers. Its primary function is to connect local communities with various industries that currently purchase their biofuels through the BiofuelCircle platform.",
          "This MoU signifies a significant step towards sustainable forest-based bioenergy value chains and highlights the commitment of both organizations to make a meaningful impact on the Indian economy and environment.",
        ],
      },
    ],
  },
  "lib-4": {
    topImage: "",
    imageExp: true,
    topHeading:
      "Collaboration for “Centre of Excellence on Aerial and Water Robotics for Development and Sustainability (CoE-AWRDS)”",
    headingDesc: "",
    aboutDescription: {
      text: [
        "The establishment of the Centre of Excellence on Aerial and Water Robotics for Development and Sustainability (CoE-AWRDS) marks a pioneering initiative aimed at driving innovation in UAV and AUV technologies. This Centre is dedicated to addressing critical social development and sustainability challenges through cutting-edge research, strategic partnerships, and the deployment of practical robotic solutions.",
        "The CoE-AWRDS is committed to fostering advancements in aerial and water robotics to support the Sustainable Development Goals (SDGs). Through collaborations with academia, industry, government, and civil society, the Centre seeks to translate research outcomes into real-world applications that drive social and environmental impact. The overarching goal is to contribute to poverty reduction, environmental conservation, and improved access to essential services while nurturing the next generation of robotics experts, particularly from tribal and rural communities.",
      ],
      aboutTags: [],
    },
    contentDescription: [
      {
        type: "paragraph-heading",
        text: "Key Objectives",
      },
      {
        type: "bulletPoints",
        text: [
          "Enhance Environmental Monitoring and Conservation (SDGs 13, 14, 15): Develop and deploy aerial and underwater drones equipped with advanced sensors to monitor deforestation, illegal fishing, pollution levels, wildlife populations, and other critical environmental indicators. This will enable data-driven conservation efforts and facilitate the implementation of effective environmental policies.",
          "Improve Disaster Resilience and Response (SDGs 11, 13): Create specialized drones for rapid assessment of disaster-stricken areas, providing real-time imagery and data for efficient resource allocation and rescue operations. Additionally, develop autonomous underwater vehicles (AUVs) capable of inspecting critical infrastructure for damage and assisting in search and rescue missions in flooded areas.",
          "Promote Sustainable Agriculture and Food Security (SDG 2): Utilize drone technology for precision agriculture, enabling farmers to monitor crop health, optimize irrigation, and detect pests and diseases early on. Additionally, develop underwater drones to monitor aquaculture farms and assess marine resources, contributing to sustainable fisheries management and food security.",
          "Enhance Access to Healthcare and Essential Services (SDG 3): Design and deploy aerial drones to deliver medical supplies, vaccines, and diagnostic tools to remote and underserved areas, overcoming logistical challenges and ensuring equitable access to healthcare. Additionally. use underwater drones to monitor water quality and sanitation systems, contributing to improved public health outcomes.",
          "Empower Communities and Foster Sustainable Development (SDGs 1, 8, 10): Partner with local communities to provide training and access to drone technology, enabling them to monitor their natural resources, participate in decision-making processes, and generate income through drone-based services. Additionally, promote the development of sustainable and inclusive drone-related industries to create green jobs and economic opportunities.",
        ],
      },
      {
        type: "paragraph-heading",
        text: "Partners",
      },
      {
        type: "paragraph",
        text: "The Centre of Excellence (CoE) on Aerial and Water Robotics for Social Development and Sustainability will be structured as a collaborative partnership between three key institutions, each contributing their unique expertise to the endeavor, combining technical expertise, capacity building, and policy-driven implementation.",
      },
      {
        type: "paragraph",
        text: "The Indraprastha Institute of Information Technology, Delhi (IIIT Delhi) will spearhead the research and development (R&D) efforts in both drone and underwater robotics technologies. Their focus will be on developing cutting-edge platforms, sensors, algorithms, and control systems that push the boundaries of robotic capabilities in these domains.",
      },
      {
        type: "paragraph",
        text: "The Delhi Research Implementation and Innovation, Delhi (DRIIV), Flagship program of the Office of the PSA to Government of India will take charge of R&D dissemination partnerships and capacity building. They will work closely with stakeholders to identify real-world problems that can be addressed through aerial and water robotics, and design tailored solutions to meet those needs. Additionally, DRIIV Delhi will conduct comprehensive training programs and workshops to build capacity among local communities, government agencies, and other relevant stakeholders, ensuring that these technologies are accessible and effectively utilized for sustainable development.",
      },
      {
        type: "paragraph",
        text: "Bharti Institute of Public Policy at the Indian School of Business, Hyderabad (BIPP-ISB) will play a crucial role in ensuring the alignment of technology and innovation with the Sustainable Development Goals (SDGs). They will conduct impact assessments, develop policy recommendations, and facilitate partnerships with relevant organizations to maximize the positive social and environmental impact of the CoE-AWRDS's initiatives. By fostering partnerships with corporates, policymakers, CSOs, local communities and other key stakeholders, BIPP-ISB will help create an enabling environment for the widespread adoption and integration of these technologies into sustainable development initiatives.",
      },
      {
        type: "paragraph-heading",
        text: "Structure: A Distributed CoE for the Network of Excellence",
      },
      {
        type: "paragraph",
        text: "Bharti Institute of Public Policy at the Indian School of Business, Hyderabad (BIPP-ISB) will play a crucial role in ensuring the alignment of technology and innovation with the Sustainable Development Goals (SDGs). They will conduct impact assessments, develop policy recommendations, and facilitate partnerships with relevant organizations to maximize the positive social and environmental impact of the CoE-AWRDS's initiatives. By fostering partnerships with corporates, policymakers, CSOs, local communities and other key stakeholders, BIPP-ISB will help create an enabling environment for the widespread adoption and integration of these technologies into sustainable development initiatives.",
      },
      {
        type: "paragraph",
        text: "The Centre of Excellence (CoE) on Aerial and Water Robotics will adopt a hub-and-spoke model, where IIIT Delhi, DRIIV Delhi, and BIPP-ISB will serve as the central hubs, each specializing in their respective areas of expertise. The spokes will be formed by various institutions and organizations across India, including universities, research centers, NGOs, and government agencies. This model enables a decentralized and distributed approach, allowing the CoE to tap into diverse talent pools, local knowledge, and regional resources. The hubs will provide the core research and development capabilities, training programs, and policy guidance, while the spokes will act as solution co-development and implementation partners, adapting and deploying the developed technologies in their specific contexts.",
      },
      {
        type: "paragraph-heading",
        text: "Stakeholder Engagement and Services",
      },
      {
        type: "paragraph",
        text: "The CoE-AWRDS is committed to engaging with a diverse range of stakeholders, each benefiting from tailored services designed to meet their specific needs:",
      },
      {
        type: "bulletPoints",
        text: [
          "Community Enterprises (FPO, SHG, Van Suraksha Samiti, etc.): Providing training and capacity building on the use of drones for natural resource governance, business process optimization, and value chain monitoring.",
          "Startups and Incubation: Offering incubation support for innovative startups focused on developing UAV/AUV solutions aligned with SDGs, including problem identification, business use-case development, and market access.", "Certifications and Standards: Developing a multi-stakeholder consultative framework for certifications, data standards, and SDG Monitoring, Reporting, and Verification (MRV) standards.", "Industries and Corporations: Collaborating with industry partners to develop tailored UAV/AUV solutions that align with corporate sustainability goals and enhance operational efficiency.", "Higher Education and R&D Institutions: Fostering collaborative research partnerships to advance UAV/AUV technologies for SDG-related research.", "Civil Society Organizations (CSOs): Empowering CSOs through training programs focused on utilizing drone technology for socio-ecological monitoring in collaboration with local communities.", "Educational Institutions (Schools and Colleges): Integrating drone technology into STEM education, promoting awareness and understanding of SDGs among students."
        ],
      },
      {
        type: "paragraph",
        text: "By addressing the needs of diverse stakeholders and fostering a culture of innovation, the CoE-AWRDS is poised to play a pivotal role in the global effort to achieve the Sustainable Development Goals. We invite you to learn more about our initiatives and explore opportunities for collaboration as we work together to build a more sustainable future.",
      },
    ],
  },
};
