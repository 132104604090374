import React from "react";
import Header from "../../components/Header2";
import Events from "./Events";
import RoundTables from "./RoundTables";
import Workshops from "./Workshops";
import { useScrollToTop } from "../../utils/scrollToTop";

function Home() {
  useScrollToTop();
  return (
    <>
      <div className="home-landing-page">
        <Header background={false} activeLink={"4"} modified={false} />
        <img
          src="/event-image/event-main-top.png"
          className="background-video"
          alt="eventImage"
        />
        <div className="event-top-container"></div>
      </div>
      <Events />
      <RoundTables />
      <Workshops />
      <div className="event-main-bottom">
        <img
          src="/event-image/event-btm-brown-vector.png"
          alt="event-btm-brown-vector-img"
        />
        <div />
      </div>
    </>
  );
}

export default Home;
