import React, { useLayoutEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import logo from "../assests/logo/logo.png";
import instaIcon from "../assests/icons/instagram.png";
import linkendinIcon from "../assests/icons/linkendin.png";
import xIcon from "../assests/icons/x.png";
import ytIcon from "../assests/icons/Youtube.png";
import { Link } from "react-router-dom";

function Footer() {
  const [size, setSize] = useState([]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  console.log("Size", size);
  return (
    <div className="footer-main-section">
      <Row className="footer-container">
        {size.length > 0 && size[0] >= 768 && (
          <Col
            md="7"
            xs="12"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              borderRight: "2px dotted #fff",
            }}
          >
            <Row className="contact-icons">
              <img alt="img-foot" className="footer-logo" src={logo} />
              <div className="footer-contact-us">
                <p className="contact-us-text">Contact Us</p>
                <div>
                  <a
                    href="https://www.instagram.com/bipp.isb/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="img-foot"
                      className="contact-us-icon"
                      src={instaIcon}
                    />
                  </a>
                  <a
                    href="https://x.com/BIPP_ISB?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="img-foot"
                      className="contact-us-icon"
                      src={xIcon}
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCBw3RD8fhi2btBuGofii7KA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="img-foot"
                      className="contact-us-icon"
                      style={{ width: 23 }}
                      src={ytIcon}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/forestgovernance/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="img-foot"
                      className="contact-us-icon"
                      src={linkendinIcon}
                    />
                  </a>
                </div>
              </div>
            </Row>
          </Col>
        )}
        <Col md="5" xs="12">
          <Row>
            <Col
              md="4"
              xs="6"
              className="footer-link-section border-link-section"
            >
              <p className="footer-link-head">Research Programs</p>
              <p className="footer-link">Technology </p>
              <p className="footer-link">Community </p>
              <p className="footer-link">Governance</p>
              <p className="footer-link">Economy</p>
            </Col>
            <Col md="4" xs="6" className="footer-link-section">
              <p className="footer-link-head">About Us</p>
              <p className="footer-link">Career</p>
              <p className="footer-link">Collaborations</p>
              <p className="footer-link">Internships</p>
              <p className="footer-link">Fellowships</p>
            </Col>
            <Col md="4" xs="6" className="footer-link-section">
              <p className="footer-link-head">Community</p>
              <Link to="/about-us#our-vision" target="_blank">
                <p className="footer-link">Our Vision</p>
              </Link>
              <Link to="/about-us/team" target="_blank">
                <p className="footer-link"> Our Team</p>
              </Link>
              <Link to="/events" target="_blank">
                <p className="footer-link">Events</p>
              </Link>
              <Link to="#workshop" target="_blank">
                <p className="footer-link">Workshops</p>
              </Link>
            </Col>
            {size.length > 0 && size[0] < 768 && (
              <>
                <Col md="4" xs="6" className="footer-link-section">
                  <div className="footer-contact-us">
                    <p className="footer-link-head">Contact Us</p>
                    <div>
                      <a
                        href="https://www.instagram.com/bipp.isb/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="img-foot"
                          className="contact-us-icon"
                          src={instaIcon}
                        />
                      </a>
                      <a
                        href="https://x.com/BIPP_ISB?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="img-foot"
                          className="contact-us-icon"
                          src={xIcon}
                        />
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCBw3RD8fhi2btBuGofii7KA"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="img-foot"
                          className="contact-us-icon"
                          style={{ width: 23 }}
                          src={ytIcon}
                        />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/forestgovernance/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="img-foot"
                          className="contact-us-icon"
                          src={linkendinIcon}
                        />
                      </a>
                    </div>
                  </div>
                </Col>
                <div>
                  <hr className="home-workshop-dotted-line" />
                  <center>
                    <img alt="img-foot" className="footer-logo" src={logo} />
                  </center>
                </div>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
