import React from "react";
import Home from "./Home";
import { Bunch } from "./Bunch";
import { OurVision } from "./OurVision";
import { Impact } from "./Impact";
import { Engage } from "./Engage";
import { useScrollToTop } from "../../utils/scrollToTop";

function Main() {
  useScrollToTop();
  return (
    <>
      <Home />
      <Bunch />
      <OurVision />
      <Impact />
      <Engage />
    </>
  );
}

export default Main;
