import leftArrow from "../../assests/images/leftArrow.png";
import rightArrow from "../../assests/images/rightArrow.png";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={leftArrow} alt="prev-arrow" style={{ width: 20 }} />
    </div>
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={rightArrow} alt="next-arrow" style={{ width: 20 }} />
    </div>
  );
}

export default function ProjectCarousel() {
  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <Slider {...settings}>
      <div className="home-pubs-division">
        <Col md="3" className="home-project-column img-col">
          <Link to="/our-work?state=jharkhand&workId=jhar-1">
            <img
              className="home-project-image"
              src={
                "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/jharfra.png"
              }
              alt="alt-img"
            />
          </Link>
        </Col>
        <Col md="3" className="home-project-column desc-col">
          <Link
            style={{ textDecoration: "none" }}
            to="/our-work?state=jharkhand&workId=jhar-1"
          >
            <div className="home-project-desc">
              <p className="home-project-desc-title">Launch of JharFRA</p>
              <p className="home-project-desc-description">
                The JharFRA application has been adopted by GOJ’s Abua Bir Abua
                Dishom Campaign, to file CFR claims for over 1 lakh individuals
                through state-civil society collaboration. {" "}
              </p>
            </div>
          </Link>
        </Col>
      </div>
      <div className="home-pubs-division">
        <Col md="3" className="home-project-column img-col">
          <Link to="/our-work?state=odisha&workId=odi-2">
            <img
              className="home-project-image"
              src={
                "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/empowering+women.png"
              }
              alt="alt-img"
            />
          </Link>
        </Col>
        <Col md="3" className="home-project-column desc-col">
          <Link
            style={{ textDecoration: "none" }}
            to="/our-work?state=odisha&workId=odi-2"
          >
            <div className="home-project-desc">
              <p className="home-project-desc-title" style={{ padding: 1 }}>
                Empowering Women-Led Enterprises 
              </p>
              <p className="home-project-desc-description">
                Women-led FPCs and primary collectors from Gumla and Ranchi,
                Jharkhand, formed their first direct industry partnership by
                selling Sal seeds to Swedish company AAK.
              </p>
            </div>
          </Link>
        </Col>
      </div>
      <div className="home-pubs-division">
        <Col md="3" className="home-project-column img-col">
          <Link to="/our-work?state=odisha&workId=odi-1">
            <img
              className="home-project-image"
              src={
                "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/ncount-main-page.png"
              }
              alt="alt-img"
            />
          </Link>
        </Col>
        <Col md="3" className="home-project-column desc-col">
          <Link
            style={{ textDecoration: "none" }}
            to="/our-work?state=odisha&workId=odi-1"
          >
            <div className="home-project-desc">
              <p className="home-project-desc-title">
                Harnessing nCount for Sustainability
              </p>
              <p className="home-project-desc-description">
                The Himachal Pradesh Forest Department's use of nCount will
                inventory herbs and medicinal plants, enhancing scientific
                knowledge and guiding policies for ecology, biodiversity, and
                community needs.
              </p>
            </div>
          </Link>
        </Col>
      </div>
      <div className="home-pubs-division">
        <Col md="3" className="home-project-column img-col">
          <Link to="/our-work?state=jharkhand&workId=jhar-2">
            <img
              className="home-project-image"
              src={
                "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/Gao-mou.png"
              }
              alt="alt-img"
            />
          </Link>
        </Col>
        <Col md="3" className="home-project-column desc-col">
          <Link
            style={{ textDecoration: "none" }}
            to="/our-work?state=jharkhand&workId=jhar-2"
          >
            <div className="home-project-desc">
              <p className="home-project-desc-title">
                ISB-Government of Goa MoU
              </p>
              <p className="home-project-desc-description">
                IOFE and the Goa government have signed an MoU to develop
                evidence-based policies, empowering forest communities and
                women-led enterprises through technology, sustainability, and
                market linkages.{" "}
              </p>
            </div>
          </Link>
        </Col>
      </div>
    </Slider>
  );
}
