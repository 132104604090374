export const OurVision = () => {
  return (
    <div className="vision-section" id="our-vision">
      <div className="vision-video-container">
        <video className="vision-video" autoPlay={true} loop muted>
          <source src="/vision-video.mp4" type="video/mp4" />
        </video>
        <div className="vision-video-content">
          <h3>Our Vision</h3>
          <p>
            The Initiative on Transformative Forest Futures is a longitudinal
            program that aims to produce evidence for replicable models of
            large-scale development and economic well-being driven by
            forest-centric value chains. The bedrock of our model is built on
            sustainability, scalability, and technology to synergize public
            policy goals, statutory provisions, multi-stakeholder engagement,
            and market opportunities in India’s forested landscapes.
          </p>

          <p>
            Sustainability is crucial for establishing an ethical, transparent,
            and accountable framework that ensures the long-term viability of
            forests and livelihoods while protecting against resource depletion
            and market volatility. Responsible scaling is key to unlocking the
            potential and returns on investment in forest-based Seasonal Forest
            Products, ensuring benefits, greater bargaining power, and fair
            prices for primary producers, while upholding social and
            environmental justice. Technologies like AI, machine learning, IoT,
            blockchain, and smart contracts are essential for creating a
            transparent digital ecosystem among communities, governments, and
            businesses. They build trust, maximize resource use, and mitigate
            environmental risks.
          </p>
        </div>
      </div>
    </div>
  );
};
