import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { validStates } from "./our-work-data/workStateData";

const DropDown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className="custom-dropdown-toggle" caret>
        Our living laboratories of IOFE
      </DropdownToggle>
      <DropdownMenu className="custom-dropdown-menu">
        {validStates.map((state, index) => {
          return (
            <Link
              to={`/our-work?state=${state[0]}`}
              className="dropdown-link"
              key={index}
            >
              <DropdownItem className="custom-dropdown-item">
                {state[1]}
              </DropdownItem>
            </Link>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropDown;
