import React from "react";
import { Link } from "react-router-dom";
import { workData } from "../../utils/our-work-data/workData";

function OurWorkCardsSection() {
  return (
    <div className="work-card-section">
      <div className="work-card-container">
        <div className="work-grid-container">
          {workData.map((info, index) => {
            return (
              <Link to={info.deepLink} className="work-card-link">
                <div className="work-card" key={index}>
                  <div className="work-card-img">
                    <img 
                    src={`work-image/${info.img}`}className="" alt="card-img" />
                  </div>
                  <div className="work-card-title">
                    <h6 className="">{info.title}</h6>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OurWorkCardsSection;
