import React from "react";
import { Container, Row, Col } from "reactstrap";
import ProjectCarousel from "./ProjectCarousel";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../utils/getWindowSize";

function Projects() {
  const { width } = useWindowSize();

  return (
    <div className="home-project-section">
      <Row>
        <Col md="4" className="home-project-desc-section">
          {width > 0 && width > 1100 && (
            <>
              <p className="home-project-text">Our Initiatives</p>
              <p className="home-project-title-desc">
                Though multiple initiatives, we aim to produce evidence and
                recipes for creating replicable models of large-scale
                development and economic well-being in rural landscapes.
              </p>
              <Link to="/our-work">
                <button className="home-project-button">All Works</button>
              </Link>
            </>
          )}
        </Col>
        {width > 0 && width >= 768 && width < 1100 && (
          <>
            <Col md="12">
              <center>
                <p className="home-project-text">Our Initiatives</p>
              </center>
              <Col md="12" className="home-project-details-section">
                <Container>
                  <Row className="home-project-details-section-row">
                    <Col md="3" className="home-project-column">
                      <Link to="/our-work?state=jharkhand&workId=jhar-1">
                        <img
                          className="home-project-image"
                          src={
                            "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/jharfra.png"
                          }
                          alt="img-alt"
                        />
                      </Link>
                    </Col>
                    <Col md="3" className="home-project-column">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/our-work?state=odisha&workId=odi-2"
                      >
                        <div className="home-project-desc">
                          <p className="home-project-desc-title">
                            Empowering Women-Led Enterprises 
                          </p>
                          <p className="home-project-desc-description">
                            Women-led FPCs and primary collectors from Gumla and
                            Ranchi, Jharkhand, formed their first direct
                            industry partnership by selling Sal seeds to Swedish
                            company AAK.
                          </p>
                        </div>
                      </Link>
                    </Col>
                    <Col md="3" className="home-project-column">
                      <Link to="/our-work?state=odisha&workId=odi-1">
                        <img
                          className="home-project-image"
                          src={
                            "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/ncount-main-page.png"
                          }
                          alt="img-alt"
                        />
                      </Link>
                    </Col>
                    <Col md="3" className="home-project-column">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/our-work?state=jharkhand&workId=jhar-2"
                      >
                        <div className="home-project-desc">
                          <p className="home-project-desc-title mt-3">
                            ISB-Government of Goa MoU
                          </p>
                          <p className="home-project-desc-description">
                            IOFE and the Goa government have signed an MoU to
                            develop evidence-based policies, empowering forest
                            communities and women-led enterprises through
                            technology, sustainability, and market linkages.
                          </p>
                        </div>
                      </Link>
                    </Col>
                    <Col md="3" className="home-project-column">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/our-work?state=jharkhand&workId=jhar-1"
                      >
                        <div className="home-project-desc">
                          <p className="home-project-desc-title">
                            Launch of JharFRA
                          </p>
                          <p className="home-project-desc-description">
                            The JharFRA application has been adopted by GOJ’s
                            Abua Bir Abua Dishom Campaign, to file CFR claims
                            for over 1 lakh individuals through state-civil
                            society collaboration. {" "}
                          </p>
                        </div>
                      </Link>
                    </Col>
                    <Col md="3" className="home-project-column">
                      <Link to="/our-work?state=odisha&workId=odi-2">
                        <img
                          className="home-project-image"
                          src={
                            "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/empowering+women.png"
                          }
                          alt="jg-rani"
                        />
                      </Link>
                    </Col>
                    <Col md="3" className="home-project-column">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/our-work?state=odisha&workId=odi-1"
                      >
                        <div className="home-project-desc">
                          <p className="home-project-desc-title mt-2">
                            Harnessing nCount for Sustainability
                          </p>
                          <p className="home-project-desc-description">
                            The Himachal Pradesh Forest Department's use of
                            nCount will inventory herbs and medicinal plants,
                            enhancing scientific knowledge and guiding policies
                            for ecology, biodiversity, and community needs. 
                          </p>
                        </div>
                      </Link>
                    </Col>
                    <Col md="3" className="home-project-column">
                      <Link to="/our-work?state=jharkhand&workId=jhar-2">
                        <img
                          className="home-project-image"
                          src={
                            "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/Gao-mou.png"
                          }
                          alt="img-alt"
                        />
                      </Link>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <center>
                <p
                  className="home-project-title-desc"
                  style={{ marginTop: "8px" }}
                >
                  Though multiple initiatives, we aim to produce evidence and
                  recipes for creating replicable models of large-scale
                  development and economic well-being in rural landscapes.
                </p>
                <Link to="/our-work">
                  <button className="home-project-button">All Works</button>
                </Link>
              </center>
            </Col>
          </>
        )}
        {width > 0 && width < 768 ? (
          <Col md="12">
            <center>
              <p className="home-project-text">Our Projects</p>
            </center>
            <Col md="12">
              <ProjectCarousel />
            </Col>
            <center>
              <p
                className="home-project-title-desc-mob"
                style={{ marginTop: "8px" }}
              >
                Though multiple initiatives, we aim to produce evidence and
                recipes for creating replicable models of large-scale
                development and economic well-being in rural landscapes.
              </p>
              <Link to="/our-work">
                <button
                  style={{ marginBottom: 35 }}
                  className="home-project-button"
                >
                  All Projects
                </button>
              </Link>
            </center>
          </Col>
        ) : (
          width > 0 &&
          width > 1100 && (
            <Col md="8" className="home-project-details-section">
              <Container>
                <Row className="home-project-details-section-row">
                  <Col md="3" className="home-project-column">
                    <Link to="/our-work?state=jharkhand&workId=jhar-1">
                      <img
                        className="home-project-image"
                        src={
                          "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/jharfra.png"
                        }
                        alt="img-alt"
                      />
                    </Link>
                  </Col>
                  <Col md="3" className="home-project-column">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/our-work?state=odisha&workId=odi-2"
                    >
                      <div className="home-project-desc">
                        <p className="home-project-desc-title">
                          Empowering Women-Led Enterprises 
                        </p>
                        <p className="home-project-desc-description">
                          Women-led FPCs and primary collectors from Gumla and
                          Ranchi, Jharkhand, formed their first direct industry
                          partnership by selling Sal seeds to Swedish company
                          AAK.
                        </p>
                      </div>
                    </Link>
                  </Col>
                  <Col md="3" className="home-project-column">
                    <Link to="/our-work?state=odisha&workId=odi-1">
                      <img
                        className="home-project-image"
                        src={
                          "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/ncount-main-page.png"
                        }
                        alt="img-alt"
                      />
                    </Link>
                  </Col>
                  <Col md="3" className="home-project-column">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/our-work?state=jharkhand&workId=jhar-2"
                    >
                      <div className="home-project-desc">
                        <p className="home-project-desc-title mt-3">
                          ISB-Government of Goa MoU
                        </p>
                        <p className="home-project-desc-description">
                          IOFE and the Goa government have signed an MoU to
                          develop evidence-based policies, empowering forest
                          communities and women-led enterprises through
                          technology, sustainability, and market linkages.
                        </p>
                      </div>
                    </Link>
                  </Col>
                  <Col md="3" className="home-project-column">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/our-work?state=jharkhand&workId=jhar-1"
                    >
                      <div className="home-project-desc">
                        <p className="home-project-desc-title">
                          Launch of JharFRA
                        </p>
                        <p className="home-project-desc-description">
                          The JharFRA application has been adopted by GOJ’s Abua
                          Bir Abua Dishom Campaign, to file CFR claims for over
                          1 lakh individuals through state-civil society
                          collaboration. {" "}
                        </p>
                      </div>
                    </Link>
                  </Col>
                  <Col md="3" className="home-project-column">
                    <Link to="/our-work?state=odisha&workId=odi-2">
                      <img
                        className="home-project-image"
                        src={
                          "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/empowering+women.png"
                        }
                        alt="jg-rani"
                      />
                    </Link>
                  </Col>
                  <Col md="3" className="home-project-column">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/our-work?state=odisha&workId=odi-1"
                    >
                      <div className="home-project-desc">
                        <p className="home-project-desc-title mt-2">
                          Harnessing nCount for Sustainability
                        </p>
                        <p className="home-project-desc-description">
                          The Himachal Pradesh Forest Department's use of nCount
                          will inventory herbs and medicinal plants, enhancing
                          scientific knowledge and guiding policies for ecology,
                          biodiversity, and community needs. 
                        </p>
                      </div>
                    </Link>
                  </Col>
                  <Col md="3" className="home-project-column">
                    <Link to="/our-work?state=jharkhand&workId=jhar-2">
                      <img
                        className="home-project-image"
                        src={
                          "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/Gao-mou.png"
                        }
                        alt="img-alt"
                      />
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Col>
          )
        )}
      </Row>
    </div>
  );
}

export default Projects;
