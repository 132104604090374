import Header from "../../components/Header2";
import { Link } from "react-router-dom";
import LibraryCaraosel from "./Carousel";
import { publicationDetailData } from "../../utils/library-data/publicationDetailData";
import { useScrollToTop } from "../../utils/scrollToTop";

export const LibraryDetail = ({ libId }) => {
  useScrollToTop();
  const detailedData = publicationDetailData[libId];

  return (
    <>
      {detailedData.imageExp && (
        <div className="lib-detail-exp-top">
          <Header activeLink={"2"} modified={true} />
          <div className="lib-detail-exp-top-detail">
            <h2>{detailedData.topHeading}</h2>
            <p>{detailedData?.headingDesc}</p>
          </div>
        </div>
      )}
      {!detailedData.imageExp && (
        <div className="lib-detail-top">
          <img
            src={`lib-image/${detailedData?.topImage}`}
            className="lib-detail-top-image"
            alt="work-img"
          />
          <img src="bg-layer.png" className="lib-detail-top-image" alt="over-lay" />
          <Header activeLink={"2"} modified={false} />
          <div className="lib-detail-top-detail">
            <h2>{detailedData?.topHeading}</h2>
          </div>
        </div>
      )}

      <div className="lib-detail-bottom">
        <div className="lib-detail-bottom-main-head">About the Project</div>
        <div className="lib-detail-bottom-description">
          <div className="lib-detail-bottom-about-desc">
            {detailedData?.aboutDescription?.text.map((textData, index) => {
              return <p>{textData}</p>;
            })}

            <div className="lib-detail-bottom-tag-container">
              {detailedData?.aboutDescription?.aboutTags.map(
                (tagName, index) => (
                  <button className="lib-detail-bottom-btn-tag" key={index}>
                    {tagName}
                  </button>
                )
              )}

              {detailedData?.contentDescription.map((block, index) => {
                switch (block.type) {
                  case "paragraph-heading":
                    return (
                      <h6 key={index} className="paragraph-heading">
                        {block.text}
                      </h6>
                    );

                  case "paragraph":
                    return (
                      <p key={index} className="paragraph">
                        {block.text}
                      </p>
                    );

                  case "bulletPoints":
                    return (
                      <ol key={index} className="bullet-points">
                        {block.text.map((item, idx) => (
                          <li key={idx}>{item}</li>
                        ))}
                      </ol>
                    );

                  default:
                    return null;
                }
              })}
            </div>
          </div>
          <div className="lib-detail-bottom-highlighted-sec">
            {detailedData?.contentDescription.map((block, index) => {
              switch (block.type) {
                case "dark":
                  return (
                    <div className="lib-detail-bottom-high-sec-dark">
                      <div className="lib-detail-bottom-high-left">
                        <p>{block.textLeft}</p>
                      </div>
                      <div className="lib-detail-bottom-high-right">
                        {block.textRight.map((text, index) => {
                          return <p>{text}</p>;
                        })}
                      </div>
                    </div>
                  );

                case "light":
                  return (
                    <div className="lib-detail-bottom-high-sec-light">
                      <div className="lib-detail-bottom-high-left">
                        <p>{block.textLeft}</p>
                      </div>
                      <div className="lib-detail-bottom-high-right">
                        {block.textRight.map((text, index) => {
                          return <p>{text}</p>;
                        })}
                      </div>
                    </div>
                  );

                default:
                  return null;
              }
            })}
          </div>
        </div>
        <div className="lib-detail-back-btn-div">
          <Link to="/library">
            <button className="back-work-btn">Back to Library</button>
          </Link>
        </div>
        <center className="lib-stake-carousel-track">
          <div className="crous-container">
            <LibraryCaraosel detail={true} />
          </div>
        </center>
      </div>
    </>
  );
};
