import React from "react";
import { useNavigate } from "react-router-dom";

const workshopData = [
  {
    type: "dark",
    title:
      "The JharFRA App Training Initiative under the Abua Bir Abua Dishom Abhiyan in Jharkhand",
    url: "/events?type=workshop&id=workshop-1",
  },
  {
    type: "light",
    title:
      "Building Biodiversity Champions: The nCount Training Program for Advanced Ecological Monitoring",
    url: "/events?type=workshop&id=workshop-2",
  },
  {
    type: "dark",
    title:
      "AAK Promoting Sustainable Sal Seed Collection in Simdega, Jharkhand: Strengthening Women-Led Enterprises through Training Programs",
    url: "/events?type=workshop&id=workshop-3",
  },
  {
    type: "light",
    title:
      "Collaborative Initiative on the Scientific Forest Management in Himachal Pradesh",
    url: "/events?type=workshop&id=workshop-4",
  },
  {
    type: "dark",
    title:
      "ISB-TCS Conducted a Field Carbon Measurement Workshop in Simdega, Jharkhand: Securing Forest Resources through Citizen Science",
    url: "/events?type=workshop&id=workshop-5",
  },
];

function Workshops() {
  const navigate = useNavigate();

  const onClicNavigate = (path) => {
    navigate(path);
  };
  return (
    <div className="event-workshop-section">
      <div className="event-workshop-container">
        <h4>Workshops and Events</h4>
        <div className="event-workshop-card-container">
          {workshopData.map((data, index) => {
            switch (data.type) {
              case "dark":
                return (
                  <div
                    className="event-workshop-card-dark"
                    key={index}
                    onClick={() => onClicNavigate(data.url)}
                  >
                    <p>{data.title}</p>
                  </div>
                );

              case "light":
                return (
                  <div
                    className="event-workshop-card-light"
                    key={index}
                    onClick={() => onClicNavigate(data.url)}
                  >
                    <p>{data.title}</p>
                  </div>
                );

              default:
                return null;
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default Workshops;
