export const validWorkIds = [
  "jhar-1",
  "jhar-2",
  "jhar-3",
  "jhar-4",
  "jhar-5",
  "jhar-6",
  "jhar-7",
  "jhar-8",
  "odi-1",
  "odi-2",
  "odi-3",
  "odi-4",
  "odi-5",
  "odi-6",
  "odi-7",
  "odi-8",
  "hp-1",
  "hp-2",
  "hp-3",
  "hp-4",
  "goa-1",
];

export const workData = [
  {
    title:
      "JharFRA: Transforming Forest Governance in Jharkhand through Technology",
    img: "jhar-1-img.png",
    location: "Jharkhand",
    deepLink: "/our-work?state=jharkhand&workId=jhar-1",
  },
  {
    title:
      "Building Biodiversity Champions: The nCount Training Program for Advanced Ecological Monitoring",
    img: "odi-1-img.png",
    location: "Odisha",
    deepLink: "/our-work?state=odisha&workId=odi-1",
  },
  {
    title: "Carbon Pilot Project in Collaboration with TCS",
    img: "jhar-2-img.png",
    location: "Jharkhand",
    deepLink: "/our-work?state=jharkhand&workId=jhar-2",
  },
  {
    title:
      "Women-led FPCs carry out successful Sal Seed Sale to AAK in Gumla and Ranchi District",
    img: "jhar-3-img.png",
    location: "Jharkhand",
    deepLink: "/our-work?state=jharkhand&workId=jhar-3",
  },

  {
    title: "JharFRA Training in Potka",
    img: "jhar-4-img.png",
    location: "Jharkhand",
    deepLink: "/our-work?state=jharkhand&workId=jhar-4",
  },
  {
    title:
      "Bamboo Economy Rising: Building Bioenergy Hubs and Women-led Enterprises through MoU signed with the Government of Goa",
    img: "goa-1-img.png",
    location: "Goa",
    deepLink: "/our-work?state=goa&workId=goa-1",
  },
  {
    title:
      "Women-led FPC Sold 72,000 Kg Sal Seeds to AAK in Simdega, Jharkhand: Multistakeholder Partnership to Create Robust Forest Economy.",
    img: "jhar-5-img.png",
    location: "Jharkhand",
    deepLink: "/our-work?state=jharkhand&workId=jhar-5",
  },
  {
    title:
      "A successful government-supported pilot implementation of CFRR in Simdega, Jharkhand",
    img: "jhar-6-img.png",
    location: "Jharkhand",
    deepLink: "/our-work?state=jharkhand&workId=jhar-6",
  },
  {
    title:
      "Jharkhand Bioeconomy Mission: Tapping the potential of community-owned forests and technology.",
    img: "jhar-7-img.png",
    location: "Jharkhand",
    deepLink: "/our-work?state=jharkhand&workId=jhar-7",
  },
  {
    title:
      "14 CFRR Claims Submitted to SDLC, Simdega: CFRR Titles Empower Communities towards Building Robust Forest Economy",
    img: "jhar-8-img.png",
    location: "Jharkhand",
    deepLink: "/our-work?state=jharkhand&workId=jhar-8",
  },

  {
    title:
      "Formation of Forest Resource Management Committees in Shoar and Shoon, Pangi ",
    img: "hp-1-img.png",
    location: "Himachal Pradesh",
    deepLink: "/our-work?state=himachal-pradesh&workId=hp-1",
  },
  {
    title:
      "Inventory Maps: Key building blocks of Bioenergy Economy in Himachal Pradesh ",
    img: "hp-2-img.png",
    location: "Himachal Pradesh",
    deepLink: "/our-work?state=himachal-pradesh&workId=hp-2",
  },
  {
    title:
      "Himachal Pradesh Bioenergy Mission: Initial consultation with the Chief Minister",
    img: "hp-3-img.png",
    location: "Himachal Pradesh",
    deepLink: "/our-work?state=himachal-pradesh&workId=hp-3",
  },
  {
    title:
      "Building women-led supply chain of Indian Hazelnut from the forests of Himachal Pradesh ",
    img: "hp-4-img.png",
    location: "Himachal Pradesh",
    deepLink: "/our-work?state=himachal-pradesh&workId=hp-4",
  },
  {
    title:
      "Jamia Conducted a one-Day Mobile Based Filming Workshop in Malkangiri, Odisha: Empowering Voices of Young Tribal Girls",
    img: "odi-2-img.png",
    location: "Odisha",
    deepLink: "/our-work?state=odisha&workId=odi-2",
  },
  {
    title:
      "Mayurbhanj Jungle Monch (MJM) Joins the Initiative on Transformative Forest Futures (in-progress)",
    img: "odi-3-img.png",
    location: "Odisha",
    deepLink: "/our-work?state=odisha&workId=odi-3",
  },
  {
    title:
      "Tribal Women Come Together to Share Dreams, Aspirations, and Life’s Lessons at Jungle Rani’s Bichar Charcha Forum",
    img: "odi-4-img.png",
    location: "Odisha",
    deepLink: "/our-work?state=odisha&workId=odi-4",
  },
  {
    title:
      "Setting up Ground for Bamboo Sale in Malkangiri, Odisha: Joining Forces with BGPPL to Foster Forest Economy.",
    img: "odi-5-img.png",
    location: "Odisha",
    deepLink: "/our-work?state=odisha&workId=odi-5",
  },
  {
    title:
      "Maha Gram Sabha Formation of 24 GS in Malkangiri, Odisha: Self-Governance for Sustainable Growth of Forests and Livelihoods",
    img: "odi-6-img.png",
    location: "Odisha",
    deepLink: "/our-work?state=odisha&workId=odi-6",
  },
  {
    title:
      "Bonda Tribes in Malkangiri, Odisha Initiate CFRR Recognition Process: First Step towards legally securing their right on the Forest Landscapes.",
    img: "odi-7-img.png",
    location: "Odisha",
    deepLink: "/our-work?state=odisha&workId=odi-7",
  },
  {
    title:
      "Gram Sabha Takes Action Against Bamboo Cutting by a Contractor in Malkangiri, Odisha: Post CFR filling Changes in the Management of the forests.",
    img: "odi-8-img.png",
    location: "Odisha",
    deepLink: "/our-work?state=odisha&workId=odi-8",
  },
  {
    title: "Jungle Rani: Empowering Young Voices",
    desc: "Jungle Ranis (queens of the forest), are using e-films as a medium to bring their community's stories to light",
    img: "odi-2-img.png",
    location: "Odisha",
    deepLink: "/our-work?state=odisha&workId=odi-2",
  },
];
