import React from "react";

function LibBottom() {
  return (
    <div className="lib-bottom-section">
      <div className="lib-bottom-container">
        <div className="lib-bottom-left">
          <h2>Want update on our every stories and blogs!</h2>
        </div>
        <div className="lib-bottom-right">
          <input placeholder="Your Email ID"></input>
          <button className="lib-subscription-button">Subscribe</button>
        </div>
      </div>
    </div>
  );
}

export default LibBottom;
