import React, { useLayoutEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import eventImg from "../../assests/images/event-img.png";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../utils/getWindowSize";

function Workshop() {
  const { width } = useWindowSize();

  return (
    <div className="workshop-container">
      <center>
        <Link to="/events">
          <button className="workshop-button">Workshops and Events</button>
        </Link>
      </center>
      {width < 768 ? (
        <div className="workshop-img-container">
          <img src={eventImg} alt="workshop-img" />
        </div>
      ) : (
        <div className="workshop-middle">
          <div className="work-left">
            <h1>2024</h1>
            <h3>Learning Exchange</h3>
          </div>
          <div className="work-mid">
            <p>On the way</p>
            <hr className="work-dotted-line" />
          </div>
          <div className="work-right">
            <h1>1 - 3</h1>
            <h3>OCTOBER</h3>
          </div>
        </div>
      )}
      <div className="workshop-bottom">
        <Link to="/events?type=event&id=event-1">
          <button className="workshop-button know-btn">Know more</button>
        </Link>
      </div>
    </div>
  );
}

export default Workshop;
