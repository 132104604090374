import React from "react";
import { useNavigate } from "react-router-dom";

const roundTableData = [
  {
    type: "light",
    title:
      "The Forests’ Bioenergy Opportunity: Investments Powering Sustainability",
    url: "/events?type=round-table&id=round-table-1",
  },
  {
    type: "dark",
    title:
      "Andhra Pradesh RoundTable: Bamboo Forest Landscapes Sustainable Thriving Forest Economy through Security of Tenure",
    url: "/events?type=round-table&id=round-table-2",
  },
  {
    type: "light",
    title: "Design and Communication for Sustainable Forest Economy",
    url: "/events?type=round-table&id=round-table-3",
  },
  {
    type: "dark",
    title: "Technology for Sustainable Forest Economy",
    url: "/events?type=round-table&id=round-table-4",
  },
  {
    type: "light",
    title:
      "Roundtable on Forests as sustainable and secure sources of energy & fossil fuel substitutes",
    url: "/events?type=round-table&id=round-table-5",
  },
  {
    type: "dark",
    title:
      "Boosting the Forest Economy of Himachal Pradesh: A Roundtable Discussion on Security of Tenure",
    url: "/events?type=round-table&id=round-table-6",
  },
];

function RoundTables() {
  const navigate = useNavigate();

  const onClicNavigate = (path) => {
    navigate(path);
  };
  return (
    <div className="event-round-section">
      <div className="event-round-container">
        <div className="event-round-info-container">
          <h4>Roundtables</h4>
          <p>
            We are forging partnerships with government agencies, civil society
            organizations, research institutions, and private companies to
            transform forest landscapes into hubs of opportunity for wealth
            creation.
          </p>
        </div>
        <div className="round-div-container">
          {roundTableData.map((data, index) => {
            switch (data.type) {
              case "light":
                return (
                  <div
                    className="round-circle-light"
                    key={index}
                    onClick={() => onClicNavigate(data.url)}
                  >
                    <p>{data.title}</p>
                    <div className="small-lt-circle small-lt-circle-1"></div>
                    <div className="small-lt-circle small-lt-circle-2"></div>
                    <div className="small-lt-circle small-lt-circle-3"></div>
                    <div className="small-lt-circle small-lt-circle-4"></div>
                    <div className="small-lt-circle small-lt-circle-5"></div>
                    <div className="small-lt-circle small-lt-circle-6"></div>
                    <div className="small-lt-circle small-lt-circle-7"></div>
                  </div>
                );

              case "dark":
                return (
                  <div
                    className="round-circle-dark"
                    key={index}
                    onClick={() => onClicNavigate(data.url)}
                  >
                    <p>{data.title}</p>
                    <div className="small-dk-circle small-dk-circle-1"></div>
                    <div className="small-dk-circle small-dk-circle-2"></div>
                    <div className="small-dk-circle small-dk-circle-3"></div>
                    <div className="small-dk-circle small-dk-circle-4"></div>
                    <div className="small-dk-circle small-dk-circle-5"></div>
                    <div className="small-dk-circle small-dk-circle-6"></div>
                    <div className="small-dk-circle small-dk-circle-7"></div>
                  </div>
                );

              default:
                return null;
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default RoundTables;
