import React from "react";
import Home from "./Home";
import Projects from "./Projects";
import Publications from "./Publications";
import Events from "./Events";
import Workshop from "./Workshop";
import { useScrollToTop } from "../../utils/scrollToTop";

function Main() {
  useScrollToTop();
  return (
    <div>
      <Home />
      <Projects />
      <Publications />
      <Events />
      <Workshop />
    </div>
  );
}

export default Main;
