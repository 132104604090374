export const Impact = () => {
  return (
    <div className="impact-section">
      <div className="impact-container">
        <div className="impact-top-section">
          <h3>Are you ready to make an impact?</h3>
          <button className="join-btn">Join Us</button>
        </div>
        <div className="impact-cards-section">
          <div className="impact-card">
            <h4>Internship</h4>
            <p>
              ed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis
            </p>
            <button className="impact-card-btn">Internships</button>
          </div>
          <div className="impact-card">
            <h4>Jobs</h4>
            <p>
              ed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis
            </p>
            <button className="impact-card-btn">Jobs</button>
          </div>
          <div className="impact-card">
            <h4>Part-Time Jobs</h4>
            <p>
              ed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis
            </p>
            <button className="impact-card-btn">Part-Time Jobs</button>
          </div>
        </div>
      </div>
    </div>
  );
};
