import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/home/Main";
import Work from "./pages/work/WorkMain";
import Footer from "./components/Footer";
import Library from "./pages/library/Library";
import About from "./pages/about/Main";
import Team from "./pages/about/team/Main";
import Event from "./pages/event/Main";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/our-work" element={<Work />} />
          <Route path="/library" element={<Library />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/about-us/team" element={<Team />} />
          <Route path="/events" element={<Event />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
