import { useState } from "react";
import DropDown from "../../utils/CustomDropdown";
import { useWindowSize } from "../../utils/getWindowSize";
import StateWorkCards from "./StateWorkCards";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { workStateData } from "../../utils/our-work-data/workStateData";

export const StateWork = ({ name }) => {
  const maxLength = 500;
  const { width } = useWindowSize();
  const pageData = workStateData[name];

  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  // Check if the text exceeds the maxLength
  const isTruncated = pageData.description.length > maxLength;
  const truncatedText = isTruncated
    ? `${pageData.description.slice(0, maxLength)}...`
    : pageData.description;

  return (
    <>
      <div className="state-work-top-container">
        <div className="state-map-container-main">
          <img src={pageData.mapImage} className="map-img-main" alt="map-img" />
        </div>
        <div className="state-work-landing-top">
          {width > 1024 && (
            <div className="state-top-details">
              <div className="state-top-details-info">
                <p>Not to the scale. Just for the illustration purpose*</p>
              </div>
              <div className="state-top-details-dropdown">
                <DropDown />
                <div>
                  <h2>{pageData.title}</h2>
                  <p>{pageData.description}</p>
                </div>
              </div>
            </div>
          )}

          {width <= 1024 && (
            <div className="state-top-details">
              <div className="state-top-details-info">
                <p>Not to the scale. Just for the illustration purpose*</p>
              </div>
              <div className="state-top-details-dropdown">
                <div>
                  <h2>{pageData.title}</h2>
                  {/* <p>{pageData.description}</p> */}
                  <p>
                    {truncatedText}
                    {isTruncated && (
                      <>
                        <span className="read-more" onClick={toggleModal}>
                          {" "}
                          read more
                        </span>
                      </>
                    )}
                  </p>
                  <DropDown />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <StateWorkCards data={pageData} />

      {/* Modal */}
      {isOpen && (
        <Modal isOpen={isOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>{pageData.title}</ModalHeader>
          <ModalBody>
            <p>{pageData.description}</p>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
