import Contact_Vector from "../../../assests/icons/contact_vector.png";
import contactBadgeIcon from "../../../assests/images/team-vector-badge.png";

export const TeamContactForm = () => {
  return (
    <div className="team-contact-section">
      <div className="team-contact-container">
        <div className="team-contact-vecor">
          <img
            src={Contact_Vector}
            alt="contact-vector"
            className="team-contact-dot"
          />
          <img
            src={contactBadgeIcon}
            alt="contact-vector-badge"
            className="team-contact-badge"
          />
        </div>
        <div className="team-contact-left">
          <h2>Want to contact any of us!</h2>
          <p>
            If you have any query to any of us! You can write to us and we will
            be in touch as soon as possible.{" "}
          </p>
        </div>
        <div className="team-contact-right">
          <input type="text" placeholder="Full name" />
          <input type="text" placeholder="Institution/Organization Name" />
          <input
            type="text"
            placeholder="Name of the person you want to connect to"
          />
          <textarea type="text" rows="4" placeholder="Write your query" />
          <input type="email" placeholder="Your Email ID" />
          <button className="sbt-btn">Send</button>
        </div>
      </div>
    </div>
  );
};
