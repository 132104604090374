import React from "react";
import Home from "./Home";
import { useSearchParams } from "react-router-dom";
import { validEventIds } from "../../utils/event-data/eventsList";
import { EventDetail } from "./EventDetail";
import { useScrollToTop } from "../../utils/scrollToTop";

const eventType = ["event", "workshop", "round-table"];

function Main() {
  useScrollToTop()
  const [searchParams] = useSearchParams();

  const eventTypeParam = searchParams.get("type");
  const eventIdParam = searchParams.get("id");

  const isValidEventType = eventType.includes(eventTypeParam);
  const isValidEventId = validEventIds.includes(eventIdParam);

  return (
    <>
      {(searchParams.size === 0 || !isValidEventId || !isValidEventType) && (
        <Home />
      )}

      {isValidEventType && isValidEventId && searchParams.size === 2 && (
        <EventDetail eventType={eventTypeParam} eventId={eventIdParam} />
      )}
    </>
  );
}

export default Main;
