import React from "react";
import LibraryCaraosel from "./Carousel";

function PubsList() {
  return (
    <div className="lib-pubs-section">
      <center className="lib-stake-carousel-track">
        <div className="crous-container">
          <LibraryCaraosel detail={false}/>
        </div>
      </center>
    </div>
  );
}

export default PubsList;
