import { useEffect, useState } from "react";
import teamData from "../../../utils/TeamData";
import { useWindowSize } from "../../../utils/getWindowSize";

const chunkArray = (array, chunkSize) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

export const OurTeam = () => {
  const { width } = useWindowSize();
  const [teamList, setTeamList] = useState([]);

  useEffect(() => {
    const tempList = teamData.sort((a, b) => a.name.localeCompare(b.name));
    let tempChunkSize;
    if (width > 920) {
      tempChunkSize = 5;
    } else if (width <= 920 && width > 720) {
      tempChunkSize = 4;
    } else if (width <= 720 && width > 620) {
      tempChunkSize = 3;
    } else if (width <= 620 && width > 380) {
      tempChunkSize = 2;
    } else {
      tempChunkSize = 1;
    }
    const teamChunks = chunkArray(tempList, tempChunkSize);
    setTeamList(teamChunks);
  }, [width]);

  return (
    <div className="team-section">
      <h3>We are a bunch of change makers!</h3>
      <div className="team-details-section">
        {teamList.map((info, index) => {
          return (
            <div className="team-info-container" key={index}>
              <div className="team-grid-section">
                {info.map((item, _index) => {
                  return (
                    <div className="team-info-space" key={_index}>
                      <img src={item.url} alt={item.name} />
                      <p className="particulars-name">{item.name}</p>
                      <p className="particulars-pos">{item.profile}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
