export const eventDetailData = {
  workshop: {
    "workshop-1": {
      topTitle:
        "The JharFRA App Training Initiative under the Abua Bir Abua Dishom Abhiyan in Jharkhand",
      // topHeading: "ILE ‘23",
      // topDesc: "",
      topImage: "wshop-1-img.png",
      contentDescription: [
        {
          heading: "About the Event",
          body: [
            "On December 12, 2023, the State Forest Rights Act (FRA) Cell of the Jharkhand Government kicked off a capacity-building program for the ‘JharFRA app’ under the Abua Bir Abua Dishom Abhiyan (My Jungle, My Country). This campaign aims to facilitate the implementation of the Scheduled Tribes and Other Traditional Forest Dwellers (Recognition of Forest Rights) Act of 2006.",
          ],
        },
        {
          heading: "Aim",
          body: [
            "A crucial catalyst for the campaign’s success is to ensure the adept utilization of the JharFRA app, designed to simplify, speed up, and streamline the process of filing individual and community forest resource rights claims.",
          ],
        },
        {
          heading: "Approach",
          body: [
            "In a strategic partnership between the state government and the Initiative on Transforming Forest Futures (ITFF) at the Bharti Institute of Public Policy, ISB a comprehensive three-tier training framework has been conceptualized. Five representatives from each district in Jharkhand have been designated as ‘Master Trainers’ and underwent intensive training by state-level experts on the intricacies of the FRA Act, its rules, CFR claim filing, and title generation processes. With the help of the Shri Krishna Institute of Public Administration (SKIPA), Ranchi, and the Foundation for Ecological Security (FES), over 150 participants—including officials from Revenue and Forest departments, PPIA fellows, e-District Managers, and CSOs—have undergone the training.",
            "Simultaneously, ITFF spearheaded the training of 20 ‘Technology Trainers’ tasked with supporting master trainers in effectively utilizing the JharFRA app and other digital tools within their respective districts. These master and technology trainers have now commenced the training of ‘Bir Bandhus,’ panchayat-level government workers who will assist Forest Rights Committees (FRCs) in generating CFR title claims and conducting awareness camps for FRA and other welfare schemes under the Aapki Yojna Aapki Sarkar Aapke Dwar program. This comprehensive approach aims to expedite the resolution of pending individual and community forest rights claims and set the stage for filing new claims on a large scale.",
          ],
        },
        {
          heading: "Impact",
          body: [
            "The government’s focused efforts on creating robust information systems and decentralized knowledge dissemination mechanisms set a pivotal new standard for future campaigns. Embracing technological innovations and a commitment to recognizing communities as key players in forest rights recognition and community-based forest management is a vital step forward. An estimate of around 1 lakh individuals and 10,000 villages in Jharkhand will prospectively benefit from this campaign.",
          ],
        },
      ],
    },
    "workshop-2": {
      topTitle:
        "Building Biodiversity Champions: The nCount Training Program for Advanced Ecological Monitoring",
      // topHeading: "ILE ‘23",
      // topDesc: "",
      topImage: "wshop-2-img.png",
      contentDescription: [
        {
          heading: "About the Event",
          body: [
            "On the 15th of February 2024, a specialized Physical Training Program focusing on Inventory Mapping was hosted in Odisha (Jashipur and Mayurbhanj), leveraging the nCount platform.",
          ],
        },
        {
          heading: "Aim",
          body: [
            "This program was directed towards imparting detailed training on the intricacies of inventory mapping, employing an integrated approach of nCount with ODK Collect to both the personnel of Vasundhara and community volunteers. Convened from the Vasundhara’s office, the session illuminated the critical importance of inventory mapping and its prospective applications, setting the stage for future engagements in ecological conservation and management.",
          ],
        },
        {
          heading: "Approach",
          body: [
            "Five Program Assistants hailing from a variety of districts, including Mayurbhanj, Deoghar, Kandhamal, Nayaghar, and Sambalpur, participated in the progamme, showcasing a commitment to diversity and collaborative learning. Following the necessary registration process, which required internet access, the assembled participants proceeded to a nearby forest. This field exercise demonstrated the practicality and efficiency of the nCount-facilitated data collection process, underscoring the platform’s user-friendliness and effectiveness. The subsequent engagement at Suanpal village within the Jashipur block further accentuated the benefits of inventory mapping. Dialogues with Vasundhara staff focused on the strategic use of nCount technology for bolstering future conservation and management projects. This was especially relevant considering the villages having received Community Forest Resource (CFR) Title in 2014 and the ongoing efforts to establish a Community Forest Resource Management Committee (CFRMC), underlining a forward-looking approach to employing nCount technology for tangible community and ecological benefits.",
          ],
        },
        {
          heading: "About nCount",
          body: [
            "nCount is an innovation in the realm of ecological knowledge enhancement and the stewardship of natural resources. It combines gamification, remote sensing, and computer vision to create an interactive platform that revitalizes biodiversity data collection.",
            "The application of remote sensing technology within nCount provides for the real-time observation and analysis of ecosystems, offering indispensable insights for conservation activities. Moreover, the precision afforded by computer vision substantially improves the accuracy of species identification and population monitoring, thereby deepening the collective understanding of biodiversity dynamics. In essence, nCount not only accelerates biodiversity research but also strengthens governance models dedicated to the sustainable oversight of natural resources.",
            "Extending its utility beyond the conventional scope of forest ecosystems, nCount integrates visual object recognition modules with an Environmental DNA (eDNA) metabarcoding analysis component. This innovative combination is designed to streamline the assessment, evaluation, and surveillance of natural ecosystems, enhancing the platform’s capability to support comprehensive ecological studies and conservation strategies effectively.",
          ],
        },
      ],
    },
    "workshop-3": {
      topTitle:
        "AAK Promoting Sustainable Sal Seed Collection in Simdega, Jharkhand: Strengthening Women-Led Enterprises through Training Programs",
      // topHeading: "ILE ‘23",
      // topDesc: "",
      topImage: "wshop-3-img.png",
      contentDescription: [
        {
          heading: "About the Event",
          body: [
            "ISB (Indian School of Business), Sweden-based oil manufacturer company AAK and Jharkhand State Livelihood Promotion Society (JSLPS) collaborated to organize three Sal seeds collection training sessions in Kolebira, Bano, and Jaldega from May 10 to 12, 2023. These sessions aimed to equip nearly 100 women from Self-Help Groups (SHGs) and Producer Groups (PGs) with the necessary skills for Sal seeds collection in Simdega. Representatives from AAK India Pvt Ltd. shared their best practices and experiences from their work in other regions.",
          ],
        },
        {
          heading: "Key Takeaways from Training Programs",
          body: [
            "The trainers’ expressed satisfaction with the existing practices of Sal seed gatherers in Simdega. However, to ensure the sustainability of the Sal seeds collection, certain hygiene factors were collectively decided upon. These factors are as follows:",
            {
              type: "bulletPoints",
              text: [
                "No forest fires: Women were urged not to set fires in the forest during the collection process.",
                "Selective picking: Only mature Sal seeds were to be collected by hand, while smaller, immature ones were to be left behind.",
                "Elimination of child labour: Strict adherence to the prohibition of child labour during the collection process.",
                "Flatbed preparation: Collected seeds should be arranged in a flatbed formation on a dry open space in the homestead instead of forming heaps.",
                "Controlled burning: Setting low-intensity fires to burn only the wings and outer layer of the seed coat, preventing over-burning, and blackening of seeds.",
                "Proper seed breaking: Sal seeds should be gently broken into four parts using light stones, avoiding over-grinding.",
                "Sun drying and moisture prevention: Sun drying the seeds and ensuring they remain dry, avoiding contact with water.",
                "Adequate storage: Store the seeds in a cool, dry place to prevent infestation.",
                "Preferred packing method: Packing the seeds in   PP (polypropylene)bags to ensure proper storage and transportation.",
                "No adulteration: Strict measures to prevent adulteration with stones, pebbles, dirt, or other foreign substances.",
              ],
            },
          ],
        },
        {
          heading: "Dissemination and Implementation",
          body: [
            "These guidelines will be widely circulated in Hindi by the JSLPS cadre and the trainees themselves. Additionally, it is recommended to include these hygiene factors in the self-declaration form to ensure their implementation and accountability.",
          ],
        },
        {
          heading: "Price Declaration and Market Credibility",
          body: [
            "During the training sessions, participants expressed their eagerness to know the purchase price for Sal seeds. They emphasized the importance of promptly declaring the price to encourage collectors to sell their goods to producer groups instead of the open market. Previous experiences with tamarind and other products had raised concerns about the credibility of community enterprises. Therefore, addressing this issue and providing transparent pricing information is crucial to fostering trust and strengthening community-based initiatives.",
          ],
        },
        {
          heading: "Looking Forward",
          body: [
            "The collaborative efforts of ISB, JSLPS, and AAK India Pvt Ltd. in organising Sal seed collection training sessions have empowered women in Simdega. By imparting essential knowledge and promoting sustainable practices, these sessions contribute to the long-term viability of the Sal seeds collection. It is anticipated that the dissemination of the training guidelines and the declaration of purchase prices will further enhance the credibility of community enterprises and foster economic growth for women of the forest communities.",
          ],
        },
      ],
    },
    "workshop-4": {
      topTitle:
        "Collaborative Initiative on the Scientific Forest Management in Himachal Pradesh",
      // topHeading: "ILE ‘23",
      // topDesc: "",
      topImage: "wshop-4-img.png",
      contentDescription: [
        {
          heading: "About the Event",
          body: [
            "On January 15, 2024, the Himachal Pradesh Forest Department and the Bharti Institute of Public Policy (BIPP) at the Indian School of Business (ISB) launched a collaborative project to inventory major seasonal forest products in the state. This project aims to estimate the occurrence and distribution of these products and project potential revenue for the state government through sustainable management and regulated trade.",
          ],
        },
        {
          heading: "Strategy",
          body: [
            "Phase one of the nCount training was implemented across six divisions in Himachal Pradesh: Ani, Dehra, Nachan, Nurpur, Palampur, and Paonta. Over two months, the forest department successfully collected approximately 40,000 data points on various forest species. On June 9, 2024, a review meeting was held at the ISB Mohali campus to discuss the on-ground experiences of forest officials, address their queries, and incorporate their insights for the planning of Phase II. This meeting provided a platform to refine the nCount technology based on practical feedback, ensuring its effective implementation for sustainable forest management.",
            "Prof. Ashwini Chhatre, Executive Director of BIPP, ISB, welcomed the Himachal Pradesh Forest Department (HPFD) officials and initiated the meeting with introductions. He outlined the agenda, focusing on sharing field experiences and lessons learned from adopting the nCount technology. Prof. Chhatre emphasized that insights from nCount could refine forest economy initiatives and help propose nCount as a modern technology for better forest management to other state governments.",
            "Dr. Pushpendra Rana, Additional Principal Chief Conservator of Forests (APCCF), discussed the utility and benefits of the nCount platform for Himachal Pradesh Forest Department and local communities, highlighting its role in sustainable forest resource management and economic linkages for community livelihoods. He explained that integrating AI and machine learning would enable the forest department to create and maintain a verified inventory of Seasonal Forest Products (SFPs) and track annual changes, assisting in preparing working plans and resource inventories.",
          ],
        },
        {
          heading: "Feedback on nCount Technology",
          body: [
            "During the review meeting, foresters shared their experiences and challenges encountered while using the nCount and ODK technologies for forest inventory management. The discussions highlighted several critical issues related to data collection, species selection, and the capacity and training needs of forest guards.",
            "Data Collection: One major challenge was the high number of data points required for shrubs and herbs, which proved difficult to achieve in areas with limited forest guard resources. It was clarified that these statistical requirements are essential for the model to produce accurate range level maps. Additionally, forest guards reported difficulties in capturing photos of tall trees and trees surrounded by dense vegetation. The training protocols specified that photos should be taken without zooming, which posed a challenge in some situations. There were also issues with achieving the required 5meter accuracy for data capture in dense forests, leading to a proposal to increase the allowable accuracy threshold to 10 meters.",
            "Species Selection: The discussion revealed the need to include a wider variety of bamboo species and other region-specific flora. For example, the Sal tree, abundant in certain forest divisions, was not adequately represented in the nCount forms. Suggestions were made to add species such as Shisham, Dhau, Curry Patta, Garna, Rohini, Ban Oak, and Deodar. It was agreed that Cedrus deodara and Quercus leucotrichophora would be added to the ODK platform to better reflect the biodiversity of the region.",
          ],
        },
        {
          heading: "Other Observations",
          body: [
            "Forest guards were noted to have limited knowledge of medicinal plants, herbs, and shrubs, indicating a need for additional training workshops led by experts. It was suggested that traditional area sampling techniques be employed instead of tree species sampling. However, it was explained that the AI-based model addresses the limitations of area sampling by using satellite imagery to generate prediction models.",
            "A new monitoring dashboard is being developed to help DFOs track progress and plan better. Additionally, the lack of access to ODK for iPhone users was highlighted, with assurances that an iPhone-compatible version would be available soon. Concerns were also raised about capturing data for herbs and shrubs, with questions about the feasibility of using satellite imagery for this purpose. Challenges in creating small polygons of 5 meters were also discussed, with the need for larger polygons being recognized.",
          ],
        },
        {
          heading: "Technical Analysis of Phase-I Presentation",
          body: [
            "The results of Phase I were presented, showing around 40,000 data points collected. Species distribution maps were displayed, and the process behind their creation was explained. The presentation highlighted the classification of five species: Taxus baccata, Sal, Rhododendron, Khair, and Wild Mango. It detailed how satellite imagery, AI models, and other data sources were used to create accurate species distribution maps.",
            "It was clarified that the AI model is designed to address the inherent limitations of area sampling methods, using a combination of satellite imagery, spectral data, and other parameters to build a comprehensive prediction model. The issue of visualizing species distribution at different scales was also addressed, emphasizing the need for more data points to enhance accuracy. Validation efforts were discussed, including techniques to ensure data accuracy and reliability. For instance, Google Earth snapshots and point cloud simulations showed high accuracy levels in species identification and geometry reconstruction. The meeting concluded with a focus on the need for at least 5000 data points per species to ensure robust validation. Once this threshold is met, the actual model will be used to generate predictions, which will undergo further validation.",
          ],
        },
        {
          heading: "Way Forward: Phase-II of nCount Implementation",
          body: [
            "The next phase of the nCount initiative will focus on strategic species selection and extensive data collection to enhance forest management. Prof. Ashwini Chhatre emphasized the importance of choosing species with high market potential, such as Camellia, which is expected to gain value as a nature-based substitute in the chemical industry. This strategic preparation will attract industry partners interested in sustainably harvested forest products, benefiting local communities.",
          ],
        },
        {
          heading: "Primary goals for Phase II",
          body: [
            {
              type: "bulletPoints",
              text: [
                "Data Collection: Aim to collect at least 5000 data points per species to ensure comprehensive coverage and accuracy. Expand the species inventory by adding at least 40 new high-altitude species, such as Quercus incana, Deodar, Rohini, Sea buck Thorn, and Kadu. Data collection for these species will begin in July and last for two months, targeting locations like Pangi, Rohru, Spiti, Nachan, and Nurpur.",
                "Drone Mapping: Initiate drone mapping from June 25, with tech team members and foresters visiting high-altitude regions. The drones, equipped with 4872-megapixel cameras and three sensors, will accurately georeference structural data to gather points for herbs. Collaborate with IIT Delhi to develop robust autonomous drone models, addressing challenges in automated flight. The drone mapping will be conducted in three phases: initial calibration with standard drones, followed by detailed mapping in specific forest areas.",
              ],
            },
            "During the discussions, Pushendra Rana highlighted that drone technology would also aid in identifying forest fire locations and water storage areas, enhancing resource inventory mapping and rejuvenation efforts.",
          ],
        },
        {
          heading: "Immediate Next Steps",
          body: [
            {
              type: "bulletPoints",
              text: [
                "Commencement: Phase 2 activities will begin in July, with drone mapping starting on June 25.",
                "Species Addition: Formalize the inclusion of species like Deodar, Ban Oak, and various bamboo species (Bambusa Bamboo and Dandrocalamus strictus).",
                "Training: Organize expert-led training sessions for foresters on the traditional knowledge of Seasonal Forest Products (SFPs).",
                "Technology Updates: Launch Version 2 of the nCount platform, compatible with Apple devices, incorporating the collected species wise data.",
                "Capacity Building: Conduct capacity building and training programs for foresters on nCount technology and drone mapping techniques.",
                "Data Collection Protocol: Foresters will capture polygons within a 10-meter range, take non-zoomed leaf pictures, and ensure accurate data collection for bamboo species.",
                "Future Meetings: Schedule the next review meeting in August, involving current DFOs and those from high-altitude regions.",
                "Validation: Ensure data validation through ground truthing to maintain data accuracy and reliability.",
                "Permissions: Obtain necessary permissions from the forest department to fly drones in HP forests.",
                "These steps aim to refine and expand the nCount initiative, leveraging advanced technology for sustainable and effective forest management in Himachal Pradesh.",
              ],
            },
          ],
        },
      ],
    },
    "workshop-5": {
      topTitle:
        "ISB-TCS Conducted a Field Carbon Measurement Workshop in Simdega, Jharkhand: Securing Forest Resources through Citizen Science",
      // topHeading: "ILE ‘23",
      // topDesc: "",
      topImage: "wshop-5-img.png",
      contentDescription: [
        {
          heading: "About the Event",
          body: [
            "ISB, in collaboration with Tata Consultancy Services Ltd. (TCS), organized a one-day Forest Carbon Stock assessment workshop in Tribal Art Center, Tutikel of Simdega district in Jharkhand, on 23 May 2023. Owing to the tremendous support and active engagement from team members, the pilot was successfully launched with 34 students from different educational institutes in three pilot blocks (Kolebira, Jaldega, and Bano) of the Initiative on the Forest Economy (ITFF).",
          ],
        },
        {
          heading: "Key Objectives",
          body: [
            "The objective of the pilot workshop was to engage students in hands-on learning experiences related to carbon measurement and foster environmental awareness among youth communities.  The program aimed to empower students with the knowledge and skills necessary to measure and understand carbon levels in their local forests. ISB-TCS partnership aims to advance innovation in using technology to monitor forests and their products for societal development, practicing citizen science, where people share and contribute to data monitoring and collection programs.",
            "The training program attempted to introduce the students to the use of new technology to capture data in forest landscapes and help them recognize the importance of environmental stewardship. Through the pilot program, the participating students developed a preliminary foundation of knowledge in the carbon cycle and the importance of measuring carbon. The hands-on nature of the program equipped students with practical skills in the use of mobile-based tools in forest carbon measurement techniques and data collection. These skills can be applied in future scientific investigations and community-based carbon measurement activities.",
          ],
        },
        {
          heading: "Training Approach",
          body: [
            "For the pilot workshop, ten test field sites within the training location were identified. These sites were chosen to allow students to explore the different factors that influence carbon levels in the forests. Using the F-DAT tool, students collected sample data at each demonstration field site. They recorded data on factors such as tree height, DBH, crown cover, etc. The students also learned the basics of biomass and carbon measurement using the data collected by them. Prior to the field measurements, students received training sessions on basic environmental concepts, carbon cycle, and carbon measurement techniques. They were provided with appropriate instruction on the use of the F-DAT tool developed by Tata Consultancy Services.",
            "The field carbon measurement pilot program with students demonstrated the potential for engaging young learners in environmental science and promoting environmental awareness in communities. By providing hands-on experiences and practical skills, the program aims to empower students to measure and understand carbon in their community-owned forests.",
            {
              type: "paragraph-heading",
              text: "This workshop is conducted under the ISB-TCS three-year partnership “Forest Carbon Stock Assessment Pilot -Sky and Earth Convergence Carbon” project.",
            },
          ],
        },
      ],
    },
  },
  "round-table": {
    "round-table-1": {
      topTitle:
        "The Forests’ Bioenergy Opportunity: Investments Powering Sustainability",
      // topHeading: "ILE ‘23",
      // topDesc: "",
      topImage: "round-1-img.png",
      contentDescription: [
        {
          heading: "About the Event",
          body: [
            "The Investment Roundtable on “The Forests’ Bioenergy Opportunity,” held on February 15th, 2024, at the Indian School of Business Hyderabad, brought together a diverse group of stakeholders to discuss the untapped potential of forest resources in bioenergy production. Organized by GIZ and the Initiative on the Forest Economy at Bharti Institute of Public Policy ISB, this event aimed at exploring the roles of investment and innovation in harnessing forest resources for sustainable energy solutions. The discussions emphasized sustainable utilization of resources, innovative financial strategies, and the importance of collaborative efforts between communities, investors, and the government to overcome challenges and pave the way for a sustainable bioenergy sector.",
            "The roundtable concluded with a strategic vision for the future, focusing on the creation of a comprehensive document to serve as a roadmap for investments in the forest bioenergy sector. This document highlights opportunities, addresses constraints, and explores financing options, guiding stakeholders toward sustainable development and growth in this emerging field. The event underscored the importance of continuous engagement, capacity building, and innovative solutions to realize the full potential of forests in bioenergy production, marking a significant step forward in the journey towards a sustainable energy future.",
          ],
        },
      ],
    },
    "round-table-2": {
      topTitle:
        "Andhra Pradesh RoundTable: Bamboo Forest Landscapes Sustainable Thriving Forest Economy through Security of Tenure",
      // topHeading: "ILE ‘23",
      // topDesc: "",
      topImage: "round-2-img.png",
      contentDescription: [
        {
          heading: "About the Event",
          body: [
            "The Indian School of Business (ISB) in collaboration with the Government of Andhra Pradesh hosted a Round Table on October 30th, 2023, at ISB, Hyderabad. This event focused on developing opportunities within Andhra Pradesh’s bamboo forest landscapes. This Round Table is a part of ISB’s Initiative on the Forest Economy, aiming to facilitate Radical Forest Futures – a vision of a thriving, robust, and sustainable forest economy beneficial to profits, the planet, and people.",
            "The Round Table, chaired by Shri Neerabh Kumar Prasad, IAS, Special Chief Secretary, Environment, Forest, Science & Technology Department, Government of Andhra Pradesh, and co-moderated by Prof. Ashwini Chhatre, Executive Director of the Bharti Institute of Public Policy, ISB, and Mr. Madhusudhana Reddy, IFS, PCCF-HoFF (Principal Chief Conservator of Forests – Head of Forest Force), convened a diverse array of stakeholders – including senior government officials, private sector leaders, finance organizations, and academic institutions – to deliberate on exploiting the forest economy potential in AP’s bamboo forest landscapes.",
          ],
        },
        {
          heading: "Roundtable Discussion Context",
          body: [
            "The Round Table began by defining the bamboo forests’ opportunity landscape, alongside the current challenges and limitations. It highlighted the historical changes in bamboo usage and presented the opportunity that lies in Andhra Pradesh’s forests, which are abundant in bamboo, necessitating regular and sustainable management and harvesting. Discussion points included the limitations in current extraction policies, issues with bidding systems, the lack of long-term contracts, and inefficiencies in transport and logistics systems. The potential for these bamboo forest landscapes to contribute positively to the climate was also discussed.",
            "The session then shifted focus to the community aspect, discussing Andhra Pradesh’s Vana Samrakshana Samities (VSS). Although these committees have been dormant, their reactivation was seen as an opportunity for fostering community-led participation in sustainable business and forest management practices. The discussion recognized that for the forest landscapes to contribute to a sustainable economy, an integration of production and marketing is essential. Opportunities in sectors such as biofuel, textiles, pulp, and furniture were also identified.",
          ],
        },
        {
          heading: "The Forest Economy Opportunity and Model",
          body: [
            "The Round Table then examined the necessary policy framework for a thriving forest economy model, emphasizing the need for convergence and integration. It noted the increasing demand for bamboo across various sectors and the support of emerging viable technologies for bringing in the necessary efficiencies.",
            "Particularly highlighted was India’s E20 mandate (a blend of 20% ethanol with petrol) by 2025, which necessitates a rapid scaling of ethanol production capacity. Since traditional raw materials like sugarcane and rice are insufficient for this mandate without impacting agricultural land and food security, forests emerge as a valuable source for ethanol production.",
            "The discussion traced the history of forest exploitation in the 1970s and the subsequent regulations imposed on industry access to forests. Since the mid-1990s, there has been experimentation with measured economic activity from forests, with several checks and balances aimed at regeneration and motivating various stakeholders (both community and industry) for forest protection. A synergy between regeneration and livelihoods was identified as key to tapping into the forest economy opportunity. The past 30 years have provided sufficient evidence and confidence that this opportunity is viable.",
          ],
        },
        {
          heading: "Three major levers for harnessing key opportunities",
          body: [
            "The Round Table then examined the necessary policy framework for a thriving forest economy model, emphasizing the need for convergence and integration. It noted the increasing demand for bamboo across various sectors and the support of emerging viable technologies for bringing in the necessary efficiencies.",
            "Particularly highlighted was India’s E20 mandate (a blend of 20% ethanol with petrol) by 2025, which necessitates a rapid scaling of ethanol production capacity. Since traditional raw materials like sugarcane and rice are insufficient for this mandate without impacting agricultural land and food security, forests emerge as a valuable source for ethanol production.",
            "The discussion traced the history of forest exploitation in the 1970s and the subsequent regulations imposed on industry access to forests. Since the mid-1990s, there has been experimentation with measured economic activity from forests, with several checks and balances aimed at regeneration and motivating various stakeholders (both community and industry) for forest protection. A synergy between regeneration and livelihoods was identified as key to tapping into the forest economy opportunity. The past 30 years have provided sufficient evidence and confidence that this opportunity is viable.",
            {
              type: "bulletPoints",
              text: [
                "Security of Tenure: Security of tenure was recognized as crucial, providing ownership incentives to communities for long-term sustainable management of forest landscapes and economic and legal ownership for managing forest resources. This security would enable formal, secure, transparent, and economically viable engagements between communities and industry, ensuring sustainable management of forest landscapes. Examples of security of tenure from different parts of the world and multiway partnerships were shared.",
                "Economies of Scale from Technology: The introduction of technology for first-mile raw material and product processing was seen as a significant enhancer of efficiency. For instance, a bamboo splitter that vertically splits bamboo poles into 4-6 strips at the point of harvesting can double the tonnage per truck. Such investments in technology become feasible when operations scale beyond the household level to community-owned enterprises that amalgamate several villages into a single business unit. Other examples included simple efficiency devices like measuring devices for the Gross Calorific Value of raw materials (critical for the energy sector) and moisture meters for assessing raw material moisture at the source.",
                "Market Linkage: Industry & Community-owned Enterprises: The importance of community-owned enterprises in directly finding large buyers from various sectors was stressed. A well-constructed ecosystem could foster synergies among different industries using forest-sourced raw material/bamboo and its processing. For example, bamboo pulp could be utilized by the textile and paper sector, and for ethanol production, while the silica-rich outer rim of bamboo could be used in the agarbatti and cosmetic industries. It was suggested that the industry should invest in community-owned enterprises/Farmer Producer Organizations (FPOs) as long-term secure and competitive suppliers. The necessity of building investments and value chains based on economic opportunity rather than charity was emphasized, ensuring long-term profitability and sustainability for both suppliers and buyers. In this model, the industry also stands to contribute directly to biodiversity conservation, women’s empowerment, and carbon sequestration. Technology would play a key role in accurately measuring and attributing the benefits of carbon sequestration and other outcomes to the industry.",
              ],
            },
            {
              type: "paragraph-heading",
              text: "Two additional aspects were highlighted:",
            },
            {
              type: "numBulletPoints",
              text: [
                "The current absence of one of the key stakeholders in the model at the roundtable discussion – the communities. It was projected that in 5-10 years, leadership and capacity at the community enterprises would enable active community participation in such meetings.",
                "The development of novel models like the forest economy model requires ongoing dialogue, regular collaboration over extended periods for trust building, and harnessing bilateral synergies. The cohort aimed to discuss a system or protocols by the end of the roundtable for building new business models that would benefit communities, industry, and the forests. The Round Table discussed the dual nature of forests as conservation and commercial opportunities. Historically, forests have not significantly contributed to revenue, focusing only on protection. This has also put investments in production and protection at risk due to the lack of measurable returns on investment.",
              ],
            },
            "Furthermore, inadequate maintenance levels make harvesting bamboo difficult, exacerbating the poor upkeep of the health of the bamboo landscapes. The discussion underlined the need for 23% of the state’s geographical area, which is forested, to become a significant contributor to the national economy. This would ensure the sustained management of forest landscapes. It was agreed that regular bamboo harvesting is essential to ensure sustainable management of bamboo landscapes, prevent gregarious flowering, manage resources well, avoid fire hazards, and improve light penetration on the land. ",
          ],
        },
        {
          heading: "Role of Industry",
          body: [
            "The Round Table then focused on the use of bamboo as a raw material source for several sectors. Despite being the world’s second-largest source of bamboo, India still imports bamboo for various industrial purposes, including agarbatti production. While India is home to more than 300 species of bamboo, only specific species are efficiently used by the agarbatti industry. The limitations of subsidized machinery, which do not lead to sustainable operations due to challenges in accessing raw materials, were discussed. 95% of the bamboo sourced is not used by the agarbatti industry; only the top green skin is used for agarbatti making, and the rest needs disposal. The discussion pointed out how China has created an ecosystem where several related industries come together to use different parts of bamboo for their specific purposes. The need and opportunity for segmented uses of bamboo were discussed in depth. Addressing the availability of specific species and establishing ecosystems for consuming the rest of the 95% of the raw material was identified as a key need.",
            "As a coal replacement, the price of bamboo, compared to coal, becomes a prime consideration for industries like cement. Bamboo’s high gross calorific value (GCV) makes it an attractive and lucrative option. The cement industry is prepared for trials at scale, with the advantage that cement kilns accept any size of bamboo chips smaller than 40mm, simplifying first-mile processing. The discussion noted that investments worth several thousands of crores in feeding agriculture and other bio-waste as a replacement for coal have not been recoverable, given the high costs and challenges in collecting agricultural waste. Access to large sources of competitively priced bamboo presents a significant opportunity for the industry to develop sustainable sources of coal replacement. Compared to RDF (refuse-derived fuel) – produced from domestic and business waste, which includes biodegradable material), bamboo is more sustainable as it not only replaces coal but also integrates carbon dioxide into circularity.",
            "At this stage, two other important aspects were re-emphasized: (a) Subsidy-Free Models: The stakeholders can collaborate to build models free of subsidies and charity. There is ample capital available, but what is required is the development of management skills at the community level and convergence, i.e., the coming together of various stakeholders to build novel business models. (b) Government as Enabler and Regulator: The government’s role as an enabler and regulator was emphasized, with the expectation that it should not participate directly in the business models. The large and readily available market for biofuel was pointed out, with a broad thumb rule of Rs. 2 per Gross Calorific Value (GCV) being a current sustainable and good price for alternative sources of coal. There is an opportunity to further process bamboo to enhance the GCV (through biochar/torrefaction). In addition, the industry can earn carbon credits from sourcing sustainably from forest landscapes. Technology is viable only at scale and can also aid in building traceability in value chains",
            "It was mentioned that industry should not be given direct control over forest landscapes. Bamboo’s role as a multifaceted solution for energy needs was underscored. It is notably utilized in the thermal power industry, and beyond its energy contributions, bamboo enhances ecological health, such as improving the water table where it thrives. The sector’s substantial demand cannot be fulfilled by agricultural residue alone due to the high costs of raw material procurement and the fixed rates of power, limiting the ability to adjust raw material costs. The potential of CBG (compressed biogas), with bamboo as a primary feedstock, was also explored. In this process, extracting methane leaves a residue that can be transformed into bio-fertilizers, adding another dimension to bamboo’s utility.",
            "For industries, three critical factors – quantity, quality, and price – dictate investment decisions. Industries are poised to invest in areas where these factors align with business models, indicating a readiness to infuse capital into viable projects. In the realm of handicrafts, technology’s significance in enhancing efficiency and scale even in smaller sectors like bamboo handicrafts was acknowledged. Combining machine production with artisanal finesse creates competitive handicraft products. The discussion also touched on the importance of resource mapping, which involves cataloging bamboo availability and species to align industry demand with supply.",
            "A convergence opportunity was identified, with the Department of Forests facilitating sustainable management practices. This involves industries procuring raw materials at scale from community-owned enterprises underpinned by secure tenure. The Indian Institute of Forest Management is set to provide necessary community training and capacity building for managing forest landscapes, with stakeholders collaboratively developing indicators to ensure long-term forest health. Certifications for sustainable and secure raw material sourcing will be pivotal. In the financial domain, the presence of various financing options and priority sector lending to support projects in this sector was highlighted by finance organizations.",
          ],
        },
        {
          heading: "Way Forward",
          body: [
            "The group resolved to initiate three pilot projects by the end of November 2023. These projects, representing unique district-industry combinations, will start with industry needs and requirements shaping the necessary value chains, encompassing technology, finance, community ownership, and capacity building. Multi-partite collaborations will be established between VSS with security of tenure, Industry, Andhra Pradesh Forest Department, Government of Andhra Pradesh, and stakeholders in technology and finance. These pilots will be grounded in viable business models and secure supply chains, not reliant on subsidies or charity. Following the initial planning phase, dedicated teams for each pilot will meet regularly to develop detailed rollout plans. These project groups, comprising industry, AP Forest Department, Government of AP, ISB, IIFM, technology, and finance teams, will work together to refine each pilot’s specifics. Additionally, the pilots will include strategies for transitioning VSS populations into Community Forest Resource Rights (CFRR) under the Forest Rights Act (FRA) and fostering community-led enterprises – essential for creating secure and viable industrial value chains. Meanwhile, ISB and the Andhra Pradesh Forest Department are collaborating to enhance bamboo inventory estimation and establish a high-accuracy inventory model for bamboo distribution and abundance. The roundtable marks a significant step towards transformative change in the bamboo sector, promoting sustainability, innovation, and community empowerment. This commitment to translating discussions into concrete actions paves the way for a resilient and prosperous future for India’s bamboo-based forest landscapes.",
          ],
        },
      ],
    },
    "round-table-3": {
      topTitle: "Design and Communication for Sustainable Forest Economy",
      // topHeading: "ILE ‘23",
      // topDesc: "",
      topImage: "round-3-img.png",
      contentDescription: [
        {
          heading: "About the Event",
          body: [
            "Design and Communication for Sustainable Forest Economy” was the topic of the third of four roundtables (RTs) held in November 2022 to move further towards the vision of “Initiative on the Forest Economy (ITFF)” by the Bharti Institute of Public Policy’s (BIPP), Indian School of Business (ISB).",
          ],
        },
        {
          heading: "Importance of Robust Information Dissemination",
          body: [
            "Designers, artists, and representatives from civil society came together for this closed group discussion. Prof. Ashwini Chhatre, Director, BIPP-ISB, shared the vision for a thriving forest economy and the potential that the forest economy model, anchored in secure tenure, holds for the industry, the macro economy, the communities, and the sustainable management of forest resources. He opened the discussion by speaking about building a robust design to facilitate the dissemination of information.” He believes that reducing information asymmetry among key stakeholders is essential for this model to work. It is necessary to make sure that everyone can make an informed choice. Understanding the wide range of stakeholders playing a variety of roles at different levels is key to the forest economy model. Accessibility to information will also enhance communication among stakeholders, such as communities, government agencies, the commercial sector, technology developers, and suppliers. There are several public and private schemes and regulations that can allow opportunities for strengthening the role and position of women in the value chain.",
          ],
        },
        {
          heading: "Perspectives from Key Stakeholders",
          body: [
            "Representatives from the Indian Institute of Technology (IIT) Kanpur’s Incubation division discussed the institute’s current work in the Technology for Tribal programme. He shared his experience with setting up a jamun pulp production unit. The processes of handpicking jamun, processing it, and bottling jamun pulp were streamlined. Their interventions reduced waste by up to 50%. They highlighted how those tribal systems could offer an indigenous design element to provide robustness to modern technology while advances in bioscience can support the management of forest products. There is a huge information gap, which calls for additional effort as well as cooperation between different public and private entities. Empowering the tribals requires simple, uncomplicated solutions and the government’s support for the effective implementation of such institutional initiatives. They highlighted the financial challenges faced during the delivery of outcomes that came out of the hackathon organised to address forest product-related problems. In conclusion, they discussed the need for a business process framework that is adaptable to many situations and circumstances.",
            "The representatives from the School of Planning and Architecture (SPA), Delhi, discussed aspects of deploying prototype business models and the associated logistical support and cost-effective technology. They resonated with the provisioning of simple solutions designed using conventional technology. He provided an example of his students working on developing an incubator. It was important to understand the basic features of an incubator and consider its utility in areas where electricity is scarce. They devised a solar-powered incubator that was a robust design solution for the given criteria. Such simple solutions can help in designing technology for tribals.",
            "The importance of creating awareness about forest-based products was discussed. This attitude shift can create demand at scale, driving large companies to accept such a sustainable business model in the long run. The representatives from the Industrial Design Centre, IIT Bombay, Echo Network, and Srishti Institute of Art, Design, and Technology each made recommendations based on their experience with storytelling tools to enhance women’s engagement. Raising awareness through effective communication and activities such as puppetry, plays, comics, filmmaking, and other documenting methods for putting the spotlight on seasonal forest products were a few other strategies proposed to facilitate working with forest-dependent communities. The need for empathetic listening and acknowledging the needs of the communities are key to designing a supportive economic model for them It was highlighted that each community has its own priorities and systems that can enrich the model with knowledge and stewardship if the youth are trained with an understanding of cultural and socio-ecological nuance.",
          ],
        },
        {
          heading: "Principal ideas of the discussion",
          body: [
            "Institutional tie-ups between IIT, ISB, and the National Institute of Design (NID) to contribute to respective domain areas of machine design, business mentorship, strategy, filmmaking, and communication. BIPP-ISB’s role will be to strengthen financial accessibility and the policy framework.",
            "The stakeholders shall design a strategy to strengthen seasonal forest product market connections. Pick a regional product that is ecologically viable, do a trial project to improve the product, and then expand across other regions of India. Incorporate communication, political, and economic factors during the business framework design process. Discover strategies to expand access to healthcare, education, and income for forest-based communities with the assistance of initiatives such as Pradhan Mantri van Dhan Yojana’s Entrepreneurship and Skill Development Programme (PMVDY-ESDP) without compromising the tribal groups’ cultural and ethnic identity. Using a variety of communication channels to overcome cultural and linguistic barriers and build trust Guarantee creative dignity and visibility while working with tribal groups to increase income through the implementation of appropriate governance mechanisms to manage and support agreements. Provide an autonomous business framework for the value chain enabler to depart.",
            "Prof. Ashwini Chhatre suggested the need to establish an ecosystem in which communities get access to societal benefits like education, wages that reflect the dignity of labour, insurance, and social security. An ecosystem where individuals with an entrepreneurial spirit find opportunities and support to accomplish their aspirations. The first stage, according to him, is to identify existing value chains that are unjust to women and legally organise them in a manner that permits higher value capture in local marketplaces. He discussed the concept of empowering communities until local leadership emerges and an action plan is established, while experimenting with an environment that enables local communities to think. He spoke at length to address a few challenges surrounding how to generate enthusiasm for constructing and operating a formal business model across all value chain participants. He described the scale for formal sales and how we can expand capacity by entering the value chain at the beginning stages, as well as how we can improve the direct sale of seasonal forest products to industries by forming long-term partnerships. He underlined that we are creating opportunities and space for constructing a forest economy based on forest products.",
            "He continued by highlighting the future opportunities for developing new models to access community-beneficial profit-sharing and licencing regimes. By reinvesting in the natural system for regeneration and leveraging the existing legal framework, we can partake in the progress of the community. He foresaw the advantages of capturing the biodiversity register’s traditional knowledge and cultural features, which will, in turn, create prospects for future innovations and new models of solutions.",
          ],
        },
        {
          heading: "Way Forward",
          body: [
            "Development initiatives like the proposed forest economy model involve communication: communication between members of a community, communication between community members and government stakeholders, communication between corporate and community members, and so on. Information, along with hope, fear, and other emotions, is also exchanged. Designing this amalgamation is important to communicate the true intent of the initiative. Participants discussed experiences from the field, classroom, and projects. Design thinking as a framework to enable development communication was discussed at length. The RT discussion highlighted how the design-centric framework can be effective in developing communication and process frameworks capable of triggering intended change.",
          ],
        },
        {
          heading: "Roundtable delegates",
          body: [
            {
              type: "bulletPoints",
              text: [
                "Aditi Singh, Professor, DOID, School of Planning and Architecture, Delhi",
                "Ankit Saxena, AVP, SIIC, Indian Institute of Technology, Kanpur",
                "Bharat Chhitubhai Parmar, Professor, Industrial Design Centre, IIT Bombay",
                "Bharath Murthy, Professor, SRISTI School of Design, Manipal Academy, Bangalore",
                "Danish Iqbal, Dean and Course Director, AJK Mass Communication Research Centre, Delhi",
                "Digangana, Head of Communications, The Echo Network",
                "Mukesh Kumar Gupta, Head, Livelihood Program, Barefoot College, Tilonia, Rajasthan",
                "Neelima Hasija, Head, Integrated Design Service, National Institute of Design, Ahmedabad",
                "Nikhil Agarwal, CEO, SIIC, Indian Institute of Technology, Kanpur",
                "Parag Anand Meshram Head, DOID, School of Planning and Architecture, Delhi",
              ],
            },
          ],
        },
      ],
    },
    "round-table-4": {
      topTitle: "Technology for Sustainable Forest Economy",
      // topHeading: "ILE ‘23",
      // topDesc: "",
      topImage: "round-4-img.png",
      contentDescription: [
        {
          heading: "About the Event",
          body: [
            "Technology for sustainable forest economy’ was the topic of the second of four roundtables (RTs) held in November 2022 to move further towards the vision for the Bharti Institute of Public Policy’s (BIPP), Indian School of Business’s (ISB) Initiative on the Forest Economy (ITFF).",
          ],
        },
        {
          heading: "Discussion Aim",
          body: [
            "Senior scientists and faculty from prominent research and technology institutions came together for the closed group discussion, where Prof. Ashwini Chhatre shared his vision for a thriving forest economy and the potential that the forest economy model, anchored in secure tenure, holds for the industry, the macro economy, the communities, and the sustainable management of forest resources. Prof. Chhatre said, “Technological innovations, both digital and mechanical, will be key to the success of the proposed forest economy model.” Prof. Ashwini Chhatre began the discussion by sharing his vision of a “vibrant forest economy grounded in Community Forest Rights.” It holds tremendous potential for industries, the macro economy, the communities, and the sustainable management of forest resources.",
          ],
        },
        {
          heading: "Forest Economy Model",
          body: [
            "Digital and mechanical technical advancements are crucial to the success of this proposed forest economy. He spoke about the various aspects of the model, such as the fundamental need to have a vision that focuses on forest-based communities and forests in India. Secondly, he elaborated on the existing and potential market for forest-based products. One of the significant sectors associated with forests for raw materials is the paper and pulp industry. Over the decades, the paper and pulp business has consistently evolved to suit different facets of our daily lives. The rise in paper use over the years has prompted us to explore technological and digital solutions for the sustainable management of forest resources. The forest economy is invisible and highly seasonal, but this seasonality persists throughout the year and accounts for a sizable portion of non-farm income for particularly vulnerable and destitute individuals. The intersection between forests and poverty presents us with the opportunity to merge divergent aspects into a prosperous forest economy. He believes that formalizing this first mile of the forest products supply chain through stronger market linkages will generate stable income for communities.",
            "Prof. Chhatre spoke about the business model being developed at ISB. He said that it should be functional for the benefit of forest-dependent people, forests, industries, and the government. The model operates on three functional pillars: tenure security, economies of aggregation, and market linkages. In collaboration with other educational and technological institutions, our team is developing digital, mechanical, and technological wealth-creating solutions. By adopting and applying innovative process optimization solutions, we endeavor to maximize the value of the forest products at the source. Aggregating forest products while customizing mechanical, digital, and technological solutions to increase efficiency, cost-effectiveness, and value increases the likelihood of selling seasonal forest products (SFP) as raw material to industries.",
          ],
        },
        {
          heading: "Perspectives from Key Stakeholders",
          body: [
            "The representative from the Centre for Excellence in Fiscal Policy and Taxation (CEFT) agreed with the professor’s vision of the forest economy. He was positive about digital technology’s facilitation of accessibility, first-mile sourcing and processing, transportation costs, forest management, and demand and supply planning. He emphasized a balanced approach to forest economy and forest protection, among other topics. Remote sensing technologies, geographic data collection, and high-frequency satellite photos must be utilized to locate viable plants and trees as well as raw materials for diverse industrial products.",
            "Representatives from the National Innovation Foundation shared their learnings about the rich knowledge of herbs and medicines the natives possess. They elaborated on ways of cultivation and conservation practiced by communities, which include the creation of a database of their practices, supporting grassroots innovators, validating and adding value to their innovations, and assisting them with business development strategies. In addition, NIF stated its practice of facilitating the transformation of innovations into commercially viable products.",
            "Representatives from IIT Mumbai mentioned their work on the agricultural value chain, product value creation, and strategies they developed for optimizing biorefineries’ inputs. They emphasized the importance of understanding the type, origin, accessibility, and commercial feasibility of a forest-based raw material in order to make it a replacement for the conventional materials used by industries. They emphasized the challenges related to building a system for fabrication, maintenance, and tinkering. For instance, the integration of sensors and displays into a machine can minimize the constraints of achieving locally adaptable modifications that should be customized as per the needs of the community and ecology.",
          ],
        },
        {
          heading: "Way Forward",
          body: [
            "Participants collectively agreed to collaborate on optimizing technologies for collection, scalability, and production using forest products as raw materials. Designing machines for processing and waste management requires the active participation of the government and other agencies, primarily for financial support and their cooperation in transporting and setting up the machines.",
            "The discussion on the inventory of forests by incorporating all stakeholders was a crucial part of the roundtable. Inventory data management, with the cooperation of the Forest Department, was suggested as a viable strategy. Additional points put forward for consideration were technological frameworks for the local communities to comprehend geotagging; infrastructure to analyze forest products; solutions for challenges in tribal inclusivity; collaboration between multiple stakeholders to experiment with unconventional solutions such as data capture at scale and leveraging drones; leveraging technology for innovative solutions; and technology applications for identifying raw material availability.",
            "Representatives discussed essential technologies, including mechanical and digital solutions. The conversation was steered toward a framework for implementation. Participants concurred that accurate inventories of forests and intervention gaps lay the foundation for creating relevant technological solutions. The use of digital technology to increase the transparency of data has emerged as an essential part of a successful forest economy model. The Round Table delegates agreed that the technology-enabled forest economy model has the potential to transform the landscape of community, forest, industry, and government welfare and benefits.",
            "Professor Ashwini Chhatre concluded the roundtable by highlighting the need for a well-defined strategy to achieve equality in the distribution of wealth. The tenure security of the forest communities will ensure continuous and long-term incentives for equitable wealth sharing as well as responsible harvesting and forest management. Women are the major collectors and actors in the invisible forest economy; nevertheless, the business model must support women in being at the forefront of the forest economy.",
          ],
        },
        {
          heading: "Roundtable delegates",
          body: [
            {
              type: "bulletPoints",
              text: [
                "Mahesh Patel, Senior Principal Scientist, National Innovation Foundation, DST, Ahmedabad",
                "Subrata Kumar Mandal, Senior Principal Scientist, Central Mechanical Engineering Research Institute, Durgapur, WB",
                "Imran Shekh, Senior Scientist, Central Mechanical Engineering Research Institute, Durgapur, WB",
                "Abhijit P Deshpande, Head, RuTAG, Indian Institute of Technology, Madras",
                "Satish B. Agnihotri, Professor, Indian Institute of Technology, Bombay",
                "Amit Y. Arora, Professor, Indian Institute of Technology, Bombay",
                "Shaily Gandhi, Program Chair, Geomatics Centre for Environmental Planning and Technology, Ahmedabad",
                "Satyendra Rao, Head, Technology Transfer, Central Food Technology Research Institute, Mysore",
                "Neha Mudaliar, International Finance Corporation",
              ],
            },
          ],
        },
      ],
    },
    "round-table-5": {
      topTitle:
        "Roundtable on Forests as sustainable and secure sources of energy & fossil fuel substitutes",
      // topHeading: "ILE ‘23",
      // topDesc: "",
      topImage: "round-3-img.png",
      contentDescription: [
        {
          heading: "About the Event",
          body: [
            "The fourth and final roundtable discussion for the Indian School of Business (ISB) – Bharti Institute of Public Policy’s (BIPP) Initiative on the Forest Economy (ITFF) was held on 24th November 2022. The Roundtable discussion was centered on the topic of “Forests as sustainable and secure sources of Energy & Fossil Fuel Substitutes.” The concept of forest economy opportunity for people, planet, and profits, was deliberated upon by participants from various organizations, including the National Bank for Agriculture and Rural Development (NABARD), Kotak Mahindra Bank, HDFC Bank, Samunnati, UC Inclusive Credit, ESAF Bank, Microsave Consulting, Intellecap, Ashok Methil Vishwanathan, and Neha Mudaliar.",
          ],
        },
        {
          heading: "Forest Economy Model",
          body: [
            "During the discussion, Professor Ashwini Chhatre, Executive Director of BIPP, shared an outline of the forest economy model, which focuses on increasing returns to scale and enables large-scale distributed industrialization, wealth, and equity while ensuring sustainable management of forest landscapes. He highlighted the triple-win opportunity that the forest economy holds, benefiting the industry from secure and competitive sources of raw materials and traceable supply chains, the forest-dependent communities from transformed livelihoods, and forests from sustainable management and conservation. Prof. Chhatre also discussed the three pillars that would make the triple-win forest economy model possible, including (a) security of tenure of the forest-dependent communities for the right ownership incentive, (b) economies of scale – formal and aggregated community-owned enterprises, and (c) market linkages of scale between industry and community-owned enterprises for industrial raw material.",
          ],
        },
        {
          heading: "Problem Statement",
          body: [
            "Prof. Ashwini identified two challenges in operationalizing this vision: technology and financing the forest economy. The discussion revolved around how to finance the initiative, which had the potential for enormous growth, like microfinance at its initial stages. It was suggested that the focus should be on how to fund the capex at the aggregated level, ensure working capital, and provide financial assistance to people dependent on the forest economy who do not have access to credit. He emphasized the need to create a mechanism for demand-based technology supply where communities will demand and how to use existing mechanisms for financing the forest economy. He also highlighted the enormous potential of this initiative, not just for poverty reduction but also for biodiversity conservation, climate mitigation, ecosystem services, and other intangible benefits to society.",
          ],
        },
        {
          heading: "Perspectives from Stakeholders",
          body: [
            "Ms. Neha focused on financing the initiative and compared it to microfinance in its initial stages. She identified three financial needs: funding the capex at the aggregated level, providing working capital to ensure timely remuneration to forest workers, and providing financial assistance to people dependent on the forest economy who do not have access to credit. By partnering at an enterprise level, credit products and services can be provided, and a credit history can be created for this vast market. Neha highlighted the potential of formalizing the forest economy, given its vast resource of wealth and knowledge and the number of people dependent on it.",
            "The focus of the discussion was on three questions: Where do the participants see the opportunity, do they see a large enough opportunity, and what are the three tangible steps that can be taken ?",
            "Mr. TVS Rao, from ITDA, pointed out that the major challenge they faced was Aadhaar, banking, and connectivity. Lack of connectivity led to the challenge of marketing. ITDA was willing to come and guarantee the banks but only through NGOs.",
            "Ms. Sushma suggested that if two villages have the security of tenure, they have the ownership and management rights of the forest resources, and then they organize themselves into a community enterprise and handshake with the private sector. So, the industry is buying raw materials sourced from the forests, sourced by the communities but through the enterprise. Communities will get the ownership incentive, and sustainability is ensured. She also pointed out that one fundamental reason for marketing efforts to flounder could be that the approach is always towards making finished products, unlike what we set out to do, which is to sell the raw materials directly to big industries that will convert these materials into products.",
            "Reji Daniel from ESAF emphasized that public policy must be translated into private policies. ESAF started as an NGO in Grameen Bank and ventured into microfinance. They wanted to call themselves a social bank, and many of their cooperative members were located on the periphery of the forest.",
            "Mr. Ashok Methil, a retired CGM from NABARD, shared his association with tribal development and the livelihoods of the tribal districts from the forests. He highlighted some of the financial institutions that are eligible for credit guarantee fund of up to 2 crores in NABARD, including commodities like honey, bamboo, hirda, sal, tussar silk, and mahua flowers. However, one of the main challenges is the lack of creditworthiness of people whom they want to finance. One of the solutions is controlling the net collectives and collecting the self-approvals and FPOs. The people dependent on forests are out of the radar, so there is no proof of their creditworthiness, so he is unsure how to deal with this challenge. The marketing of the products collected from the forest is a major challenge because they lack an organized mechanism for marketing. NABARD does not give loans to individuals, only grants if they find value.",
            "Mr. Jayesh Bhatia highlighted two factors – the national system and the perspective around the ecological function. He pointed out that the challenge is how to balance commercial and ecological security. He discussed the two funds of Avishkar: the ESG fund, which supports small and immediate businesses, and the Carbon trust fund, which is scheduled to start soon and will help plant- and forest-based food businesses. The emphasis is on the need for policies to be translated into materialized solutions.",
            "From the standpoint of the banking sector, the Kotak Bank spokesperson emphasized the need for policy support and institutional strength. Regarding the success of the Forest Economic Initiative, he cited Proof of concept and a combination of financial backing based on commercial bank standards as important elements. Technology, raw material supply deadlines, contract deliveries, and a credit guarantee facility are crucial to the model’s success, according to his opinion.",
          ],
        },
        {
          heading: "Way Forward",
          body: [
            "The discussion concluded with the need to find sustainable solutions to finance the forest economy, including creating demand-based technology supply and providing financial assistance to people who are dependent on the forest economy but do not have access to credit. The participants discussed the vast potential of forests as a resource of wealth and knowledge, highlighting the fact that 97% of human existence has survived through forests. They acknowledged that the people who are dependent on forests have been marginalized to the fringes, and an initiative like this has a massive scale, potential, and high impact in formalizing the forest economy.",
            "The group identified and discussed the use of current financial instruments and institutions to meet the demand for and requirements of finance in enabling the forest economy. They also discussed important aspects that will need to be ensured for the viability of a forest economy. The ISB-BIPP team shared the design of the forest economy eco-system that incorporates all these key aspects, thereby lending itself to an inherently strong, equitable, and sustainable model.",
            "In conclusion, the participants identified financing as the main challenge in formalizing the forest economy. The lack of connectivity, creditworthiness of people, and an organized mechanism for marketing were also identified as major challenges. The need to balance commercial and ecological security was also highlighted. The participation from multiple categories of financial institutions and organizations led to identifying pathways for different kinds of financing in the forest economy, including working capital and capital for capacity and infrastructure at the community-owned enterprises and project finance to private companies, community-owned enterprises or independent entrepreneurs towards the large business opportunities in the forest-based industry value chains. The roundtable was a valuable milestone for ISB’s initiative in formalizing the forest economy.",
            "Steps have enormous potential for growth and impact on poverty reduction, biodiversity conservation, climate mitigation, ecosystem services, and other intangible benefits to society.",
          ],
        },
      ],
    },
    "round-table-6": {
      topTitle:
        "Boosting the Forest Economy of Himachal Pradesh: A Roundtable Discussion on Security of Tenure",
      // topHeading: "ILE ‘23",
      // topDesc: "",
      topImage: "round-6-img.png",
      contentDescription: [
        {
          heading: "About the Event",
          body: [
            "The “Boosting the Forest Economy of Himachal Pradesh from Security of Tenure” roundtable was held on August 30th, 2022 in Shimla and was hosted by BIPP-ISB and the Centre for Responsible Business. The roundtable chaired by Shri. Ajay Srivastava, Principal Chief Conservator of Forests of the Himachal Pradesh Forest Department, received significant attention from the Himachal Pradesh government and industries. Senior representatives from IKEA, AAK, Dabur, Hindustan Unilever, ITC-Agribusiness Division, and representatives from the Himachal Pradesh Women & Child Development department, Forest department, Tribal Development department, and Industries department, as well as the Government of Himachal Pradesh actively participated in the forest-based economy potential of the state.",
          ],
        },
        {
          heading: "Forest Economy Model",
          body: [
            "BIPP-ISB’s Forest Economy Initiative places “Security of Tenure” at its core. BIPP-ISB is committed to promoting collective action and reinforcing the benefits of recognizing Community Forest Resource (CFR) rights. The Forest Rights Act of 2006 grants ownership rights to forest communities whose livelihoods depend on the collection and sale of Seasonal Forest Products (SFPs). Professor Ashwini Chhatre explains that an inclusive and formal forest economy model based on the Community Forest Resource rights framework promotes forest conservation and livelihood security by creating job opportunities. When forest communities have secure tenure, they have a long-term incentive to sustainably manage forests, leading to increased income through local value-added processing and market linkages. A dynamic partnership between the government, industry, and communities is essential to establish the forest economy in Himachal Pradesh.",
          ],
        },
        {
          heading: "Perspectives from Stakeholders",
          body: [
            "At the Roundtable, the Forest Governance Specialist from BIPP-ISB team, Ms. Apurva Duddu, shared details of the pilot project underway in Pangi and discussed how it can be replicated in other communities across the state. A collaborative effort between various stakeholders, including the District Administration, SRLM, Forest Department, and WDC, is underway to establish a thriving forest economy in the region. The team also had the opportunity to meet with the Chief Secretary of Himachal Pradesh, Mr. RD Dhiman, who expressed support for their work and proposed a state-level collaboration between various government departments and the Indian School of Business to enhance the forest economy. He previously held the position of the Resident Commissioner of Pangi and hence proposed to provide us with pragmatic guidance relevant to the area.",
            "There are various support systems, such as the Self-Help Group (SHG), Van Dhan Kendra, and Van Sakhis, in place within government departments to overcome obstacles in the forest economy. The Department of Industries provides support to startups for the first three years. However, these programs and groups face optimization challenges. BIPP-ISB is working to improve infrastructure, communication channels, and inventory management for the materials harvested from the forests. This will be achieved by establishing market connections, shortening the credit period between industry and communities, increasing raw material processing through mechanization, and integrating forest management plans with biodiversity registers.",
            "During the roundtable, the Centre for Responsible Business (CRB) stressed the importance of several key factors for creating a responsible business. These include implementing wealth distribution models with effective risk management, pursuing innovative partnerships and collaborations beyond traditional business practices, and receiving improved policy support. CRB also emphasized the crucial role of technology in providing transparency to stakeholders and facilitating business operations. Ms. Sushma Kattamuri, Corporate Engagement Specialist of our team explained how the Forest Economy model based on Community Forest Rights addresses these critical aspects of building sustainable and responsible businesses.",
          ],
        },
        {
          heading: "Way Forward",
          body: [
            "At the Roundtable, the Forest Governance Specialist from BIPP-ISB team, Ms. Apurva Duddu, shared details of the pilot project underway in Pangi and discussed how it can be replicated in other communities across the state. A collaborative effort between various stakeholders, including the District Administration, SRLM, Forest Department, and WDC, is underway to establish a thriving forest economy in the region. The team also had the opportunity to meet with the Chief Secretary of Himachal Pradesh, Mr. RD Dhiman, who expressed support for their work and proposed a state-level collaboration between various government departments and the Indian School of Business to enhance the forest economy. He previously held the position of the Resident Commissioner of Pangi and hence proposed to provide us with pragmatic guidance relevant to the area.",
            "There are various support systems, such as the Self-Help Group (SHG), Van Dhan Kendra, and Van Sakhis, in place within government departments to overcome obstacles in the forest economy. The Department of Industries provides support to startups for the first three years. However, these programs and groups face optimization challenges. BIPP-ISB is working to improve infrastructure, communication channels, and inventory management for the materials harvested from the forests. This will be achieved by establishing market connections, shortening the credit period between industry and communities, increasing raw material processing through mechanization, and integrating forest management plans with biodiversity registers.",
            "During the roundtable, the Centre for Responsible Business (CRB) stressed the importance of several key factors for creating a responsible business. These include implementing wealth distribution models with effective risk management, pursuing innovative partnerships and collaborations beyond traditional business practices, and receiving improved policy support. CRB also emphasized the crucial role of technology in providing transparency to stakeholders and facilitating business operations. Ms. Sushma Kattamuri, Corporate Engagement Specialist of our team explained how the Forest Economy model based on Community Forest Rights addresses these critical aspects of building sustainable and responsible businesses.",
          ],
        },
      ],
    },
  },
  event: {
    "event-1": {
      topTitle:
        "International Learning Exchange 2023: Navigating the Terrain of Radical Forest Futures",
      topHeading: "ILE ‘23",
      topDesc:
        "Delegates from diverse global communities unite in a vibrant celebration of culture, hope, and solidarity at the International Learning Exchange 2023.",
      topImage: "event-main-top.png",
      contentDescription: [
        {
          heading: "About the Event",
          body: [
            "To empower voices from diverse communities and build collaborative relationships, the Bharti School of Public Policy at ISB initiated the inaugural ‘International Learning Exchange 2023’ (ILE 2023). This platform was co-created with the Policy and Development Advisory Group, Society for Rural, Urban and Tribal Initiative, Vasundhara, and the AJK Mass Communication Centre at Jamia Millia Islamia, aiming to create a dynamic multi-stakeholder platform. The International Learning Exchange, under the theme “Radical Forest Futures,” was an epochal convergence held from November 2nd to 4th, 2023, at Delhi University’s Multipurpose Hall Sports Complex.",
            "This gathering was a crucible of thought and action, addressing the urgent and complex issues of forest conservation and climate change mitigation through the lens of the Forest Rights Act of 2006. The event stood out for its interdisciplinary approach, attracting a mosaic of participants — community leaders, environmental activists, policymakers, journalists, academicians, and students — creating a melting pot of perspectives and expertise.",
            "This conclave was a harmonious blend of academic discourse, cultural celebration, and community engagement. It was an embodiment of the idea that understanding and addressing environmental issues require a multifaceted approach, encompassing legal, social, cultural, and environmental dimensions. The event commenced with an informal setting, promoting equality and community harmony. Professor Ashwini Chhatre, Executive Director of BIPP, Indian School of Business opened the event, setting a tone of collaboration and shared learning. A pictorial introduction to Delhi highlighted the city’s historical and modern aspects, showcasing its rich cultural and environmental heritage.",
          ],
        },
        {
          heading: "Knowledge Sharing Sessions",
          body: [
            "The Knowledge Sharing Sessions were the backbone of the event, facilitating deep dives into the intricacies and impacts of the Forest Rights Act 2006. These sessions illuminated the varying trajectories of the Act’s implementation across Indian states, shedding light on both its triumphs and tribulations. Key discussions revolved around the empowerment that community forest titles bestowed upon local communities, particularly in terms of managing and commercially benefiting from forest resources like bamboo and Tendu leaves.",
            "The sessions also shone a spotlight on women’s leadership within the Forest Rights Act framework. This was a critical discourse highlighting how women, deeply versed in local flora and traditional practices, are indispensable to sustainable forest management. The narrative extended to explore the challenges and achievements in Community Forest Rights, addressing the protection of forest dwellers’ rights and the role of community engagement in forest resource management. The discussions were not confined to Indian perspectives but also embraced international experiences, sharing insights from countries like Tanzania, Kenya, Indonesia, Malaysia, Cameroon, and Brazil.",
            { type: "event-dt-image", text: "event-dt-1-img.png" },
            { type: "paragraph-heading", text: "List of Knowledge Session: " },
            {
              type: "bulletPoints",
              text: [
                "Session 1: Transition from Titles to Ownership",
                "Session 2: Role of Women Leadership Within the FRA Purview",
                "Session 3: Community Forest Rights within FRA: Challenges and Achievements",
                "Session 4: Chhattisgarh and the challenges in the context of CFR and FRA",
                "Session 5: Social Cohesion: An Inclusive Forest-Dependent Community",
                "Session 6: Exploring International Perspectives on Community Forest Relationships",
              ],
            },
          ],
        },
        {
          heading: "Phoenix Talks",
          body: [
            "The Phoenix Talks were a tapestry of human spirit and resilience, narrating stories of individuals who transcended societal and environmental challenges to emerge as catalysts of change. These sessions served as a powerful testimony to the human capacity for overcoming adversity and contributing to societal upliftment. Speakers from diverse backgrounds shared their experiences — from battling societal norms and environmental challenges to advocating for community rights and women’s empowerment. These narratives not only inspired but also offered practical insights into grassroots activism and community-led conservation efforts.",
            { type: "event-dt-image", text: "event-dt-2-img.png" },
            { type: "paragraph-heading", text: "List of Phoenix Talk" },
            {
              type: "bulletPoints",
              text: [
                "Pawna Kumari – A Fearless Crusader for Nature and Community",
                "Bhawani Harijana – Crusade Towards Personal Hygiene, Health and Security for Women",
                "Rajim Didi – A Tale of Grit, And Undying Resolve to Uplift Dalits and Adivasis",
                "Narayani Bhil – Crusader and Advocate for Marginalised Communities",
              ],
            },
          ],
        },
        {
          heading: "Cultural Events and Highlights",
          body: [
            "The event was interspersed with a range of cultural activities that provided a glimpse into the rich tapestry of global cultures and traditions. A Sufi music night and the “March of Diversity” were not just entertainment but symbolic representations of the unity and diversity inherent in the global fight for environmental conservation. The ‘Wish Tree’ installation, where participants shared their hopes and dreams, stood as a testament to the collective aspirations for a sustainable future.",
            { type: "event-dt-image", text: "event-dt-3-img.png" },
          ],
        },
        {
          heading: "Round Table",
          body: [
            "The Media Roundtable on Community Forest Rights was a critical element of the event, serving as a bridge between grassroots movements and broader public awareness. The roundtable highlighted the essential role of media in shaping public opinion and policy regarding forest rights and conservation. Discussions at the roundtable were comprehensive, covering the successes and challenges in the implementation of the Forest Rights Act, the empowerment of communities through forest rights, and the role of media in advocacy and awareness. This session underscored the symbiotic relationship between effective journalism and societal change, particularly in the context of environmental conservation.",
            "The International Learning Exchange was a seminal event, not just for its scope but for its profound impact on the discourse surrounding forest conservation and community rights. It was a testament to the power of collaborative effort, education, and advocacy in shaping a future where humanity and nature coexist in harmony. The insights and experiences shared during the event have sown the seeds for radical and transformative forest futures, emphasizing our collective responsibility towards the planet, profits, and people.",
            { type: "event-dt-image", text: "event-dt-4-img.png" },
            "The three-day event’s pinnacle was a vibrant display of unity, as participants from diverse communities in India and international delegates from Kenya, Tanzania, Cameroon, Malaysia, Nepal, Indonesia, and Brazil came together. This festive culmination featured a lively blend of singing, dancing, and music, symbolizing a shared vision of hope and solidarity. This joyous gathering underscored the event’s spirit of collaboration and mutual support across geographical and cultural boundaries.",
          ],
        },
      ],
    },
    "event-2": {
      topTitle:
        "Collaborative Initiative on the Scientific Forest Management in Himachal Pradesh",
      // topHeading: "ILE ‘23",
      topDesc:
        "Abhijeet, lead, Tech & Research Partnership, BIPP-ISB presenting the results and technical insights of the nCount initiative during the review meeting at ISB Mohali Campus.",
      topImage: "wshop-4-img.png",
      contentDescription: [
        {
          heading: "About the Event",
          body: [
            "On January 15, 2024, the Himachal Pradesh Forest Department and the Bharti Institute of Public Policy (BIPP) at the Indian School of Business (ISB) launched a collaborative project to inventory major seasonal forest products in the state. This project aims to estimate the occurrence and distribution of these products and project potential revenue for the state government through sustainable management and regulated trade.",
            "Phase one of the nCount training was implemented across six divisions in Himachal Pradesh: Ani, Dehra, Nachan, Nurpur, Palampur, and Paonta. Over two months, the forest department successfully collected approximately 40,000 data points on various forest species.",
            "On June 9, 2024, a review meeting was held at the ISB Mohali campus to discuss the on-ground experiences of forest officials, address their queries, and incorporate their insights for the planning of Phase II. This meeting provided a platform to refine the nCount technology based on practical feedback, ensuring its effective implementation for sustainable forest management.",
            "Prof. Ashwini Chhatre, Executive Director of BIPP, ISB, welcomed the Himachal Pradesh Forest Department (HPFD) officials and initiated the meeting with introductions. He outlined the agenda, focusing on sharing field experiences and lessons learned from adopting the nCount technology. Prof. Chhatre emphasized that insights from nCount could refine forest economy initiatives and help propose nCount as a modern technology for better forest management to other state governments.",
            "Dr. Pushpendra Rana, Additional Principal Chief Conservator of Forests (APCCF), discussed the utility and benefits of the nCount platform for Himachal Pradesh Forest Department and local communities, highlighting its role in sustainable forest resource management and economic linkages for community livelihoods. He explained that integrating AI and machine learning would enable the forest department to create and maintain a verified inventory of Seasonal Forest Products (SFPs) and track annual changes, assisting in preparing working plans and resource inventories.",
            "During the review meeting, foresters shared their experiences and challenges encountered while using the nCount and ODK technologies for forest inventory management. The discussions highlighted several critical issues related to data collection, species selection, and the capacity and training needs of forest guards.",
          ],
        },
        {
          heading: "Data Collection",
          body: [
            "Points required for shrubs and herbs, which proved difficult to achieve in areas with limited forest guard resources. It was clarified that these statistical requirements are essential for the model to produce accurate rangelevel maps. Additionally, forest guards reported difficulties in capturing photos of tall trees and trees surrounded by dense vegetation. The training protocols specified that photos should be taken without zooming, which posed a challenge in some situations. There were also issues with achieving the required 5meter accuracy for data capture in dense forests, leading to a proposal to increase the allowable accuracy threshold to 10 meters.",
          ],
        },
        {
          heading: "Species Selection",
          body: [
            "The discussion revealed the need to include a wider variety of bamboo species and other regionspecific flora. For example, the Sal tree, abundant in certain forest divisions, was not adequately represented in the nCount forms. Suggestions were made to add species such as Shisham, Dhau, Curry Patta, Garna, Rohini, Ban Oak, and Deodar. It was agreed that Cedrus deodara and Quercus leucotrichophora would be added to the ODK platform to better reflect the biodiversity of the region.",
          ],
        },
        {
          heading: "Other Observations",
          body: [
            "Forest guards were noted to have limited knowledge of medicinal plants, herbs, and shrubs, indicating a need for additional training workshops led by experts. It was suggested that traditional area sampling techniques be employed instead of tree species sampling. However, it was explained that the AI-based model addresses the limitations of area sampling by using satellite imagery to generate prediction models.",
            "A new monitoring dashboard is being developed to help DFOs track progress and plan better. Additionally, the lack of access to ODK for iPhone users was highlighted, with assurances that an iPhone-compatible version would be available soon. Concerns were also raised about capturing data for herbs and shrubs, with questions about the feasibility of using satellite imagery for this purpose. Challenges in creating small polygons of 5 meters were also discussed, with the need for larger polygons being recognized.",
          ],
        },
        {
          heading: "Technical Analysis of Phase-I Presentation",
          body: [
            "The results of Phase I were presented, showing around 40,000 data points collected. Species distribution maps were displayed, and the process behind their creation was explained. The presentation highlighted the classification of five species: Taxus baccata, Sal, Rhododendron, Khair, and Wild Mango. It detailed how satellite imagery, AI models, and other data sources were used to create accurate species distribution maps.",
            "It was clarified that the AI model is designed to address the inherent limitations of area sampling methods, using a combination of satellite imagery, spectral data, and other parameters to build a comprehensive prediction model. The issue of visualizing species distribution at different scales was also addressed, emphasizing the need for more data points to enhance accuracy. Validation efforts were discussed, including techniques to ensure data accuracy and reliability. For instance, Google Earth snapshots and point cloud simulations showed high accuracy levels in species identification and geometry reconstruction. The meeting concluded with a focus on the need for at least 5000 data points per species to ensure robust validation. Once this threshold is met, the actual model will be used to generate predictions, which will undergo further validation.",
          ],
        },
        {
          heading: "Way Forward: Phase-II of nCount Implementation",
          body: [
            "The next phase of the nCount initiative will focus on strategic species selection and extensive data collection to enhance forest management. Prof. Ashwini Chhatre emphasized the importance of choosing species with high market potential, such as Camellia, which is expected to gain value as a nature-based substitute in the chemical industry. This strategic preparation will attract industry partners interested in sustainably harvested forest products, benefiting local communities.",
            {
              type: "paragraph-heading",
              text: "Primary goals for Phase II are -",
            },
            {
              type: "numBulletPoints",
              text: [
                "Data Collection: Aim to collect at least 5000 data points per species to ensure comprehensive coverage and accuracy. Expand the species inventory by adding at least 40 new high-altitude species, such as Quercus incana, Deodar, Rohini, Sea buck Thorn, and Kadu. Data collection for these species will begin in July and last for two months, targeting locations like Pangi, Rohru, Spiti, Nachan, and Nurpur.",
                "Drone Mapping: Initiate drone mapping from June 25, with tech team members and foresters visiting high-altitude regions. The drones, equipped with 4872-megapixel cameras and three sensors, will accurately georeference structural data to gather points for herbs. Collaborate with IIT Delhi to develop robust autonomous drone models, addressing challenges in automated flight. The drone mapping will be conducted in three phases: initial calibration with standard drones, followed by detailed mapping in specific forest areas.",
              ],
            },
            "During the discussions, Pushendra Rana highlighted that drone technology would also aid in identifying forest fire locations and water storage areas, enhancing resource inventory mapping and rejuvenation efforts.",
            {
              type: "bulletPoints",
              text: [
                "Commencement: Phase 2 activities will begin in July, with drone mapping starting on June 25.",
                "Species Addition: Formalize the inclusion of species like Deodar, Ban Oak, and various bamboo species (Bambusa Bamboo and Dandrocalamus strictus).",
                "Training: Organize expert-led training sessions for foresters on the traditional knowledge of Seasonal Forest Products (SFPs).",
                "Technology Updates: Launch Version 2 of the nCount platform, compatible with Apple devices, incorporating the collected species wise data.",
                "Capacity Building: Conduct capacity building and training programs for foresters on nCount technology and drone mapping techniques.",
                "Data Collection Protocol: Foresters will capture polygons within a 10-meter range, take non-zoomed leaf pictures, and ensure accurate data collection for bamboo species.",
                "Future Meetings: Schedule the next review meeting in August, involving current DFOs and those from high-altitude regions.",
                "Validation: Ensure data validation through ground truthing to maintain data accuracy and reliability.",
                "Permissions: Obtain necessary permissions from the forest department to fly drones in HP forests.",
                "These steps aim to refine and expand the nCount initiative, leveraging advanced technology for sustainable and effective forest management in Himachal Pradesh.",
              ],
            },
          ],
        },
      ],
    },
  },
};
