import React from "react";
import { Row } from "reactstrap";
import Header from "../../components/Header2";
import PubsList from "./PubsList";
import LibBottom from "./LibBottom";
import { useScrollToTop } from "../../utils/scrollToTop";

function Home() {
  useScrollToTop();
  return (
    <>
      <div className="home-landing-page">
        <video className="background-video" autoPlay={true} loop muted>
          <source
            src="/main_bg.mp4"
            type="video/mp4"
          />
        </video>
        <img src="bg-layer.png" className="background-video" alt="over-lay" />
        <Header activeLink={"2"} modified={false} />
        <Row className="lib-page-title-row">
          <p className="home-page-title">
            Shaping Tomorrow's Forests: Transformative Initiatives for a
            Sustainable Future
          </p>
        </Row>
      </div>
      <PubsList />
      <LibBottom />
    </>
  );
}

export default Home;
