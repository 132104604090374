import React from "react";
import { Row } from "reactstrap";
import Header from "../../components/Header2";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="home-landing-page">
      <video className="background-video" autoPlay={true} loop muted>
        <source src="/main_bg.mp4" type="video/mp4" />
      </video>
      <img src="bg-layer.png" className="background-video" alt="over-lay" />
      <Header activeLink={"0"} modified={false} />
      <Row className="home-page-title-row">
        <p className="home-page-title">
          Building sustainable and robust forest futures for people, planet and
          profits
        </p>
        <Link to="/about-us">
          {" "}
          <center>
            <button className="home-page-button">Know More About Us</button>
          </center>
        </Link>
      </Row>
    </div>
  );
}

export default Home;
