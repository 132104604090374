import React from "react";
import { Link } from "react-router-dom";
import { workData } from "../../utils/our-work-data/workData";

function StateWorkCards({ data }) {
  let stateWorkCardsData = [];
  for (let index = 0; index < workData.length; index++) {
    const element = workData[index];
    if (element.location === data.title) {
      stateWorkCardsData.push(element);
    }
  }
  return (
    <div className="state-work-card-section">
      <div className="state-work-container">
        <div className="state-work-left-container">
          <div className="state-work-state-data">
            <h4>{data.title}</h4>
            <p>{data.stateDescription}</p>
          </div>
          {data.distData &&
            data.distData.map((info, index) => {
              return (
                <div className="state-work-dist-data" key={index}>
                  <h6>{info.distTitle}</h6>
                  <p>{info.distDescription}</p>
                </div>
              );
            })}
        </div>
        <div className="state-work-right-container">
          <div className="state-work-grid-container">
            {stateWorkCardsData.map((info, index) => {
              return (
                <Link to={info.deepLink} className="work-card-link">
                  <div className="state-work-card">
                    <div className="state-work-card-img">
                      <img
                        src={`work-image/${info.img}`}
                        className=""
                        alt="card-img"
                      />
                    </div>
                    <div className="state-work-card-title">
                      <h6 className="">{info.title}</h6>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StateWorkCards;
